<template>
  <ion-header
    class="ion-no-border"
    style="z-index: 1;padding-top: 0!important;"
  >
    <ion-toolbar
      v-if="selectedView === '1' && device.active"
      color="transparent"
    >
      <ion-button
        v-if="device.typeI === 'SmartLightColorChangeable' || device.typeI === 'SmartLightColorDimmable' || device.typeI === 'SmartLightDimmableIkea250Lm' || device.typeI === 'SmartDriverDimmableIkea' || device.typeI === 'SmartLightDimmableIkea806Lm' || device.typeI === 'SmartSpotLightDimmableIkea345Lm'"
        slot="end"
        fill="clear"
        color="transparent"
        :disabled="linkageExists || canLink < 2 || pickerOpen"
        size="large"
        class="close-modal-button"
        @click="openLinkagePopover(device)"
      >
        <i
          class="mdi"
          :class="linkageExists ? 'mdi-link-lock text--primary' : 'mdi-link-variant text--white'"
          style="font-size: 27px"
        />
        <span class="text--primary">{{ device.linkages.length > 0 ? device.linkages.length : '' }}</span>
      </ion-button>
      <ion-button
        v-if="device.typeI === 'SmartLock'"
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="lockDetailsModal(device)"
      >
        <i
          class="mdi mdi-open-in-new text--white"
          style="font-size: 25px"
        />
      </ion-button>
    </ion-toolbar>
    <ion-toolbar
      v-if="selectedView === '1' && !device.active"
      color="transparent"
      class="text-center pr-10"
    >
      <div
        class="text--danger"
        style="text-shadow: -2px 1px 5px rgba(114, 114, 114, 0.7);"
      >
        {{ $t('Disconnected') }}
      </div>
    </ion-toolbar>
    <ion-toolbar
      v-if="selectedView === '2'"
      color="transparent"
    >
      <ion-item
        slot="start"
        lines="none"
        color="transparent"
        class="close-modal-button"
        @click="selectedView = '1'; initiateRGBPicker();"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <div
        class="text-18 text--white text-center"
      >
        {{ $t('settings') }}
      </div>
      <ion-button
        slot="end"
        :disabled="loading"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="removeAlertConfirm(device)"
      >
        <i
          v-if="!loading"
          class="material-icons"
        >delete</i>
        <ion-spinner
          v-else-if="loading"
          color="light"
          name="crescent"
        />
      </ion-button>
    </ion-toolbar>
    <ion-toolbar
      v-if="selectedView === '3'"
      color="transparent"
    >
      <ion-item
        slot="start"
        lines="none"
        color="transparent"
        class="close-modal-button"
        @click="selectedView = '1'; initiateRGBPicker();"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title
        class="text-18 text--white"
      >
        {{ $t('History') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content
    :scroll-y="selectedView === '3' ? true : false"
    style="--keyboard-offset: 0px!important;"
  >
    <div v-if="selectedView === '1'">
      <div
        v-if="device.typeI === 'SmartLightColorDimmable' ||
          device.typeI === 'SmartLightDimmableIkea250Lm' ||
          device.typeI === 'SmartDriverDimmableIkea' ||
          device.typeI === 'SmartLightDimmableIkea806Lm' ||
          device.typeI === 'SmartSpotLightDimmableIkea345Lm' ||
          device.typeI === 'SmartLightColorDimmableFilament' ||
          device.typeI === 'SmartLightColorDimmableFilamentIkea'"
      >
        <div>
          <div :class="device.typeI === 'SmartDriverDimmableIkea' || device.typeI === 'SmartLightColorDimmableFilamentIkea' ? 'shade-out' : ''">
            <div
              v-show="rgbSelected"
              class="color-picker"
            >
              <div
                ref="Picker"
                class="iro-picker-a"
              />
            </div>
            <div
              v-show="!rgbSelected"
              class="color-picker"
            >
              <div class="cct-picker-container">
                <ion-item
                  color="transparent"
                  lines="none"
                  class="ion-no-padding ion-no-margin"
                  style="width: 105%; height: 200px;"
                >
                  <ion-range
                    v-model="colorTemp"
                    min="1"
                    max="500"
                    class="slider-temp"
                  />
                </ion-item>
                <div class="cct-picker" />
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <div
              :class="device.typeI === 'SmartDriverDimmableIkea' || device.typeI === 'SmartLightColorDimmableFilamentIkea' ? 'shade-out' : ''"
              style="display: inline-block; margin-left: 20px;"
            >
              <ion-img
                tappable
                style="max-width: 60px;"
                :src="pickerCCT"
                @click="rgbSelected = false"
              />
            </div>
            <div style="display: inline-block; margin-left: 20px;">
              <ion-img
                tappable
                style="max-width: 60px;"
                :src="pickerLevel"
                @click="openLevelPicker"
              />
            </div>
          </div>
        </div>
        <ion-item
          v-if="!smallScreen"
          color="transparent"
          :disabled="!device.active"
          lines="none"
          class="mt-10"
        >
          <ion-toggle
            v-model="switchgear"
            :disabled="$store.state.communication"
            style="margin: auto;"
            :checked="switchgear"
          />
        </ion-item>
        <transition name="fade">
          <div
            v-if="levelOpen"
            v-click-outside="handleFocusOut"
            class="mt-10 light-level-container"
          >
            <div>
              <ion-item
                color="transparent"
                lines="none"
                class="ion-no-padding ion-no-margin"
              >
                <ion-range
                  v-model="lightLevel"
                  class="light-level"
                  min="5"
                  max="254"
                />
              </ion-item>
              <i
                class="mdi mdi-lightbulb-on-outline"
                style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: gray; z-index: 99; pointer-events: none; font-size: 35px;"
              />
            </div>
          </div>
        </transition>
      </div>
      <div v-else-if="device.typeI === 'SmartLightColorChangeable' || device.typeI === 'SmartLightColorDimmableSpot'">
        <!-- <div :class="pickerOpen ? 'shade-out' : ''">
          <div class="device-image">
            <img
              style="max-width: 100px; margin-top: -10px;"
              src="../../assets/devices/SmartLightColorChangeable.png"
            >
          </div>
          <ion-item
            color="transparent"
            lines="none"
          >
            <ion-toggle
              v-model="switchgear"
              :disabled="$store.state.communication"
              style="margin: auto;"
              :checked="switchgear"
            />
          </ion-item>
        </div> -->
        <div>
          <div
            v-show="rgbSelected"
            class="color-picker"
          >
            <div
              ref="Picker"
              class="iro-picker-a"
            />
          </div>
          <div
            v-show="!rgbSelected"
            class="color-picker"
          >
            <div class="cct-picker-container">
              <!-- <input
                v-model="colorTemp"
                debounce="500"
                orient="vertical"
                type="range"
                min="1"
                max="500"
              > -->
              <ion-item
                color="transparent"
                lines="none"
                class="ion-no-padding ion-no-margin"
                style="width: 105%; height: 200px;"
              >
                <ion-range
                  v-model="colorTemp"
                  min="1"
                  max="500"
                  class="slider-temp"
                />
              </ion-item>
              <div class="cct-picker" />
            </div>
          </div>
          <div style="margin-top: 15px;">
            <div style="display: inline-block;">
              <ion-img
                tappable
                style="max-width: 60px;"
                :src="picker"
                @click="rgbSelected = true"
              />
            </div>
            <div style="display: inline-block; margin-left: 20px;">
              <ion-img
                tappable
                style="max-width: 60px;"
                :src="pickerCCT"
                @click="rgbSelected = false"
              />
            </div>
            <div style="display: inline-block; margin-left: 20px;">
              <ion-img
                tappable
                style="max-width: 60px;"
                :src="pickerLevel"
                @click="openLevelPicker"
              />
            </div>
          </div>
        </div>
        <ion-item
          v-if="!smallScreen"
          color="transparent"
          lines="none"
          :disabled="!device.active"
          class="mt-10"
        >
          <ion-toggle
            v-model="switchgear"
            :disabled="$store.state.communication"
            style="margin: auto;"
            :checked="switchgear"
          />
        </ion-item>
        <transition name="fade">
          <div
            v-if="levelOpen"
            v-click-outside="handleFocusOut"
            class="mt-10 light-level-container"
          >
            <div>
              <ion-item
                color="transparent"
                lines="none"
                class="ion-no-padding ion-no-margin"
              >
                <ion-range
                  v-model="lightLevel"
                  class="light-level"
                  min="5"
                  max="254"
                />
              </ion-item>
              <i
                class="mdi mdi-lightbulb-on-outline"
                style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: gray; z-index: 99; pointer-events: none; font-size: 35px;"
              />
            </div>
          </div>
        </transition>
      </div>
      <div v-else-if="device.typeI === 'SmartPlug' || device.zigbeeModel === 'WallSwitch' || device.typeI === 'WaterLeakValveIkea' || device.typeI === 'SmartPlugIkea' || device.typeI === 'SmartRelay'">
        <div v-if="device.zigbeeModel === 'SmartPlug' || device.typeI === 'SmartPlugIkea' || device.typeI === 'SmartRelay'">
          <img
            class="device-image-popup"
            :src="require('../../assets/devices/' + device.typeI + '.png')"
          >
        </div>
        <div v-else-if="device.zigbeeModel === 'WallSwitch'">
          <img
            class="device-image-popup"
            src="../../assets/devices/WallSwitch.png"
          >
        </div>
        <ion-item
          color="transparent"
          lines="none"
          :disabled="!device.active"
          class="mt-10"
        >
          <ion-toggle
            v-model="switchgear"
            :disabled="$store.state.communication"
            style="margin: auto;"
            :checked="switchgear"
          />
        </ion-item>
        <div
          v-if="device.zigbeeModel === 'SmartPlug' || device.zigbeeModel === 'SmartRelay'"
          class="text--white mt-30"
        >
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="8"
            >
              {{ $t('Current consumption') }}:
            </ion-col> <ion-col class="text--primary">
              <span class="text--primary">{{ device.currentConsumption ? device.currentConsumption : 0 }}</span> W
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="8"
            >
              {{ $t('Total consumption') }}:
            </ion-col> <ion-col class="text--primary">
              <span class="text--primary">{{ device.totalConsumption ? Math.round(device.totalConsumption) : 0 }}</span> kW
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorMulti'">
        <div>
          <img
            class="device-image-popup"
            src="../../assets/devices/SensorMulti.png"
          >
        </div>
        <div class="text--white mt-10">
          <ion-row>
            <ion-col size="5">
              Status:
            </ion-col>
            <ion-col
              v-if="device.active"
              class="text--primary"
            >
              {{ deviceStatusMapper(device.status) }}
            </ion-col>
            <ion-col
              v-else-if="!device.active"
              class="text--primary"
            >
              {{ $t('Disconnected') }}!!
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="5"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="5"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="5"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorMotion'">
        <div>
          <img
            class="device-image-popup"
            src="../../assets/devices/SensorMotion.png"
          >
        </div>
        <div class="text--white mt-10">
          <ion-row>
            <ion-col size="4">
              Status:
            </ion-col>
            <ion-col
              v-if="device.active"
              class="text--primary"
            >
              {{ deviceStatusMapper(device.status) }}
            </ion-col>
            <ion-col
              v-else-if="!device.active"
              class="text--primary"
            >
              {{ $t('Disconnected') }}!!
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="4"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorTemperatureOutdoor'">
        <div>
          <img
            class="device-image-popup"
            src="../../assets/devices/SensorTemperatureOutdoor.png"
          >
        </div>
        <div class="text--white mt-30">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorTemperatureIndoor' || device.typeI === 'SensorTemperatureIndoorWithProbe'">
        <div>
          <img
            class="device-image-popup"
            src="../../assets/devices/SensorTemperatureIndoor.png"
          >
        </div>
        <div class="text--white mt-30">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SmartCurtain'">
        <img
          class="device-image-popup"
          :src="require('../../assets/devices/' + device.typeI + '.png')"
        >
        <div
          class="mt-20"
          :class="curtainControlEnabled ? '' : 'disabled'"
        >
          <i
            tappable
            class="mdi mdi-chevron-up-circle-outline text--primary"
            style="font-size: 35px; margin-right: 15px;"
            @click="controlCurtain('open')"
          />
          <i
            tappable
            class="mdi mdi-stop-circle-outline text--primary"
            style="font-size: 35px;"
            @click="controlCurtain('stop')"
          />
          <i
            tappable
            class="mdi mdi-chevron-down-circle-outline text--primary"
            style="font-size: 35px; margin-left: 15px;"
            @click="controlCurtain('close')"
          />
        </div>
      </div>
      <div v-else-if="device.typeI === 'SmartLock'">
        <!-- <img
          style="max-width: 150px;"
          :src="require('../../assets/devices/' + device.typeI + '.png')"
        > -->
        <div>
          <div>
            <transition
              name="slide-up"
              mode="out-in"
            >
              <div v-if="languageSelected === 'EN'">
                <img
                  v-if="device.switchgear === 'unlock' || device.switchgear === null || device.switchgear === 'unknown'"
                  src="../../assets/images/unlocked.png"
                  class="securityicon-svg"
                >
                <img
                  v-else-if="device.switchgear === 'lock'"
                  src="../../assets/images/locked.png"
                  class="securityicon-svg"
                >
              </div>
              <div v-else>
                <img
                  v-if="device.switchgear === 'unlock' || device.switchgear === null || device.switchgear === 'unknown'"
                  src="../../assets/images/unlocked-se.png"
                  class="securityicon-svg"
                >
                <img
                  v-else-if="device.switchgear === 'lock'"
                  src="../../assets/images/locked-se.png"
                  class="securityicon-svg"
                >
              </div>
            </transition>
          </div>
          <div style="transition: all 0.5s ease-out">
            <ion-button
              :color="device.switchgear === 'unlock' ? 'transparent' : ''"
              style="max-width: 120px; border-radius: 5px;"
              :style="device.switchgear === 'unlock' ? 'border: 1px solid #ffd4a4; color: #ffd4a4;' : 'color: #2c3036;'"
              @click="setLockStatus(device, device.switchgear === 'lock' ? 'unlock' : 'lock')"
            >
              <transition
                name="slide-left"
                mode="out-in"
              >
                <ion-label v-if="!lockWorking">
                  {{ device.switchgear === 'lock' ? $t('Unlock door') : $t('Lock door') }}
                </ion-label>
                <ion-spinner
                  v-else
                  :color="device.switchgear === 'lock' ? 'secondary' : 'primary'"
                  name="dots"
                />
              </transition>
            </ion-button>
          </div>
          <div class="mt-10 text-left pl-20">
            <div class="text--white">
              <span class="text--white">{{ $t('Last event') }}:</span> <span class="text--primary text-14 mt-5">{{ device.status }}</span>
            </div>
          </div>
          <div class="mt-10 text-left pl-20">
            <div>
              <span class="text--white">{{ $t('Status') }}:</span> <span class="text--primary text-14 mt-5">{{ device.active ? $t('Ok') : $t('Disconnected') }} </span>
            </div>
          </div>
          <div class="mt-5 text-left pl-20">
            <div>
              <span class="text--white">{{ $t('Battery') }}:</span>
              <!-- <span class="text--primary text-14 mt-5">{{ (device.batteryPercentageRemain / 200 ) * 100 }} %</span> -->
              <span class="text--primary text-14">
                <ion-icon
                  v-if="(device.batteryPercentageRemain / 2) >= 60"
                  color="primary"
                  style="font-size: 20px; position: relative; top: 5px; left: 5px;"
                  :icon="batteryFullOutline"
                />
                <ion-icon
                  v-else-if="(device.batteryPercentageRemain / 2) >= 11 && (device.batteryPercentageRemain / 2) <= 59"
                  color="primary"
                  style="font-size: 20px; position: relative; top: 5px; left: 5px;"
                  :icon="batteryHalfOutline"
                />
                <ion-icon
                  v-else-if="(device.batteryPercentageRemain / 2) <= 10"
                  color="primary"
                  style="font-size: 20px; position: relative; top: 5px; left: 5px;"
                  :icon="batteryDeadOutline"
                />
                <span
                  v-if="(device.batteryPercentageRemain / 2) <= 10"
                  style="margin-left: 7px;"
                  class="text--white text-14"
                >{{ $t('Charge Battery') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <img
            class="device-image-popup"
            :src="require('../../assets/devices/' + device.typeI + '.png')"
          >
        </div>
        <div class="text--white mt-30">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="4"
            >
              {{ $t('Status') }}:
            </ion-col>
            <ion-col
              v-if="device.active"
              class="text--primary"
            >
              {{ deviceStatusMapper(device.status) }}
            </ion-col>
            <ion-col
              v-else-if="!device.active"
              class="text--primary"
            >
              {{ $t('Disconnected') }}!!
            </ion-col>
          </ion-row>
          <ion-row
            v-if="device.zigbeeModel === 'IASWarning'"
            class="flex-1"
          >
            <ion-col
              size="12"
            >
              <ion-button
                v-if="!sirenActive"
                fill="clear"
                color="transparent"
                @click="testSiren(18, device)"
              >
                <ion-label color="primary">
                  <i
                    class="mdi mdi-alarm-light"
                    style="font-size: 20px; position: relative; top: 2px;"
                  /> {{ $t('activate siren') }}
                </ion-label>
              </ion-button>
              <ion-button
                v-if="sirenActive"
                fill="clear"
                color="transparent"
                @click="resetSiren(device)"
              >
                <ion-label color="primary">
                  <i
                    class="mdi mdi-alarm-light-off"
                    style="font-size: 20px; position: relative; top: 2px;"
                  /> {{ $t('stop siren') }}
                </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
          <!-- <ion-row
            v-if="device.zigbeeModel === 'IASWarning'"
          >
            <ion-col
              size="12"
            >
              <div
                tappable
                :style="testDisabled ? 'opacity: 0.5; pointer-events: none;' : ''"
                style="display: inline-block; font-size: 25px;"
                @click="testSiren(18, device)"
              >
                <i class="mdi mdi-test-tube text--primary" />
              </div>
              <div
                tappable
                :style="testDisabled ? 'opacity: 0.5; pointer-events: none;' : ''"
                style="display: inline-block; font-size: 25px; margin-left: 20px;"
                @click="testSiren(20, device)"
              >
                <i class="mdi mdi-test-tube text--green" />
              </div>
              <div
                tappable
                :style="testDisabled ? 'opacity: 0.5; pointer-events: none;' : ''"
                style="display: inline-block; font-size: 25px; margin-left: 20px;"
                @click="testSiren(24, device)"
              >
                <i class="mdi mdi-test-tube text--danger" />
              </div>
            </ion-col>
          </ion-row> -->
          <ion-row
            v-if="device.zigbeeModel !== 'IASWarning'"
            class="flex-1"
          >
            <ion-col
              class="text--right"
              size="4"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
    <div
      v-else-if="selectedView === '2'"
      class="ion-padding"
    >
      <form
        class="login-form form--dark flex-1"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('deviceName') }}
          </ion-label>
          <ion-input
            v-model="deviceName"
            type="text"
            maxlength="13"
            enterkeyhint="done"
            autocapitalize="words"
            required
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </form>
      <div class="text-left pb-10">
        <ion-text class="text--white">
          {{ $t('room') }}
        </ion-text>
      </div>
      <div>
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-no-padding">
            <div
              v-for="room in propertyRooms"
              :key="room.id"
            >
              <ion-col class="ion-no-padding">
                <div class="buttonRoom">
                  <ion-button
                    :class="selectedRoom === room.id ? 'active' : 'inactive'"
                    @click="selectedRoom = room.id"
                  >
                    {{ room.roomName }}
                  </ion-button>
                </div>
              </ion-col>
            </div>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <div
      v-else-if="selectedView === '3'"
      class="ion-padding"
    >
      <div
        v-if="loading"
        class="text--gray"
      >
        <ion-spinner
          color="light"
          name="crescent"
        /><br><br>
        {{ $t('Loading') }}....
      </div>
      <div v-else>
        <div
          v-if="deviceHistory.length"
          style="max-height: 400px"
          class="text--white text-left"
        >
          <div
            v-for="item in deviceHistory"
            :key="item"
          >
            <div
              v-if="device.zigbeeModel === 'SensorDoorWindow'"
              class="mb-10"
            >
              <div class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                <div class="mt-5">
                  {{ device.deviceName }} {{ $t('has been') }} {{ deviceStatusMapperHistory(item.status) }}
                </div>
              </div>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'SensorWaterLeak'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('sensorWaterLeakLeakDetected') }}
              </p>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'SensorMotion' || device.zigbeeModel === 'SensorVibration'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('sensorMotionMovement') }}
              </p>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'SensorSmoke'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('sensorSmokeSmokeDetected') }}
              </p>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'IASWarning'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('securityBreached') }} {{ $t('in') }} {{ deviceRoom(item.roomId) }}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text--gray">
            {{ $t('No history recorded') }}
          </p>
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer
    class="footer"
    :class="pickerOpen ? 'shade-out' : ''"
  >
    <ion-button
      v-if="selectedView === '2'"
      :disabled="!deviceName"
      @click="applyDeviceSetting(device.ieee)"
    >
      <ion-label>
        {{ $t('save') }}
      </ion-label>
    </ion-button>
    <ion-button
      v-if="selectedView === '1' && !device.tunable && !device.temperatureSensor && device.typeI !== 'SmartLock'"
      fill="clear"
      color="transparent"
      @click="getHistory(device); selectedView = '3'"
    >
      <ion-label color="primary">
        {{ $t('Events History') }}
      </ion-label>
    </ion-button>
    <ion-button
      v-if="selectedView === '1'"
      :disabled="pickerOpen"
      :class="canEdit === false ? 'disabled' : ''"
      @click="selectedView = '2'"
    >
      <ion-label>
        {{ $t('Edit Device') }}
      </ion-label>
    </ion-button>
    <ion-button
      fill="clear"
      :disabled="pickerOpen"
      color="transparent"
      @click="dismissPopover"
    >
      <ion-label color="primary">
        {{ $t('close') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import {
  batteryFullOutline,
  batteryDeadOutline,
  batteryHalfOutline,
  closeCircleOutline
} from 'ionicons/icons'
import { IonContent, alertController, popoverController, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import dayjs from 'dayjs'
import { Keyboard } from '@capacitor/keyboard'

import iro from '@jaames/iro'

import _ from 'lodash'

import picker from '../../assets/images/picker.png'
import pickerCCT from '../../assets/images/pickerCCT.png'
import pickerLevel from '../../assets/images/pickerLevel.png'
import linkBulbPopover from './LinkBulbPopOver.vue'
import lockDetailsModal from '../Security/LockDetailsModal'

export default defineComponent({
  name: 'Popover',
  components: { IonContent },
  props: {
    canLink: { type: Number, default: 0 },
    linkageExists: { type: Boolean, default: false },
    device: { type: Object, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      batteryFullOutline,
      batteryDeadOutline,
      closeCircleOutline,
      batteryHalfOutline,
      selectedView: '1',
      picker,
      pickerCCT,
      pickerLevel,
      smallScreen: false,
      deviceName: '',
      loading: false,
      pickerOpen: false,
      cctPickerOpen: false,
      levelOpen: false,
      rgbSelected: true,
      selectedRoom: Number,
      backgrounds: '',
      identifying: false,
      value: Number,
      deviceHistory: [],
      sirenActive: false,
      curtainControlEnabled: true
    }
  },
  computed: {
    canEdit () {
      let returnValue = false
      const deviceId = this.device.id
      const userDevices = this.$store.state.userData[0].userDevices ? this.$store.state.userData[0].userDevices : []
      console.log(userDevices)
      if (userDevices.length) {
        const index = userDevices.findIndex(item => item.id === deviceId)
        if (index !== -1) {
          if (userDevices[index].canEdit) {
            returnValue = true
          } else {
            returnValue = false
          }
        }
      } else {
        returnValue = true
      }

      return returnValue
    },
    propertyRooms () {
      return this.$store.state.propertyRooms
    },
    lightLevel: {
      get () {
        const propertyDevices = this.$store.state.propertyDevices
        const device = propertyDevices.filter(obj => obj.ieee === this.device.ieee)
        return device[0].lightLevel
      },
      set: _.debounce(function (value) {
        const ieee = this.device.ieee
        const ep = this.device.ep
        const val = parseInt(value)
        this.$store.dispatch('lightControl', { type: 'lightLevel', value: val, ieee, ep })
          .then(response => {
            if (response) {
              this.$store.commit('communication', false)
            }
          })
      }, 500)
    },
    colorTemp: {
      get () {
        const propertyDevices = this.$store.state.propertyDevices
        const device = propertyDevices.filter(obj => obj.ieee === this.device.ieee)
        return device[0].colorTemp
      },
      set: _.debounce(function (value) {
        const ieee = this.device.ieee
        const ep = this.device.ep
        console.log(value)
        const val = parseInt(value)
        this.$store.dispatch('lightControl', { type: 'colorTemp', value: val, ieee, ep })
          .then(response => {
            if (response) {
              this.$store.commit('communication', false)
            }
          })
      }, 500)
    },
    switchgear: {
      get () {
        const propertyDevices = this.$store.state.propertyDevices
        const device = propertyDevices.filter(obj => obj.ieee === this.device.ieee)
        let toggle
        if (device[0].switchgear === 'on') {
          toggle = true
        } else {
          toggle = false
        }
        return toggle
      },
      set (value) {
        const ieee = this.device.ieee
        const ep = this.device.ep
        let toggle
        if (value) {
          toggle = 'on'
        } else {
          toggle = 'off'
        }
        this.$store.dispatch('lightControl', { type: 'switchgear', value: toggle, ieee, ep })
          .then(response => {
            if (response) {
              this.$store.commit('communication', false)
            }
          })
      }
    },
    platform () {
      return this.$store.state.platform
    },
    lockWorking () {
      return this.$store.state.lockWorking
    },
    languageSelected () {
      console.log(this.$i18n.locale)
      return this.$i18n.locale
    }
  },
  watch: {
    valueRangeSlider: _.debounce(function (val) {
      this.setLightLevel(val)
    }, 500)
  },
  created () {
    this.deviceName = this.device.deviceName
    this.selectedRoom = this.device.propertyRoomId
  },
  mounted () {
    this.initiateRGBPicker()
  },
  methods: {
    identifyDevice (id) {
      this.$store.dispatch('identifyDevice', { deviceId: id }).then(resp => {
        if (resp) {
          this.identifying = true
          setTimeout(() => { this.identifying = false }, 30000)
        }
      })
    },
    getHistory (device) {
      this.loading = true
      this.$store.dispatch('getDeviceHistory', { ieee: device.ieee, type: device.typeI }).then(response => {
        console.log(response.data)
        if (this.device.typeI === 'SensorMotion' || this.device.typeI === 'SensorVibration' || this.device.typeI === 'SensorMulti') {
          const data = response.data.filter(item => item.type === 'Movement')
          this.deviceHistory = data
          this.loading = false
        } else {
          this.deviceHistory = response.data
          this.loading = false
        }
      })
    },
    dateFormatter (date) {
      const locale = this.$i18n.locale
      console.log(locale)
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      }

      return dayjs(date).locale(localeActive).format('dddd DD.MM.YYYY HH:mm')
    },
    applyDeviceSetting (ieee) {
      if (!this.deviceName) {
        return
      }
      const data = {
        ieee: ieee,
        deviceName: this.deviceName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).trim(),
        deviceRoom: this.selectedRoom
      }
      this.$store.dispatch('updateDeviceSetting', data).then(response => {
        if (response) {
          this.dismissPopover()
        }
      })
    },
    deviceStatusMapper (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = this.$t('sensorDoorWindowOpen')
      } else if (status === 'Closed') {
        statusResult = this.$t('sensorDoorWindowClose')
      } else if (status === 'No Movement') {
        statusResult = this.$t('sensorMotionNoMovement')
      } else if (status === 'Movement Detected') {
        statusResult = this.$t('sensorMotionMovement')
      } else if (status === 'No Smoke Detected') {
        statusResult = this.$t('sensorSmokeNoSmokeDetected')
      } else if (status === 'Smoke Detected') {
        statusResult = this.$t('sensorSmokeSmokeDetected')
      } else if (status === 'No Water Leak Detected') {
        statusResult = this.$t('sensorWaterLeakNoLeakDetected')
      } else if (status === 'Water Leak Detected!') {
        statusResult = this.$t('sensorWaterLeakLeakDetected')
      } else if (status === 'active') {
        statusResult = this.$t('smartSirenStatusActive')
      } else if (status === 'inactive') {
        statusResult = this.$t('smartSirenStatusSilent')
      }
      return statusResult
    },
    deviceStatusMapperHistory (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = this.$t('sensorDoorWindowOpened')
      } else if (status === 'Closed') {
        statusResult = this.$t('sensorDoorWindowClosed')
      } else if (status === 'No Movement') {
        statusResult = this.$t('sensorMotionNoMovement')
      } else if (status === 'Movement Detected') {
        statusResult = this.$t('sensorMotionMovement')
      } else if (status === 'No Smoke Detected') {
        statusResult = this.$t('sensorSmokeNoSmokeDetected')
      } else if (status === 'Smoke Detected') {
        statusResult = this.$t('sensorSmokeSmokeDetected')
      } else if (status === 'No Water Leak Detected') {
        statusResult = this.$t('sensorWaterLeakNoLeakDetected')
      } else if (status === 'Water Leak Detected!') {
        statusResult = this.$t('sensorWaterLeakLeakDetected')
      } else if (status === 'active') {
        statusResult = this.$t('smartSirenStatusActive')
      } else if (status === 'inactive') {
        statusResult = this.$t('smartSirenStatusSilent')
      }
      return statusResult
    },
    deviceRoom (roomId) {
      let result = ''
      if (roomId === 0) {
        result = 'Unassigned'
      } else {
        const rooms = this.$store.state.propertyRooms
        const found = rooms.find(element => element.id === roomId)
        result = found ? found.roomName : '--'
      }

      return result
    },
    removeDevice (ieee, ep, deviceId) {
      this.loading = true
      this.$store.dispatch('removeDevice', { ieee, ep, deviceId })
        .then(() => {
          this.dismissPopover()
          this.loading = false
        })
        .catch(async err => {
          this.loading = false
          const alert = await alertController
            .create({
              cssClass: 'remove-device',
              header: this.$t('Failure'),
              message: err,
              buttons: [
                {
                  text: this.$t('Ok'),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
                }
              ]
            })
          return alert.present()
        })
    },
    dismissPopover () {
      this.close()
    },
    async removeAlertConfirm (device) {
      console.log(device)
      if (device.landlordsDevice) {
        const alert = await alertController
          .create({
            cssClass: 'remove-device',
            header: this.$t('Failure'),
            message: this.$t('Device belog to the landlord'),
            buttons: [
              {
                text: this.$t('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
              }
            ]
          })
        return alert.present()
      } else {
        const alert = await alertController
          .create({
            cssClass: 'remove-device',
            header: this.$t('Remove this device?'),
            // message: 'Are you sure you want to remove this Device?',
            buttons: [
              {
                text: this.$t('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                }
              },
              {
                text: this.$t('Remove'),
                role: 'destructive',
                handler: () => {
                  this.removeDevice(device.ieee, device.ep, device.id)
                }
              }
            ]
          })
        return alert.present()
      }
    },
    // getRGBValue (colorY, colorX, lightLevel) {
    //   const x = colorX / 65536
    //   const y = colorY / 65536

    //   const Y = lightLevel * 100 / 255.0
    //   const X = x * (Y / y)
    //   const Z = (1 - x - y) * (Y / y)

    //   const varX = X / 100
    //   const varY = Y / 100
    //   const varZ = Z / 100

    //   let varR = varX * 3.2406 + varY * -1.5372 + varZ * -0.4986
    //   let varG = varX * -0.9689 + varY * 1.8758 + varZ * 0.0415
    //   let varB = varX * 0.0557 + varY * -0.2040 + varZ * 1.0570

    //   if (varR > 0.0031308) {
    //     varR = 1.055 * (varR ^ (1 / 2.4)) - 0.055
    //   } else {
    //     varR = 12.92 * varR
    //   }

    //   if (varG > 0.0031308) {
    //     varG = 1.055 * (varG ^ (1 / 2.4)) - 0.055
    //   } else {
    //     varG = 12.92 * varG
    //   }

    //   if (varB > 0.0031308) {
    //     varB = 1.055 * (varB ^ (1 / 2.4)) - 0.055
    //   } else {
    //     varB = 12.92 * varB
    //   }
    //   const sR = varR * 255
    //   const sG = varG * 255
    //   const sB = varB * 255
    // },
    changeValue (color) {
      const colour = color.rgbString.replace(/[^\d,]/g, '').split(',')
      const r = parseInt(colour[0])
      const g = parseInt(colour[1])
      const b = parseInt(colour[2])
      const ieee = this.device.ieee
      const ep = this.device.ep
      const response = this.setRGBValue(r, g, b)
      console.log(response)
      this.$store.dispatch('lightControl', { type: 'color', value: response, ieee, ep })
        .then(response => {
          if (response) {
            this.$store.commit('communication', false)
          }
        })
        .catch(async err => {
          const alert = await alertController
            .create({
              cssClass: 'remove-device',
              header: this.$t('Failure'),
              message: err,
              buttons: [
                {
                  text: this.$t('Ok'),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
                }
              ]
            })
          return alert.present()
        })
    },
    controlCurtain (action) {
      this.curtainControlEnabled = false
      this.$store.dispatch('curtainControl', {
        ieee: this.device.ieee,
        ep: this.device.ep,
        type: action
      })
        .then(() => {
          setTimeout(() => {
            this.curtainControlEnabled = true
          }, 1000)
        })
    },
    setRGBValue (r, g, b) {
      let varR = (r / 255.0)
      let varG = (g / 255.0)
      let varB = (b / 255.0)

      if (varR > 0.04045) {
        varR = Math.pow(((varR + 0.055) / 1.055), 2.4)
      } else {
        varR = varR / 12.92
      }
      if (varG > 0.04045) {
        varG = Math.pow(((varG + 0.055) / 1.055), 2.4)
      } else {
        varG = varG / 12.92
      }
      if (varB > 0.04045) {
        varB = Math.pow(((varB + 0.055) / 1.055), 2.4)
      } else {
        varB = varB / 12.92
      }

      varR = varR * 100
      varG = varG * 100
      varB = varB * 100

      const X = varR * 0.4124 + varG * 0.3576 + varB * 0.1805
      const Y = varR * 0.2126 + varG * 0.7152 + varB * 0.0722
      const Z = varR * 0.0193 + varG * 0.1192 + varB * 0.9505

      const x = Math.round((X / (X + Y + Z)) * 65536)
      const y = Math.round((Y / (X + Y + Z)) * 65536)

      return { colorX: x, colorY: y }
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    resetSiren (device) {
      this.$store.dispatch('resetSiren', device)
      this.sirenActive = false
    },
    testSiren (testId, device) {
      this.sirenActive = true
      this.$store.dispatch('testSiren', { testId, device })
      setTimeout(() => {
        this.sirenActive = false
      }, 60000)
    },
    initiateRGBPicker () {
      //       device.typeI === 'SmartLightColorDimmableFilament' ||
      // device.typeI === 'SmartLightColorDimmableFilamentIkea' ||
      // device.typeI === 'SmartLightColorDimmableFilament' ||
      // device.typeI === 'SmartLightColorDimmableSpot'"
      if (this.device.typeI === 'SmartLightColorChangeable' ||
          this.device.typeI === 'SmartLightColorDimmable' ||
          this.device.typeI === 'SmartLightDimmableIkea250Lm' ||
          this.device.typeI === 'SmartDriverDimmableIkea' ||
          this.device.typeI === 'SmartLightDimmableIkea806Lm' ||
          this.device.typeI === 'SmartSpotLightDimmableIkea345Lm' ||
          this.device.typeI === 'SmartLightColorDimmableFilament' ||
          this.device.typeI === 'SmartLightColorDimmableFilamentIkea' ||
          this.device.typeI === 'SmartLightColorDimmableSpot') {
        const width = window.screen.width
        let appliedWidth = 250
        if (width <= 375) {
          appliedWidth = 200
          this.smallScreen = true
        }
        if (this.device.typeI === 'SmartLightColorDimmable' ||
            this.device.typeI === 'SmartLightDimmableIkea250Lm' ||
            this.device.typeI === 'SmartDriverDimmableIkea' ||
            this.device.typeI === 'SmartLightDimmableIkea806Lm' ||
            this.device.typeI === 'SmartSpotLightDimmableIkea345Lm' ||
            this.device.typeI === 'SmartLightColorDimmableFilament' ||
            this.device.typeI === 'SmartLightColorDimmableFilamentIkea') {
          this.rgbSelected = false
        }
        this.$nextTick(() => {
          const colorPicker = iro.ColorPicker(this.$refs.Picker, {
            width: appliedWidth,
            layout: [
              {
                component: iro.ui.Wheel
              }
            ]
          })
          colorPicker.on('input:end', (color) => {
            this.changeValue(color)
          })
        })
      }
      // color:change -input:end
    },
    async openLinkagePopover (parentLinkDevice) {
      const deviceCanBeLinked = this.$store.state.propertyDevices.filter(item => (item.zigbeeModel === 'SmartLightColorDimmable' || item.zigbeeModel === 'SmartLightColorChangeable') && item.propertyRoomId === this.selectedRoom)
      const popover = await popoverController
        .create({
          component: linkBulbPopover,
          cssClass: 'popover',
          translucent: false,
          componentProps: {
            parentLinkDevice,
            deviceCanBeLinked,
            close: () => popover.dismiss()
          }
        })
      await popover.present()
    },
    touch (val) {
      console.log('touch  ' + val)
    },
    handleFocusOut () {
      if (this.levelOpen) {
        this.levelOpen = false
      }
    },
    openLevelPicker () {
      if (this.levelOpen) {
        this.levelOpen = false
      } else {
        setTimeout(() => {
          this.levelOpen = !this.levelOpen
        }, 100)
      }
    },
    setLockStatus (device, newStatus) {
      if (!this.lockWorking) {
        this.$store.commit('setLockInProgress', { progress: true })
        this.$store.dispatch('setLockStatus', {
          ieee: device.ieee,
          ep: device.ep,
          switchgear: newStatus
        })
          .then(resp => {
            console.log(resp.data)
            setTimeout(() => {
              this.$store.commit('setLockInProgress', { progress: false })
            }, 2000)
          })
          .catch(err => {
            this.$store.commit('setLockInProgress', { progress: false })
            console.log(err)
          })
      }
    },
    lockDetailsModal (lock) {
      // console.log(this.userData[0].role)
      const propertyLevelPermissions = this.$store.state.userData[0].propertyLevelPermissions
      // console.log(this.$store.state.currentPropertySelected, propertyLevelPermissions)
      let userRole = ''
      propertyLevelPermissions.forEach(item => {
        if (parseInt(item.propertyId) === parseInt(this.$store.state.currentPropertySelected)) {
          userRole = item.role
        }
      })
      if (userRole !== 'House Member') {
        return modalController
          .create({
            component: lockDetailsModal,
            cssClass: 'devices-modal',
            canDismiss: true,
            showBackdrop: false,
            backdropDismiss: true,
            componentProps: {
              lock: lock,
              close: () => this.closeModal()
            }
          })
          .then(m => m.present())
      }
    },
    closeModal () {
      modalController.dismiss()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

    // ion-header {
    //  background: red!important;
    //  ion-toolbar {
    //   background: yellow!important;
    //  }
    // }

.buttonRoom {
  padding-left: 2px;
  padding-right: 2px;
}
.inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
    max-height: 44px;
}
.active {
    --background: #3F424A;
    color: colors(primary, base);
    max-height: 44px;
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}
.color-picker {
  max-width: 250px;
  margin: 0 auto;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.device-image-popup {
  max-width: 200px;
}
.device-image {
  height: 120px;
}
.iro-picker-a {
  max-width: 250px;
}
.cct-picker-container {
  position: relative;
  width: 250px;
  height: 250px;
}
.cct-picker {
  z-index: -1;
  pointer-events: none;
  position: absolute;
  top: 0;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background-image: linear-gradient(to right,#dbe9f4, orange);
}
.light-level-container {
  position: relative;
  background: radial-gradient(100% 100% at 0% 0%, rgba(161, 161, 161, 0.9) 0%, rgba(95, 95, 95, 0.9) 100%);
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 8px;
  top: -170px;
  .light-level {
    --bar-height: 60px;
    --bar-border-radius: 10px;
    --bar-background-active: rgb(214, 214, 214);
    --bar-background: rgb(68, 68, 68);
    --knob-box-shadow: none;
    --knob-background: transparent;
  }
}

/* The slider itself */
.slider-temp {
  --bar-background: transparent;
  --bar-background-active: transparent;
  --knob-background: transparent;
  --knob-box-shadow: none;
  margin-top: 85px;
  width: 250px; /* Full-width */
  height: 80px; /* Specified height */
  margin-left: 0px;
  --bar-height: 90px;
  --knob-size: 13px;
  --knob-box-shadow: 0px 0px 0px 2px black;

}

.brightness-button {
  position: relative;
  background: colors(primary, base);
  padding: 8px 11px 8px 11px;
  border-radius: 50%;
  bottom: 20px;
  left: 0px;
}

.shade-out {
  opacity: 0.3;
  pointer-events: none;
}

.securityicon-svg{
  max-width: 200px;
}

@media only screen and (max-width: 375px) and (max-height: 667px) {
.iro-picker-a {
  max-width: 200px;
}
.cct-picker-container {
  width: 200px;
  height: 200px;
  .cct-picker {
      width: 200px;
  height: 200px;
  }
}
.color-picker {
  width: 200px;
}
.device-image-popup {
  max-width: 100px;
}
.device-image {
  display: none;
}
.slider-temp {
  margin-top: 60px;
}
}
@media only screen and (max-width: 414px) and (max-height: 736px)  {
.device-image-popup {
  max-width: 120px;
}
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.25s ease-out;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translateX(10px);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
