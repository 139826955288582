<template>
  <ion-page>
    <div class="background--modal" />
    <ion-header class="ion-no-border text-center">
      <ion-toolbar color="transparent">
        <ion-title
          class="text--white text-18"
        />
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div>
        <div>
          <i
            class="mdi mdi-check-circle-outline text--primary"
            style="font-size: 85px;"
          />
        </div>
        <div class="text--white">
          {{ $t('Your key has been shared! You can edit the access level anytime in your smart locks user setting') }}<br>
        </div>
        <div class="ion-padding">
          <ion-button
            @click="goBack"
          >
            <ion-label>{{ $t('Okey') }}</ion-label>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

export default {
  name: 'DoneSharing',
  components: {
  },
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      loading: true,
      sessionId: ''
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.sessionId = this.$route.params.id
  },
  mounted () {
    this.loading = true
  },
  methods: {
    goBack () {
      this.loading = true
      this.sessionId = ''
      this.dismissModal()
    },
    dismissModal () {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
.search {
  --background: #2c3036;
  text-align: left;
  --color: white;
  --icon-color: white;
  max-width: 400px;
}

.items{
  padding:0 8px;
  max-width: 520px;
  .product-img {
    max-width: 140px;
    margin-top: 5px;
  }
}
.h-65 {
    max-width: 150px;
    height: 54px;
    color: white;
    font-size: 16px;
    background-color: #35393f9c;
    text-align: center;
    border-radius: 5px;
    line-height: 50px;
}
.h-65-active {
  border: 1px solid #ffd4a4;
  border-radius: 5px;
      background: #3f424a;
    color: #ffd4a4;
}
.detail-picture {
  margin: 0 auto;
  max-width: 350px;
}
.product-description {
transition: all 0.5s ease;
}
.showMore {
  height: 230px;
  transition: height 0.15s ease-out;
  -webkit-transition: height 0.15s ease-out;
}
.showLess {
  height: 130px;
  transition: height 0.15s ease-in;
  -webkit-transition: height 0.15s ease-in;
}
</style>
