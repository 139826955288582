<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-title>
        {{ $t('MyDevicesTitle') }}
      </ion-title>
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="closeModal()"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-item
        v-if="gatewayProgress"
        slot="end"
        lines="none"
        color="transparent"
      >
        <ion-spinner
          color="light"
          name="crescent"
        />
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div v-if="!propertyDevices.length">
      <div class="text-center">
        {{ $t('No devices found...') }}
      </div>
    </div>
    <div class="connect-devices">
      <ion-row class="ion-margin-top">
        <ion-col
          v-for="device in propertyDevices"
          :key="device.id"
          size="6"
        >
          <div
            class="device rounded-corners"
            :class="device.active ? '' : 'room-card-devices-item-inactive'"
          >
            <div @click="openSettingPopover(device)">
              <div class="device-img">
                <img :src="require('../assets/devices/' + device.typeI + '.png')">
              </div>
              <h3>{{ device.deviceName }}</h3>
              <p
                class="text-12"
                :class="deviceRoom(device.propertyRoomId) === 'Unassigned' ? 'text--danger' : 'text--gray'"
              >
                {{ deviceRoom(device.propertyRoomId) }}
              </p>
            </div>
            <p
              v-if="device.security || device.alarm && device.active"
              class="text-12 text--primary"
            >
              {{ deviceStatusMapper(device.status) }}
            </p>
            <p
              v-else-if="device.zigbeeModel === 'IASWarning' && device.active"
              class="text-12 text--primary"
            >
              {{ deviceStatusMapper(device.status) }}
            </p>
            <p
              v-else-if="device.zigbeeModel === 'SensorTemperatureOutdoor' || device.zigbeeModel === 'SensorTemperatureIndoor' && device.active"
              class="text-12 text--gray"
            >
              {{ Math.round(device.temp/100) }}°C / {{ Math.round(device.humi/100) }}%
            </p>
            <p v-else-if="device.zigbeeModel === 'SmartLock' && device.active">
              {{ device.switchgear === 'lock' ? 'Locked' : 'Unlocked' }}
            </p>
            <ion-toggle
              v-else-if="device.tunable && device.active"
              class="mt-5"
              :value="device.switchgear === 'on' ? false : true"
              :checked="device.switchgear === 'on' ? true : false"
              @click="controlTunableDevice($event.target.value, device)"
            />
            <div
              v-else-if="!device.active"
              class="text--primary text-14"
            >
              {{ $t('Disconnected') }}
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row style="height: 80px;" />
    </div>
    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
    >
      <ion-fab-button @click="openAddDeviceModal(roomId)">
        <ion-icon
          color="dark"
          :icon="addOutline"
        />
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</template>

<script>
import { closeOutline, addOutline, alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons'

import { modalController, popoverController } from '@ionic/vue'

import addDeviceModal from '../components/AddDeviceModal'
import SettingPopOver from '../components/Popovers/SettingPopOver'

export default {
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      addOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
      closeOutline
    }
  },
  computed: {
    gatewayProgress () {
      return this.$store.state.gatewayProgress
    },
    propertyDevices () {
      return this.$store.state.propertyDevices
    }
  },
  methods: {
    deviceStatusMapper (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = this.$t('sensorDoorWindowOpen')
      } else if (status === 'Closed') {
        statusResult = this.$t('sensorDoorWindowClose')
      } else if (status === 'No Movement') {
        statusResult = this.$t('sensorMotionNoMovement')
      } else if (status === 'Movement Detected') {
        statusResult = this.$t('sensorMotionMovement')
      } else if (status === 'No Smoke Detected') {
        statusResult = this.$t('sensorSmokeNoSmokeDetected')
      } else if (status === 'Smoke Detected') {
        statusResult = this.$t('sensorSmokeSmokeDetected')
      } else if (status === 'No Water Leak Detected') {
        statusResult = this.$t('sensorWaterLeakNoLeakDetected')
      } else if (status === 'Water Leak Detected!') {
        statusResult = this.$t('sensorWaterLeakLeakDetected')
      } else if (status === 'active') {
        statusResult = this.$t('smartSirenStatusActive')
      } else if (status === 'inactive') {
        statusResult = this.$t('smartSirenStatusSilent')
      }
      return statusResult
    },
    async openSettingPopover (device) {
      const popover = await popoverController
        .create({
          component: SettingPopOver,
          cssClass: 'popover',
          translucent: false,
          componentProps: {
            device,
            close: () => popover.dismiss()
          }
        })
      await popover.present()
    },
    deviceRoom (roomId) {
      let result = ''
      if (roomId === 0) {
        result = 'Unassigned'
      } else {
        const rooms = this.$store.state.propertyRooms
        const found = rooms.find(element => element.id === roomId)
        result = found ? found.roomName : '--'
      }

      return result
    },
    deviceActive (id) {
      const devices = this.$store.state.propertyDevices
      const found = devices.find(element => element.id === id)
      return found.active
    },
    controlTunableDevice (value, device) {
      let toggle
      if (value === 'true') {
        toggle = 'on'
      } else {
        toggle = 'off'
      }
      this.$store.dispatch('lightControl', { type: 'switchgear', value: toggle, ieee: device.ieee, ep: device.ep })
        .then(response => {
          if (response) {
            this.$store.commit('communication', false)
          }
        })
    },
    openAddDeviceModal () {
      return modalController
        .create({
          component: addDeviceModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            roomId: null,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
ion-content, ion-item, ion-list, ion-header, ion-page {
  --background: transparent;
  background-color: transparent;
  color: white;
}
ion-fab {
 bottom: 30px;
}
.welcome {
  color: white;
}
.add-button {
  max-width: 80px;
  max-height: 30px;
}
.connect-devices{
  padding:0 8px;
  .device{
    height: 180px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
    @include bg-box-room-device;
  h3{
    margin: 0;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
  }
  .inactive {
    @include bg-box-room-device-inactive;
  }
  .room-card-devices-item-inactive {
  border: 1px solid red;
}
}
</style>
