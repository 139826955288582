<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView !== '3' && selectedView !== '4' && selectedView !== '5'"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else-if="selectedView === '5'"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = '3'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else-if="selectedView === '4'"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = '3'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = '1'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>

      <ion-title class="text--white text-18 text--400 mt-10">
        {{ lock.deviceName }}
        <div class="centertxt">
          <ion-text
            class="text--gray text-12"
          >
            {{ propertyName }}
          </ion-text>
        </div>
      </ion-title>
      <ion-button
        v-if="selectedView !== '3' && selectedView !== '4' && selectedView !== '5'"
        slot="end"
        debounce="500"
        :disabled="saving"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = '3'"
      >
        <ion-text>
          <i
            class="mdi mdi-tune saveicon"
          />
        </ion-text>
      </ion-button>
    </ion-toolbar>
    <ion-segment
      v-if="selectedView !== '3' && selectedView !== '4' && selectedView !== '5'"
      swipe-gesture="true"
      :value="String(selectedView)"
      style="width: 300px;"
      @ionChange="selectedView = $event.target.value"
    >
      <ion-segment-button value="1">
        <ion-label class="text-18">
          {{ $t('Smart Lock') }}
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="2">
        <ion-label class="text-18">
          {{ $t('History log') }}
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-header>
  <ion-content class="text-left">
    <div
      v-if="selectedView === '1'"
      class="create-scene"
    >
      <div class="security-overview">
        <div v-if="languageSelected === 'EN'">
          <div
            v-show="lock.switchgear==='unlock'"
            class="security-icon ion-padding-start ion-padding-end mt-20"
            tappable
            @click="setSmartLock('lock')"
          >
            <div>
              <img
                src="../../assets/images/lock-open.png"
                class="securityicon-svg"
              >
            </div>
          </div>
          <div
            v-show="lock.switchgear==='lock'"
            class="security-icon ion-padding-start ion-padding-end mt-20"
            tappable
            @click="setSmartLock('unlock')"
          >
            <div>
              <img
                src="../../assets/images/locked.png"
                class="securityicon-svg"
              >
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-show="lock.switchgear==='unlock'"
            class="security-icon ion-padding-start ion-padding-end mt-20"
            tappable
            @click="setSmartLock('lock')"
          >
            <div>
              <img
                src="../../assets/images/lock-open-se.png"
                class="securityicon-svg"
              >
            </div>
          </div>
          <div
            v-show="lock.switchgear==='lock'"
            class="security-icon ion-padding-start ion-padding-end mt-20"
            tappable
            @click="setSmartLock('unlock')"
          >
            <div>
              <img
                src="../../assets/images/locked-se.png"
                class="securityicon-svg"
              >
            </div>
          </div>
        </div>
        <div>
          <div class="centertxt">
            <ion-text
              v-if="unlocked===''"
              class="text--white text-16"
            >
              {{ $t('Tap "Open" to unlock') }}
            </ion-text>
            <ion-text
              v-if="unlocked===true"
              class="text--white text-16"
            >
              <i class="mdi mdi-check-decagram text--primary text-18" /> {{ $t('Smart lock is open') }}
            </ion-text>
            <ion-text
              v-else-if="unlocked===false"
              class="text--white text-16"
            >
              <i class="mdi mdi-alert-decagram text--primary text-18" /> {{ $t('Could not open smart lock') }}
            </ion-text>
          </div>
        </div>
      </div>
      <div class="security-overview">
        <div class="security-devices ion-padding-start ion-padding-end">
          <div class="text-left ion-padding-start ion-padding-end">
            <ion-text class="text--white text-16 text-bold">
              {{ $t('Active keys') }}
            </ion-text>
          </div>
          <div
            v-for="item in stateActiveSmartLockKeys"
            :key="item"
          >
            <ion-item
              lines="none"
              class="h-100"
              button
              detail="false"
              @click="presentActionSheet(item)"
            >
              <ion-label class="text--white">
                <p style="color: white;">
                  {{ item.userName }}
                </p>
                <p style="color: #D9E1D980;">
                  {{ item.userId === null ? $t('guest') : $t('member') }}, {{ $t(item.accessType) }}
                </p>
              </ion-label>
              <i
                class="mdi mdi-chevron-right text--white text-16 mr-10"
              />
              <!-- <i
                class="mdi mdi-chevron-right alert-icon"
              />-->
            </ion-item>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="selectedView === '2'"
      class="room-details ion-padding create-scene"
    >
      <div class="security-overview">
        <div class="security-devices ion-padding-start ion-padding-end">
          <div
            v-for="item in accessLogs"
            :key="item"
          >
            <ion-label class="text--white mt-20">
              <i
                class="mdi unlockicon"
                :class="item.switchgear==='lock' ? 'mdi-lock' : 'mdi-lock-open'"
              /> &nbsp;
              <p style="color: gray;">
                {{ dateFormatter(item.time) }}
              </p>
            </ion-label>
            <ion-label class="text--white mt-10 mb-10">
              <p style="color: white;">
                {{ item.key }}
              </p>
            </ion-label>
          </div>
          <ion-infinite-scroll
            id="infinite-scroll"
            threshold="100px"
            :disabled="isDisabled"
            @ionInfinite="loadData($event)"
          >
            <ion-infinite-scroll-content
              loading-spinner="circular"
              loading-text=""
            />
          </ion-infinite-scroll>
        </div>
      </div>
    </div>
    <div v-else-if="selectedView === '3'">
      <div class="ion-padding-start ion-padding-end ion-padding-top">
        <ion-item
          button
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners"
          @click="selectedView = '4'"
        >
          <i
            slot="start"
            class="mdi mdi-tune-variant text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('Auto-lock') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          button
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners"
          @click="selectedView = '5'"
        >
          <i
            slot="start"
            class="mdi mdi-bell-ring text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('notifications') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
      </div>
    </div>
    <div v-else-if="selectedView === '4'">
      <div class="ion-padding-start ion-padding-end ion-padding-top">
        <div class="text--primary">
          {{ $t('features') }}
        </div>
        <div>
          <ion-item
            lines="none"
            class="device-item"
            color="transparent"
            detail="false"
          >
            <div
              slot="start"
              class="text--white"
            >
              {{ $t('Auto-lock Enabled') }}
            </div>
            <ion-toggle
              slot="end"
              v-model="autoLockEnabled"
            />
          </ion-item>
          <ion-item
            lines="none"
            class="device-item"
            color="transparent"
            detail="false"
          >
            <div
              slot="start"
              class="text--white"
            >
              {{ $t('Auto-lock Timer') }}
            </div>
            <ion-select
              slot="end"
              v-model="selectedAutoLockDelay"
              class="text--white"
            >
              <ion-select-option
                v-for="{value, name} in autoLockDelays"
                :key="value"
                :value="value"
              >
                {{ name }}
              </ion-select-option>
            </ion-select>
            <i
              slot="end"
              class="mdi mdi-chevron-down text--gray"
              style="font-size: 20px;"
            />
          </ion-item>
        </div>
      </div>
    </div>
    <div v-else-if="selectedView === '5'">
      <div class="ion-padding-start ion-padding-end ion-padding-top security-devices">
        <div class="text--gray text-14">
          {{ $t('Here you can select which keys you wish to receive notifcations from') }}
        </div>
        <div
          v-for="item in stateActiveSmartLockKeys"
          :key="item"
        >
          <ion-item
            lines="none"
            class="h-100"
            button
            detail="false"
          >
            <ion-label class="text--white">
              <p style="color: white;">
                {{ item.userName }}
              </p>
              <p style="color: #D9E1D980;">
                {{ item.userId === null ? $t('guest') : $t('member') }}, {{ $t(item.accessType) }}
              </p>
            </ion-label>
            <ion-toggle
              slot="end"
              :value="item.sendNotification"
              :checked="item.sendNotification"
              @click="smartLockChangeNotification(item.sendNotification, item.ieee, item.id)"
            />
            <!-- <i
              class="mdi mdi-chevron-right alert-icon"
            />-->
          </ion-item>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline,
  chevronBackOutline,
  addOutline,
  chevronForwardOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import {
  modalController,
  actionSheetController,
  alertController
} from '@ionic/vue'

import dayjs from 'dayjs'
import shareKeyModal from './ShareKeyModal'

import { Share } from '@capacitor/share'
import editKeyModal from './EditKey'
import doneSharing from './DoneSharing'

import _ from 'lodash'

export default defineComponent({
  components: {},
  props: {
    lock: { type: Object, default: () => {} },
    close: { type: Function, default: null }
  },
  data () {
    return {
      lodash: _,
      autoLockEnabled: true,
      selectedAutoLockDelay: 5,
      autoLockDelays: [
        { value: 1, name: '1 min' },
        { value: 2, name: '2 min' },
        { value: 4, name: '3 min' },
        { value: 4, name: '4 min' },
        { value: 5, name: '5 min' }
      ],
      closeOutline,
      addCircleOutline,
      chevronBackOutline,
      chevronForwardOutline,
      addOutline,
      selectedView: '1',
      selectedViewDetail: 1,
      selectedViewSub: 1,
      saving: false,
      todayDateTime: 0,
      tomorrowDateTime: 0,
      limitTerms: [
        { id: 1, value: 'daily', name: 'Per Day' },
        { id: 2, value: 'weekly', name: 'Per Week' },
        { id: 3, value: 'monthly', name: 'Per Month' }
      ],
      newKey: {},
      dismisstxt: '',
      unlocked: '',
      tapped: false,
      propertyName: '',
      accessKeys: [],
      accessLogs: [],
      isDisabled: false,
      logCount: 0
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    lockWorking () {
      return this.$store.state.lockWorking
    },
    propertyId () {
      return this.$store.state.currentPropertySelected
    },
    stateActiveSmartLockKeys () {
      return this.$store.state.activeSmartLockKeys
    },
    languageSelected () {
      console.log(this.$i18n.locale)
      return this.$i18n.locale
    }
  },
  watch: {
    autoLockEnabled: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.saveLockFeatures()
      }
    },
    selectedAutoLockDelay: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.saveLockFeatures()
      }
    }
  },
  created () {
    this.propertyName = this.$store.state.userProperties[0].propertyName
    this.newKey = this.lock
    this.unlocked = this.newKey.switchgear
    // console.log('thisunlock: ' + this.unlocked)
    this.getSmartLockKeys(this.propertyId, this.lock.ieee)
    this.getSmartLockKeyLogs(this.lock.ieee)

    this.dismisstxt = this.$t('Dismiss')
  },
  mounted () {
    this.autoLockEnabled = this.lock.autoLockEnabled
    this.selectedAutoLockDelay = this.lock.selectedAutoLockDelay
  },
  methods: {
    saveLockFeatures () {
      this.$store.dispatch('saveLockFeatures', { autoLockEnabled: this.autoLockEnabled, selectedAutoLockDelay: this.selectedAutoLockDelay, ieee: this.lock.ieee })
    },
    dismissModal () {
      this.close()
    },
    async canShare (key) {
      // console.log(this.getKeyAccess(key))
      await Share.canShare().then(resp => {
        if (resp.value) {
          this.showShare(key)
        } else {
          console.log('cannot share')
          this.errorAlert(this.$t('Share API not available in this browser'))
        }
      })
    },
    async showShare (key) {
      const sharingTxt = this.getKeyAccess(key)
      await Share.share({
        title: this.$t('Homevision Key'),
        text: sharingTxt,
        url: 'https://app.homevision.se/control-lock/' + key.keyToken,
        dialogTitle: this.$t('Homevision Key')
      })
        .then(resp => {
          this.dismissModal()
          this.doneSharing()
          // alert(JSON.stringify(resp))
        })
        .catch((error) => console.log('Error sharing ::: ', error))
    },
    async errorAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: [this.dismisstxt]
        })
      await alert.present()
    },
    shareKeyModal (lock) {
      return modalController
        .create({
          component: shareKeyModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            lock: lock,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    doneSharing () {
      return modalController
        .create({
          component: doneSharing,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    getSmartLockKeys (propertyId, ieee) {
      this.$store.dispatch('listActiveSmartLockKeys', { propertyId, ieee })
        .then(resp => {
          this.accessKeys = resp.data
          this.accessKeys.forEach(item => {
            const hist = JSON.parse(item.usageHistory)
            hist.forEach(historyItem => {
              this.accessLogs.push({ key: item.keyName, time: historyItem })
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSmartLockKeyLogs (ieee) {
      this.$store.dispatch('getSmartLockKeyLogs', { ieee: ieee, logCount: this.logCount })
        .then(resp => {
          const logs = resp.data
          logs.forEach(item => {
            this.accessLogs.push({ key: item.status, switchgear: item.switchgear, time: item.createdAt })
            this.logCount++
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    setSmartLock (status) {
      if (!this.lockWorking) {
        this.$store.commit('setLockInProgress', { progress: true })
        this.newKey.switchgear = status
        this.$store.dispatch('setLockStatus', this.newKey)
          .then(resp => {
            console.log('setSmartLock')
            console.log(resp.data)
            setTimeout(() => {
              this.$store.commit('setLockInProgress', { progress: false })
            }, 1000)
          })
          .catch(err => {
            console.log(err)
            setTimeout(() => {
              this.$store.commit('setLockInProgress', { progress: false })
            }, 1000)
          })
      }
    },
    async showWarning (key) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Varning'),
          message: this.$t('You are about the share your door lock key. Are you sure you want to proceed?'),
          buttons: [
            {
              text: this.$t('Ja'),
              cssClass: 'secondary',
              handler: () => {
                this.canShare(key)
              }
            },
            {
              text: this.$t('Avbryt'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('')
              }
            }
          ]
        })
      await alert.present()
    },
    async presentActionSheet (key) {
      const arrbtns = [
        {
          text: this.$t('Edit access'),
          handler: () => {
            this.editKeyModal(key)
          }
        },
        {
          text: this.$t('Re-invite'),
          handler: () => {
            this.showWarning(key)
          }
        },
        {
          text: this.$t('Remove access'),
          role: 'destructive',
          handler: () => {
            this.$store.dispatch('deleteSmartLockKey', key).then(response => {
              this.getSmartLockKeys()
            })
          }
        },
        {
          text: this.$t('Cancel'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          }
        }
      ]
      if (key.userId) {
        arrbtns.splice(1, 1)
      }
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: arrbtns
        })
      await actionSheet.present()

      // const { role } = await actionSheet.onDidDismiss()
      // console.log('onDidDismiss resolved with role', role)
    },
    editKeyModal (key) {
      // console.log('in share: key')
      // console.log(key)
      return modalController
        .create({
          component: editKeyModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            lock: key,
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    dateFormatter (date) {
      const locale = this.$i18n.locale
      console.log(locale)
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      }

      return dayjs(date).locale(localeActive).format('DD/MM/YYYY HH:mm')
    },
    pushData () {
      this.$store.dispatch('getSmartLockKeyLogs', { ieee: this.lock.ieee, logCount: this.logCount }).then(resp => {
        const logs = resp.data
        logs.forEach(item => {
          this.accessLogs.push({ key: item.status, switchgear: item.switchgear, time: item.createdAt })
          this.logCount++
        })
        if (resp.data.length === 0) {
          this.isDisabled = true
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    loadData (ev) {
      setTimeout(() => {
        this.pushData()
        console.log('Loaded data')
        ev.target.complete()
      }, 500)
    },
    getKeyAccess (key) {
      let accessStr = this.$t('Hi') + ' ' + key.userName + ', \r\n\r\n'
      accessStr += this.$t('Here is your digital key!') + ' ' + this.$t('Use the link for access.')
      accessStr += '\r\n\r\n'
      accessStr += this.$t('Access Level') + ': ' + this.$t(key.accessType) + '\r\n'
      if (key.accessType === 'temporary') {
        const dayfrom = dayjs(key.startDate)
        const dayto = dayjs(key.endDate)
        accessStr += this.$t('App Access') + ': ' + dayfrom.format('MMM D, YYYY HH:mm') + ' - ' + dayto.format('MMM D, YYYY HH:mm')
      }
      if (key.accessType === 'recurring') {
        const dayfrom = dayjs(key.startDate)
        const dayto = dayjs(key.endDate)
        let days = ''
        key.appliedDays.forEach(item => {
          days += ', ' + this.$t(item)
        })
        accessStr += this.$t('App Access') + ': ' + dayfrom.format('HH:mm') + '-' + dayto.format('HH:mm') + days
      }
      return accessStr
    },
    smartLockChangeNotification (notification, ieee, id) {
      if (notification === true) {
        notification = false
      } else {
        notification = true
      }
      console.log('notification, ieee')
      console.log(notification, ieee)
      this.$store.dispatch('smartLockChangeNotification', { ieee: ieee, notification: notification, id: id }).then(resp => {
        console.log(resp.data)
        this.getSmartLockKeys(this.propertyId, ieee)
      })
        .catch(err => {
          console.log(err)
        })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.remove-device {
  .alert-wrapper {
    .alert-title {
      font-size: 16px !important;
      letter-spacing: 0.5px !important;
    }
  }
}
.no-ripple {
     --ripple-color: transparent;
    --background-activated: transparent;
}

.device{
    height: 180px;
    @include bg-box-room-device;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
  h3{
    margin: 0;
    margin-top:10px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
}

.devices-modal ion-title{
  text-align: center;
}
.link-disabled {
  filter: opacity(40%);
}
.saveicon{
  font-size:25px;
  color:#fff;
}
.unlockicon{
  color:#FFD4A4;
  font-size:22px;
}
ion-datetime{
  --padding-top: 2px;
}
.form--dark ion-item#limittoggle{
  margin:20px 0 0 0 !important;
  --min-height: 20px;
}
.form--dark ion-item#limittoggle ion-toggle{
  padding:0px;
}
.create-scene ion-text{
  display:block;
}
.btnlowertxt, .centertxt {
  text-align: center;
}
.title-default{
  text-align:center;
}
.security-overview .security-icon{
  text-align:center;
}
.verified ion-text img{
    vertical-align: text-top;
}
.securityicon-svg{
   margin-top:-20px;
   margin-bottom:-10px;
   max-width: 200px;
}

.security-devices ion-item{
  --background: #3F424A;
  background: #3F424A;
  padding:5px 0;
  border-radius: 10px;
  height:65px;
  margin-top:10px;
  color:#fff;
  border: 1px solid #636363;
}

.security-devices{
  margin-top:20px;
}
.security-devices ion-text{
  letter-spacing: 0.8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.security-devices ion-item  span{
  color: #fff;
  padding: 6px;
  border-radius: 15px;
  font-size:12px;
  width: 43px;
  height: 26px;
  text-align: center;
  background: rgba(120, 120, 128, 0.32);
}

.alert-icon {
    border-radius: 15px;
    font-size: 22px;
    width: 43px;
    height: 26px;
    text-align: center;
}

.security-devices ion-item  span .greenz{
  background: #0CD0A1 !important;
    background: transparent;
    font-size: 1px;
    width: 1px;
    padding: 2px;
    margin-right: 3px;
    vertical-align:middle;
}

.security-devices ion-item  span .redz{
  background: #d00c0c !important;
    background: transparent;
    font-size: 6px;
    width: 6px;
    padding: 2px;
    margin-right: 3px;
    vertical-align:middle;
}

.security-devices ion-label{
margin-left:10px;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
}

</style>