<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-item
        v-if="selectedView === 1"
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-item
        v-else
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView--"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title class="text-18 text--white">
        {{ $t('Previous orders') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end">
    <div v-if="selectedView === 1">
      <ion-item
        v-for="order in orders"
        :key="order.id"
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-8 text--white"
        @click="openOrder(order.id)"
      >
        <span
          slot="start"
          class="text-14 text--primary mr-5"
        >
          ID: {{ order.orderId }}
        </span>
        <div
          class="text-14 ml-10 text--white"
        >
          {{ order.totalPaid }} kr
        </div>
        <div
          slot="end"
          style="min-width: 100px;"
        >
          <span class="text-12">Order: {{ mapStatus(order.paymentStatus) }}</span><br>
          <span class="text-12">Status: {{ mapStatus(order.orderStatus) }}</span>
        </div>
        <i
          slot="end"
          class="mdi mdi-chevron-right text--gray"
        />
      </ion-item>
    </div>
    <div
      v-else
      class="mb-10 mt-20"
    >
      <div class="text-left">
        <span class="text--gray text-14">{{ $t('Order Id:') }} {{ orderDetailView[0].orderId }}</span>
      </div>
      <div class="text-left text-14">
        <span class="text--gray">{{ $t('Ordered') }}: {{ new Date(orderDetailView[0].createdAt).toLocaleString() }}</span>
      </div>
      <div class="text-left mt-10 text-14">
        <span class="text--white">{{ $t('Items') }}:</span>
      </div>
      <div>
        <ion-item
          v-for="(item, i) in orderDetailView[0].orderItems"
          :key="item.id"
          detail="false"
          lines="none"
          color="transparent"
          class="item-order rounded-corners mt-8 text--white"
        >
          <span
            slot="start"
            class="text-14"
          >{{ i + 1 }}</span>
          <ion-img
            class="item-picture"
            :src="item.picture"
          />
          <div>
            <div class="text-14 ml-10">
              {{ item.name }}
            </div>
            <div
              class="text-left text-12 mt-5 ml-10"
              style="min-width: 100px;"
            >
              <span
                v-if="item.paymentStyle === 'subscription'"
              >
                <span v-if="!item.isMember || item.itemCategory === 'Homevision Kit'">{{ item.subPrice }}</span><span v-else>{{ item.memberSubscriptionPriceMonth }}</span> kr <span class="text--primary">{{ $t('monthly') }} <span
                  v-if="item.qty > 1"
                  class="text--primary"
                >x {{ item.qty }}</span></span><br>
                <span
                  v-if="item.subscriptionUpfrontPrice"
                  class="text-10 text--gray"
                >{{ item.subscriptionUpfrontPrice }} kr {{ $t('Upfrontcost') }} <span
                  v-if="item.qty > 1"
                  class="text--primary"
                >x {{ item.qty }}</span></span>
              </span>
              <span
                v-else-if="item.paymentStyle === 'onetime'"
              >
                <!-- <span v-if="item.discountedPrice"><span style="text-decoration: line-through;">{{ item.price }}</span> <span class="text--primary">{{ item.price - item.discountedPrice }} SEK</span></span> -->
                <span>{{ item.price }} kr <span
                  v-if="item.qty > 1"
                  class="text--primary"
                >x {{ item.qty }}</span></span>
              </span>
            </div>
          </div>
          <!-- <div
                slot="end"
                class="item-qty"
              >
                <ion-input :value="item.qty" />
              </div> -->
          <div
            slot="end"
          >
            <!-- <i
              class="mdi mdi-minus-circle-outline text--primary mr-10"
              style="font-size: 25px; display: inline-block; margin-top: 10px;"
              tappable
              @click="removeItem(item)"
            /> -->
            <div
              class="text-14"
              style="min-width: 20px; display: inline-block; text-align: center; vertical-align: middle; margin-bottom: 0px;"
            >
              <span class="text--gray text-14">{{ $t('Qty') }}: </span> {{ item.qty }}
            </div>
            <!-- <i
              class="mdi mdi-plus-circle-outline text--primary ml-10"
              style="font-size: 25px; display: inline-block; margin-top: 10px;"
              tappable
              @click="addItem(item)"
            /> -->
          </div>
        </ion-item>
      </div>
      <!-- <hr> -->
      <div class="text-right text--white mt-10">
        <span v-if="orderDetailView[0].paymentStatus === 'Paid'">
          <span class="mr-20">{{ $t('Total paid') }}:</span>
          {{ orderDetailView[0].totalPaid }}kr
        </span>
        <span v-else>
          <span class="mr-20 text--primary">{{ mapStatus('Unpaid') }}</span>
        </span>
      </div>
      <div class="text-left text--white mt-30">
        <span class="text-14">
          {{ $t('Shipping Details') }}
        </span>
      </div>
      <div class="text--gray text-14 mt-10 text-left">
        <ion-row class="ion-no-padding">
          <ion-col
            class="ion-no-padding"
            size="4"
          >
            {{ $t('Name') }}:
          </ion-col>
          <ion-col class="ion-no-padding">
            <span>{{ orderDetailView[0].customerName }}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col
            class="ion-no-padding"
            size="4"
          >
            {{ $t('Your email') }}:
          </ion-col>
          <ion-col class="ion-no-padding">
            <span>{{ orderDetailView[0].customerEmail }}</span>
          </ion-col>
        </ion-row>
        <ion-row class="mt-5">
          <ion-col
            class="ion-no-padding"
            size="4"
          >
            {{ $t('Address') }}:
          </ion-col>
          <ion-col class="ion-no-padding">
            <span>{{ orderDetailView[0].shipToAddress.street }}</span><br>
            <span>{{ orderDetailView[0].shipToAddress.city }}</span><br>
            <span>{{ orderDetailView[0].shipToAddress.postCode }}</span><br>
          </ion-col>
        </ion-row>
        <!-- {{ $t('Name') }}: <span style="padding-left: 26px;">{{ orderDetailView[0].customerName }}</span><br>
        {{ $t('Your email') }}: <span style="padding-left: 29px;">{{ orderDetailView[0].customerEmail }}</span><br>
        <div class="mt-5" />
        {{ $t('Address') }}: <span style="padding-left: 10px;">{{ orderDetailView[0].shipToAddress.street }}</span><br>
        <span style="padding-left: 70px;">{{ orderDetailView[0].shipToAddress.city }}</span><br>
        <span style="padding-left: 70px;">{{ orderDetailView[0].shipToAddress.postCode }}</span><br> -->
      </div>
      <!-- {{ orderDetailView }} -->
    </div>
    <div class="spacer" />
  </ion-content>
</template>

<script>
export default ({
  components: {
  },
  props: {
    orders: { type: Array, default: () => [] },
    close: { type: Function, default: null }
  },
  data () {
    return {
      selectedView: 1,
      orderDetailView: []
    }
  },
  computed: {
    isMember () {
      return this.$store.state.isMember
    }
  },
  watch: {
  },
  mounted () {
  },
  created () {
  },
  methods: {
    openOrder (id) {
      const orderDetail = this.orders.filter(item => item.id === id)
      this.orderDetailView = orderDetail
      this.selectedView = 2
    },
    dismissModal () {
      this.close()
    },
    mapStatus (status) {
      let result
      if (status === 'Active') {
        result = 'Aktiv'
      } else if (status === 'Requested cancellation') {
        result = 'Pågående'
      } else if (status === 'Cancelled') {
        result = 'Avbruten'
      } else if (status === 'Completed') {
        result = 'Avslutad'
      } else if (status === 'Paid') {
        result = 'Betald'
      } else if (status === 'Unpaid') {
        result = 'Obetald'
      } else if (status === 'Preparing') {
        result = 'Förbereder'
      } else if (status === 'Dispatched') {
        result = 'Skickad'
      } else if (status === 'Delivered') {
        result = 'Levererad'
      } else if (status === 'Returning') {
        result = 'Retur'
      } else if (status === 'Returned') {
        result = 'Returnerad'
      }
      return result
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.item-order {
    // max-width: 20px;
    height: 50px;
    padding: 0;
    margin-inline: 0 0;
    .item-inner {
        margin-inline: 0;
        padding-inline-end: 0;
        padding-inline-start: 0;
    }
}
.item-picture {
    margin-inline: 0;
    max-width: 40px;
}
.item-qty {
    border: 1px solid white;
    color: white;
    font-size: 13px;
    max-width: 35px;
    max-height: 25px;
    padding-left: 7px;
    ion-input {
      max-height: 25px;
      padding: 0;
      margin: 0;
    }
    // padding: 3px;
    // margin-inline: 0;
  //     --inner-padding-bottom: 0;
  // --inner-padding-end: 0;
  // --inner-padding-start: 0;
  // --inner-padding-top: 0;
  // --inner-margin: 0;
  // --ion-safe-area-right: 0;
  // --ion-safe-area-left: 0;
  // --ion-safe-area: 0;
  // --ion-safe-area-bottom: 0;
  //   .item-inner {
  //       margin-inline: 0;
  //       padding-inline-end: 0;
  //       padding-inline-start: 0;
  //   }
}
.spacer {
  min-height: 90px;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 0;
    border-top: 1px solid #ccc;
    padding: 0;
}
</style>
