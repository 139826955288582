<template>
  <ion-header
    class="ion-no-border"
    style="z-index: 1;"
  >
    <ion-toolbar
      color="transparent"
    >
      <ion-title
        class="text-18 text--white"
      >
        {{ $t('Bulb linkage') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content
    class="ion-padding"
    scroll-y="true"
  >
    <ion-row>
      <ion-col
        v-for="device in devicesFiltered"
        :key="device.id"
        class="card-device-item-wrapper"
        size="6"
      >
        <div class="card-devices-item rounded-corners">
          <div class="mt-5">
            <img
              v-if="device.typeI === 'SmartLightColorDimmable'"
              class="device-icon"
              src="../../assets/devices/SmartLightColorDimmable.png"
            >
            <img
              v-else
              class="device-icon"
              src="../../assets/devices/SmartLightColorChangeable.png"
            >
          </div>
          <div class="text-14 text--white mt-5">
            {{ device.deviceName }}
          </div>
          <div class="text-center mt-5">
            <ion-item
              color="transparent"
              lines="none"
            >
              <i
                class="mdi mr-10"
                :class="isDeviceLinked(device) ? 'mdi-link text--primary' : 'mdi-link-off text--gray'"
                style="font-size: 25px;"
              />
              <ion-toggle
                :value="isDeviceLinked(device) ? false : true"
                :checked="isDeviceLinked(device)"
                @ionChange="linkDevice($event.target.value, device)"
              />
            </ion-item>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-content>
  <ion-footer class="footer">
    <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover"
    >
      <ion-label color="primary">
        {{ $t('close') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'

// import _ from 'lodash'

export default defineComponent({
  name: 'Linkage',
  components: { IonContent },
  props: {
    parentLinkDevice: { type: Object, default: null },
    deviceCanBeLinked: { type: Object, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      selectedView: '1'
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    devicesFiltered () {
      return this.deviceCanBeLinked.filter(item => item.id !== this.parentLinkDevice.id)
    }
  },
  created () {
  },
  mounted () {
    // console.log(this.deviceCanBeLinked)
    // console.log(this.parentLinkDevice)
  },
  methods: {
    dismissPopover () {
      this.close()
    },
    isDeviceLinked (device) {
      const linkExists = this.parentLinkDevice.linkages.findIndex(item => item.ieee === device.ieee)
      if (linkExists === -1) {
        return false
      } else {
        return true
      }
    },
    linkDevice (value, device) {
      if (value === 'true' || value === true) {
        const deviceToLink = {
          deviceId: device.id,
          ieee: device.ieee,
          ep: device.ep
        }
        this.$store.dispatch('deviceLinkages', { type: 'add', deviceToLink, parentLinkDevice: this.parentLinkDevice }).then(resp => {
          console.log(resp)
        })
      } else {
        const deviceToLink = {
          deviceId: device.id,
          ieee: device.ieee,
          ep: device.ep
        }
        this.$store.dispatch('deviceLinkages', { type: 'remove', deviceToLink, parentLinkDevice: this.parentLinkDevice }).then(resp => {
          console.log(resp)
        })
      }
      console.log(device)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.card-device-item-wrapper {
  padding: 4px;
}
.card-devices-item {
  padding: 7px;
  min-height: 150px;
  @include bg-box-room-device;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
}

.device-icon {
    width: 60px;
    margin: auto;
    display: block;
}
</style>
