import { createStore } from 'vuex'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import $socket from '../lib/socketIo'

import router from '@/router/index'
import dayjs from 'dayjs'

// import { Plugins } from '@capacitor/core'
// const { PushNotifications } = Plugins
// import udpController from '../lib/udpController'
// import tcpController from '../lib/tcpController'

export default createStore({
  state () {
    return {
      currentPropertySelected: localStorage.getItem('current_property') || null,
      platform: [],
      keyboardStatus: 'closed',
      token: localStorage.getItem('access_token') || null,
      userId: localStorage.getItem('user') || null,
      propertyId: localStorage.getItem('property_id') || null,
      agentId: localStorage.getItem('agent_id') || null,
      userName: localStorage.getItem('user_name') || null,
      userRole: localStorage.getItem('user_role') || null,
      landlordId: localStorage.getItem('landlord_id') || null,
      language: localStorage.getItem('language') || null,
      subscribeNewsletter: localStorage.getItem('subscribeNewsletter') || null,
      subscribeDealsOffers: localStorage.getItem('subscribeDealsOffers') || null,
      mainLandlord: localStorage.getItem('ml') || null,
      canBeAdminOfMainLandlord: localStorage.getItem('cbadol') || null,
      companyName: localStorage.getItem('company_name') || null,
      landlordName: '',
      isMember: null,
      isIkeaMember: false,
      // pullingLandlordsData: false,
      userData: [],
      profileImage: '',
      propertyScenes: [],
      userProperties: [],
      propertyRooms: [],
      propertyDevices: [],
      propertyCameras: [],
      propertyUsers: [],
      landlordBuildings: [],
      landlordProperties: [],
      landlordDevices: [],
      landlordOverviewPieData: [],
      statusBucketLandlord: [],
      landlordNotifications: [],
      showLandlordsNotification: false,
      latestLandlordsNotification: [],
      deviceAddStatus: null,
      propertyAutomation: [],
      notification: [],
      propertyAlarm: false,
      weather: {},
      gatewayProgress: false,
      userHasNoGateway: null,
      data: [],
      tcpData: null,
      socketTcpId: null,
      tcpStatus: true,
      gatewayIp: null,
      availableWifi: [],
      gwSessionId: null,
      communication: false,
      loadingUserData: false,
      pullEshopData: false,
      initLocation: {},
      eshopCart: JSON.parse(localStorage.getItem('basket')) || [],
      landlordEvents: [],
      deviceFailedToAddReason: null,
      openSensorsDetected: [],
      openSensorsFoundByAutomation: false,
      event3DModel: null,
      lockWorking: false,
      smartLockKeys: [],
      activeSmartLockKeys: [],
      invokeGetMyProperties: false
    }
  },
  // MUTATIONS ===========================================================================================================================================
  mutations: {
    updatePropertyCameras (state, data) {
      state.propertyCameras = data
    },
    invokeGetMyProperties (state, value) {
      console.log('invoking get properties', value)
      state.invokeGetMyProperties = value
    },
    removeAdditionalProperty (state, propertyId) {
      const index = state.userData[0].myGeoFences.findIndex(item => item.propertyId === Number(propertyId))
      state.userData[0].myGeoFences.splice(index, 1)
    },
    updateLockFeatures (state, { ieee, autoLockEnabled, selectedAutoLockDelay }) {
      const index = state.propertyDevices.findIndex(i => i.ieee === ieee)
      if (index !== -1) {
        state.propertyDevices[index].autoLockEnabled = autoLockEnabled
        state.propertyDevices[index].selectedAutoLockDelay = selectedAutoLockDelay
      }
    },
    setCurrentPropertySelected (state, { propertyId }) {
      state.currentPropertySelected = propertyId
    },
    setAgentId (state, { agentId }) {
      state.agentId = agentId
      localStorage.setItem('agent_id', agentId)
    },
    setLockInProgress (state, { progress }) {
      state.lockWorking = progress
    },
    event3DModel (state, data) {
      state.event3DModel = data
    },
    platform (state, platform) {
      state.platform = platform
    },
    initLocation (state, { lat, lng }) {
      state.initLocation = { lat, lng }
    },
    geofenceAdded (state, myGeoFences) {
      console.log('from store')
      console.log(myGeoFences)
      state.userData[0].myGeoFences = myGeoFences
    },
    removeGeofence (state, myGeoFences) {
      state.userData[0].myGeoFences = myGeoFences
      console.log('from store')
      console.log(myGeoFences)
    },
    keyboardEvent (state, event) {
      state.keyboardStatus = event
    },
    setRole (state, data) {
      state.userRole = data
    },
    retrievedToken (state, {
      token,
      userId,
      propertyId,
      agentId,
      userName,
      userRole,
      landlordId,
      language,
      subscribeNewsletter,
      subscribeDealsOffers,
      mainLandlord,
      canBeAdminOfMainLandlord
    }) {
      state.token = token
      state.userId = userId
      state.propertyId = propertyId
      state.agentId = agentId
      state.userName = userName
      state.userRole = userRole
      state.landlordId = landlordId
      state.language = language
      state.subscribeNewsletter = subscribeNewsletter
      state.subscribeDealsOffers = subscribeDealsOffers
      state.mainLandlord = String(mainLandlord)
      state.canBeAdminOfMainLandlord = String(canBeAdminOfMainLandlord)
    },
    destroyToken (state) {
      localStorage.clear()
      state.currentPropertySelected = null
      state.token = null
      state.userId = null
      state.propertyId = null
      state.agentId = null
      state.userName = null
      state.userRole = null
      state.landlordId = null
      state.language = null
      state.subscribeNewsletter = null
      state.subscribeDealsOffers = null
      state.userData = []
      state.propertyUsers = []
      state.profileImage = ''
      state.propertyScenes = []
      state.userProperties = []
      state.propertyRooms = []
      state.propertyDevices = []
      state.propertyCameras = []
      state.landlordBuildings = []
      state.landlordProperties = []
      state.landlordDevices = []
      state.statusBucketLandlord = []
      state.deviceAddStatus = null
      state.propertyAutomation = []
      state.notification = []
      state.propertyAlarm = false
      state.weather = {}
      state.gatewayProgress = false
      state.data = []
      state.tcpData = null
      state.socketTcpId = null
      state.tcpStatus = true
      state.availableWifi = []
      state.gwSessionId = null
      state.communication = false
      state.loadingUserData = false
      state.mainLandlord = null
      state.openSensorsDetected = []
      state.openSensorsFoundByAutomation = false
      state.smartLockKeys = []
      state.activeSmartLockKeys = []
      localStorage.clear()
      $socket.close()
    },
    // Notifications
    SOCKET_NOTIFICATION (state, message) {
      state.notification.unshift(message)
    },
    resolveNotification (state, { id, resolvedBy }) {
      const index = state.landlordNotifications.findIndex(i => i.id === id)
      if (index !== -1) {
        state.landlordNotifications[index].resolved = true
        state.landlordNotifications[index].resolvedBy = resolvedBy
        state.landlordNotifications[index].resolvedAt = dayjs().toISOString()
      }
    },
    // SOCKET_LANDLORDNOTIFICATION (state, message) {
    //   state.landlordNotifications.unshift(message)
    // },
    removeNotificationById (state, id) {
      const notificationIndex = state.notification.findIndex(item => item.id === id)
      state.notification.splice(notificationIndex, 1)
    },
    removeLandlordNotificationById (state, id) {
      const notificationIndex = state.landlordNotifications.findIndex(item => item.id === id)
      state.landlordNotifications.splice(notificationIndex, 1)
    },
    removeAllNotifications (state) {
      if (state.userRole === 'Landlord') {
        state.landlordNotifications = []
      } else {
        state.notification = []
      }
    },
    notificationSetting (state, { setting, id }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.id === id)
      state.propertyDevices[deviceIndex].notification = setting
    },
    notificationCameraSetting (state, { setting, id }) {
      const cameraIndex = state.propertyCameras.findIndex(item => item.id === id)
      state.propertyCameras[cameraIndex].notification = setting
    },
    notificationSettingWhenScene (state, { value, deviceId }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.id === deviceId)
      state.propertyDevices[deviceIndex].notificationWhenScene = value
    },
    allNotificationsRead (state) {
      setTimeout(() => {
        state.notification.forEach(item => {
          item.read = true
        })
      }, 1000)
    },
    gradualLoadNotifications (state, data) {
      data.forEach(item => {
        state.notification.push(item)
      })
    },
    resetUserNotifications (state) {
      state.notification.splice(0, state.notification.length)
    },
    controlPushNotificationWhenAlarm (state, { setting }) {
      state.userData[0].pushNotificationWhenAlarm = setting
    },
    controlPushWhenSceneChange (state, { setting }) {
      state.userData[0].pushWhenSceneChange = setting
    },
    controlPushWhenAlarmChange (state, { setting }) {
      state.userData[0].pushWhenAlarmChange = setting
    },
    controlPushWhenGatewayDisconnects (state, { setting }) {
      state.userProperties[0].notificationWhenGatewayDisconnects = setting
    },
    // User Data
    startLoadingUserData (state) {
      state.loadingUserData = true
    },
    userData (state, { user, userProperties, propertyDevices, propertyNotifications, weather, profileImage, propertyUsers, openSensorsFoundByAutomation, propertyCameras }) {
      state.userData = user
      state.profileImage = profileImage
      state.userProperties = userProperties
      state.isMember = userProperties[0].isMember
      state.isIkeaMember = userProperties[0].ikeaIntegrationEnabled
      state.propertyDevices = propertyDevices
      state.notification = propertyNotifications
      state.weather = weather
      state.propertyUsers = propertyUsers
      state.openSensorsFoundByAutomation = openSensorsFoundByAutomation
      state.propertyCameras = propertyCameras
      if (state.userData.length) {
        state.loadingUserData = false
      }
    },
    profileImage (state, profileImage) {
      state.profileImage = profileImage
    },
    updateUserProfile (state, data) {
      state.userData[0].name = data.name
      state.userData[0].email = data.email
      state.userData[0].phone = data.phone
    },
    updateUserName (state, name) {
      state.userName = name
    },
    changeAppLanguage (state, value) {
      state.language = value
    },
    changeNewsletter (state, value) {
      state.subscribeNewsletter = value
    },
    changeDealsOffers (state, value) {
      state.subscribeDealsOffers = value
    },
    weatherUpdate (state, weather) {
      console.log(weather)
      if (state.currentPropertySelected === weather.propertyId) {
        state.weather = weather
      }
    },
    alarmStatus (state, data) {
      state.propertyAlarm = data.alarm
    },
    openSensorsDetected (state, data) {
      state.openSensorsDetected = data
    },
    propertyScenes (state, data) {
      state.propertyScenes = data
    },
    smartLockKeys (state, data) {
      state.smartLockKeys = data
    },
    activeSmartLockKeys (state, data) {
      state.activeSmartLockKeys = data
    },
    openSensorsFoundByAutomation (state, value) {
      state.openSensorsFoundByAutomation = value
    },
    editSceneName (state, { sceneName, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].sceneName = sceneName
    },
    editScenePicture (state, { scenePicture, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].scenePicture = scenePicture
    },
    updateSceneDeviceOnOff (state, data) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === data.sceneId)
      if (data.type === 'device') {
        const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.ieee === data.ieee)
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].switchgear = data.newValue
      } else if (data.type === 'linkage') {
        // ieee used for ID here
        // console.log(data.newValue)
        const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.id === data.ieee)
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].switchgear = data.newValue
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].linkages.forEach(link => {
          link.switchgear = data.newValue
        })
        // console.log(state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex])
        // console.log(state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex])
      } else if (data.type === 'enableLinkageInScene') {
        let desiredValue = Boolean
        let opposite = Boolean
        if (data.newValue === 'true' || data.newValue === true) {
          desiredValue = true
          opposite = false
        } else if (data.newValue === 'false' || data.newValue === false) {
          desiredValue = false
          opposite = true
        }

        const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.id === data.ieee)
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].activeInScene = desiredValue
        const ieees = [state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].ieee]
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].linkages.forEach(item => {
          // console.log(item.ieee)
          ieees.push(item.ieee)
        })

        ieees.forEach(ieee => {
          const device = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.ieee === ieee && !item.linkages.length)
          state.propertyScenes[sceneIndex].sceneSettings.devices[device].activeInScene = opposite
          // console.log(state.propertyScenes[sceneIndex].sceneSettings.devices[device].activeInScene)
        })
        // console.log(state.propertyScenes[sceneIndex].sceneSettings)
      } else if (data.type === 'disableLinkageInScene') {
        let desiredValue = Boolean
        let opposite = Boolean
        if (data.newValue === 'true' || data.newValue === true) {
          desiredValue = false
          opposite = true
        } else if (data.newValue === 'false' || data.newValue === false) {
          desiredValue = false
          opposite = true
        }

        const ieees = []
        const linkId = 'link' + data.ieee
        const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.id === linkId)
        // console.log(sceneDeviceIndex)
        if (sceneDeviceIndex !== -1) {
          state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].activeInScene = desiredValue
          ieees.push(state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].ieee)
          state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].linkages.forEach(item => {
            ieees.push(item.ieee)
          })
        } else {
          const containsInLinkage = state.propertyScenes[sceneIndex].sceneSettings.devices.filter(item => {
            let found = false
            item.linkages.forEach(link => {
              if (link.deviceId === data.ieee) {
                found = true
              }
            })
            return found
          })
          // console.log(containsInLinkage)
          // console.log(data.ieee)
          const sceneDeviceIndex2 = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.id === containsInLinkage[0].id)
          state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex2].activeInScene = desiredValue
          ieees.push(state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex2].ieee)
          state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex2].linkages.forEach(item => {
            ieees.push(item.ieee)
          })
        }

        ieees.forEach(ieee => {
          const device = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.ieee === ieee && !item.linkages.length)
          state.propertyScenes[sceneIndex].sceneSettings.devices[device].activeInScene = opposite
          // console.log(state.propertyScenes[sceneIndex].sceneSettings.devices[device].activeInScene)
        })
      } else if (data.type === 'saveBulbPreset') {
        const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.id === data.presetData.deviceId)
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].colorTemp = data.presetData.colorTemp
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].lightLevel = data.presetData.lightLevel
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].colorX = data.presetData.colorX
        state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].colorY = data.presetData.colorY
      }
    },
    addDeviceToScene (state, data) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === data.sceneId)
      state.propertyScenes[sceneIndex].sceneSettings.devices.push(data.device)
    },
    removeDeviceFromScene (state, data) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === data.sceneId)
      const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.ieee === data.ieee)
      state.propertyScenes[sceneIndex].sceneSettings.devices.splice(sceneDeviceIndex, 1)
    },
    turnOnOffSceneSecurity (state, { sceneSetting, sceneSecurityObject, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].alarm = sceneSetting
      state.propertyScenes[sceneIndex].sceneSettings.security = sceneSecurityObject
    },
    updateSecurityInScene (state, { sceneSecurityObject, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].sceneSettings.security = sceneSecurityObject
    },
    deleteScene (state, scene) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === scene.id)
      state.propertyScenes.splice(sceneIndex, 1)
    },
    // rooms ====================================
    propertyRooms (state, data) {
      state.propertyRooms = data
    },
    editRoom (state, data) {
      const roomIndex = state.propertyRooms.findIndex(item => item.id === data.roomId)
      state.propertyRooms[roomIndex].roomName = data.roomName
      state.propertyRooms[roomIndex].roomClass = data.roomClass
      state.propertyRooms[roomIndex].base64Image = data.base64Image
    },
    deleteRoom (state, id) {
      const roomIndex = state.propertyRooms.findIndex(item => item.id === id)
      state.propertyRooms.splice(roomIndex, 1)
    },
    // Automation --------------------------------------------------------------------------------------------------------------------------------
    // getPropertyAutomation (state, data) {
    //   state.propertyAutomation = data[0].automation
    // },
    // addPropertyAutomation (state, data) {
    //   state.propertyAutomation.push(data)
    // },
    // updateAutomationActiveInactive (state, data) {
    //   let active
    //   if (data.active === 'true') {
    //     active = true
    //   } else {
    //     active = false
    //   }
    //   const automationIndex = state.propertyAutomation.findIndex(item => item.automationName === data.automation.automationName)
    //   state.propertyAutomation[automationIndex].active = active
    // },
    // removePropertyAutomation (state, data) {
    //   const automationIndex = state.propertyAutomation.findIndex(item => item.automationName === data.automationName)
    //   state.propertyAutomation.splice(automationIndex, 1)
    //   console.log(state.propertyAutomation)
    // },
    // TCP UDP Comm ----------------------------------------------------------------------------------------------------------------------------------
    tcpData (state, data) {
      state.tcpData = data
    },
    socketTcpId (state, data) {
      state.socketTcpId = data
    },
    tcpStatus (state, data) {
      state.tcpStatus = data
    },
    clearTcpData (state, message) {
      state.tcpData = null
    },
    availableWifi (state, data) {
      state.availableWifi = data
    },
    gwSessionId (state, data) {
      state.gwSessionId = data
    },
    UDPResponse (state, data) {
      state.gatewayIp = data.remoteAddress
    },
    // Gateway and devices -------------------------------------------------------------------------------------------------------------------------
    userHasNoGateway (state, status) {
      state.userHasNoGateway = status
    },
    gatewayProgress (state, status) {
      state.gatewayProgress = status
    },
    gatewayAddDevice (state, data) {
      state.propertyDevices.push(data)
    },
    resetAddingDevice (state) {
      state.deviceAddStatus = null
    },
    updateLight (state, { type, value, ieee }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === ieee)
      if (type === 'lightLevel') {
        state.propertyDevices[deviceIndex].lightLevel = value
      }
      if (type === 'colorTemp') {
        state.propertyDevices[deviceIndex].colorTemp = value
      }
      if (type === 'switchgear') {
        state.propertyDevices[deviceIndex].switchgear = value
      }
    },
    updateSwitch (state, { type, value, ieee }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === ieee)
      if (type === 'switchgear') {
        state.propertyDevices[deviceIndex].switchgear = value
      }
    },
    updateLockStatus (state, { ieee, switchgear }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === ieee)
      state.propertyDevices[deviceIndex].switchgear = switchgear
    },
    devicesRefresh (state, devices) {
      state.propertyDevices = devices.data
    },
    updateDeviceSetting (state, deviceSetting) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === deviceSetting.ieee)
      state.propertyDevices[deviceIndex].deviceName = deviceSetting.deviceName
      state.propertyDevices[deviceIndex].propertyRoomId = deviceSetting.deviceRoom
    },
    removeDevice (state, deviceId) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.id === deviceId)
      state.propertyDevices.splice(deviceIndex, 1)
    },
    communication (state, value) {
      state.communication = value
    },
    updateParentDeviceLinkages (state, { type, deviceToLink, parentLinkDevice }) {
      const index = state.propertyDevices.findIndex(item => item.id === parentLinkDevice.id)
      if (type === 'add') {
        if (index !== -1) {
          if (state.propertyDevices[index].linkages === null) {
            state.propertyDevices[index].linkages = []
          }
          state.propertyDevices[index].linkages.push(deviceToLink)
        }
      } else if (type === 'remove') {
        const linkageIndex = state.propertyDevices[index].linkages.findIndex(item => item.ieee === deviceToLink.ieee)
        if (index !== -1) {
          state.propertyDevices[index].linkages.splice(linkageIndex, 1)
        }
      }
    },
    deviceFailedToAddReasonToFalse (state) {
      state.deviceFailedToAddReasonToFalse = null
    },
    // Landlords
    createBuilding (state, response) {
      state.landlordBuildings.push(response.data)
    },
    editBuilding (state, data) {
      const index = state.landlordBuildings.findIndex(item => item.id === data.buildingId)
      if (index !== -1) {
        state.landlordBuildings[index].buildingAddress = data.buildingAddress
        state.landlordBuildings[index].buildingCity = data.buildingCity
        state.landlordBuildings[index].buildingFlatsPerFloor = data.buildingFlatsPerFloor
        state.landlordBuildings[index].buildingFloors = data.buildingFloors
        state.landlordBuildings[index].buildingName = data.buildingName
        state.landlordBuildings[index].buildingPostCode = data.buildingPostCode
      }
    },
    getLandlordBuildingsAndProperties (state, response) {
      // console.log(response)
      const result = {
        tenantsInPortfolio: response.data.tenantsInPortfolio,
        landlordDevicesInactive: response.data.landlordDevicesInactive,
        landlordDevicesLength: response.data.landlordDevicesLength,
        landlordPropertiesGatewayInstalled: response.data.landlordPropertiesGatewayInstalled,
        landlordPropertiesGatewayInstalledButNotActive: response.data.landlordPropertiesGatewayInstalledButNotActive,
        landlordPropertiesLength: response.data.landlordPropertiesLength,
        landlordDevicesInactivePropertyIds: response.data.landlordDevicesInactivePropertyIds,
        landlordPropertiesGatewayInstalledButNotActiveWithDevices: response.data.landlordPropertiesGatewayInstalledButNotActiveWithDevices,
        landlordPropertiesGatewayInstalledWithDevices: response.data.landlordPropertiesGatewayInstalledWithDevices
      }

      const fireEvents = response.data.fireEvents
      const leakEvents = response.data.leakEvents
      const climateWarnings = response.data.climaWarnings

      const todayMinus7Days = Date.now() - (3600000 * 24 * 7)
      const todayMinus31days = Date.now() - (3600000 * 24 * 31)

      const last7DaysFireEvent = []
      const last31DaysFireEvent = []

      const last7daysLeakEvent = []
      const last31daysLeakEvent = []

      const last7DaysClimateWarnings = []
      const last31DaysClimateWarnings = []

      fireEvents.forEach(item => {
        if (item.startTime > todayMinus7Days) {
          last7DaysFireEvent.push(item)
        }
        if (item.startTime > todayMinus31days) {
          last31DaysFireEvent.push(item)
        }
      })

      leakEvents.forEach(item => {
        if (item.startTime > todayMinus7Days) {
          last7daysLeakEvent.push(item)
        }
        if (item.startTime > todayMinus31days) {
          last31daysLeakEvent.push(item)
        }
      })

      climateWarnings.forEach(item => {
        if (new Date(item.startTime).getTime() > todayMinus7Days) {
          last7DaysClimateWarnings.push(item)
        }
        if (new Date(item.startTime).getTime() > todayMinus31days) {
          last31DaysClimateWarnings.push(item)
        }
      })

      state.landlordEvents = {
        last7DaysFireEvent: last7DaysFireEvent,
        last31DaysFireEvent: last31DaysFireEvent,
        last7daysLeakEvent: last7daysLeakEvent,
        last31daysLeakEvent: last31daysLeakEvent,
        last7DaysClimateWarnings: last7DaysClimateWarnings,
        last31DaysClimateWarnings: last31DaysClimateWarnings
      }

      state.landlordOverviewPieData = result

      state.landlordBuildings = response.data.landlordBuildings
      state.landlordProperties = response.data.landlordProperties
      // console.log(response.data.landlordProperties)
      state.landlordDevices = response.data.landlordDevices
      state.userData = response.data.userData
      state.landlordName = response.data.userData[0].name
      state.companyName = response.data.userData[0].companyName
      localStorage.setItem('company_name', response.data.userData[0].companyName)
      // console.log(response.data.notifications)
      state.landlordNotifications = response.data.notifications
      // state.pullingLandlordsData = false
    },
    landlordCreateApartment (state, response) {
      state.landlordProperties.push(response.data)
    },
    landlordEditApartment (state, data) {
      const index = state.landlordProperties.findIndex(item => item.id === data.propertyId)
      if (index !== -1) {
        state.propertyFloor = data.propertyFloor
        state.propertyName = data.propertyName
        state.propertyType = data.propertyType
        state.flatNumber = data.flatNumber
      }
    },
    landlordApartmentProgress (state, id) {
      console.log(id)
    },
    gatewayStatus (state, { message, status }) {
      if (parseInt(state.currentPropertySelected) === parseInt(message.propertyId)) {
        state.userProperties[0].gatewayActive = status
      }
    },
    notificationDelaysLandlord (state, { value, type }) {
      if (type === 'waterLeakNotificationDelay') {
        state.userData[0].waterLeakNotificationDelay = value
      } else if (type === 'fireNotificationDelay') {
        state.userData[0].fireNotificationDelay = value
      }
    },
    settingVariables (state, { value, type }) {
      if (type === 'climateWarningBelow') {
        state.userData[0].climateWarningBelow = value
      } else if (type === 'climateWarningAbove') {
        state.userData[0].climateWarningAbove = value
      } else if (type === 'climateWarningDelayDays') {
        state.userData[0].climateWarningDelayDays = value
      }
    },
    getMoreLandlordsNotifications (state, data) {
      state.landlordNotifications.push(...data)
    },
    addItemToCart (state, { item, paymentStyle }) {
      const index = state.eshopCart.findIndex(i => i.id === item.id && i.paymentStyle === paymentStyle)
      if (index === -1) {
        const itemToAdd = {
          id: item.id,
          price: item.itemPrice,
          itemCategory: item.itemCategory,
          subPrice: item.subscriptionPriceMonth,
          subscriptionUpfrontPrice: item.subscriptionUpfrontPrice,
          picture: item.itemPicturesAWSLinks[0],
          name: item.itemName,
          sku: item.itemSKU,
          paymentStyle,
          qty: 1,
          productId: item.productId,
          productPriceId: item.productPriceId,
          productPriceSubId: item.productPriceSubId,
          productSubUpfrontPriceId: item.productSubUpfrontPriceId,
          memberSubscriptionPriceMonth: item.memberSubscriptionPriceMonth,
          memberSubscriptionPriceId: item.memberSubscriptionPriceId
        }
        state.eshopCart.push(itemToAdd)
      } else {
        state.eshopCart[index].qty++
      }
      localStorage.setItem('basket', JSON.stringify(state.eshopCart))
    },
    removeItemFromCart (state, { item, paymentStyle }) {
      const index = state.eshopCart.findIndex(i => i.id === item.id && i.paymentStyle === paymentStyle)
      if (index !== -1) {
        if (state.eshopCart[index].qty > 1) {
          state.eshopCart[index].qty--
        } else {
          state.eshopCart.splice(index, 1)
        }
      }
      localStorage.setItem('basket', JSON.stringify(state.eshopCart))
    },
    removeSpecificAmountFromBasket (state, { item, paymentStyle, availableQty }) {
      const index = state.eshopCart.findIndex(i => i.id === item.id && i.paymentStyle === paymentStyle)
      if (index !== -1) {
        if (availableQty === 0) {
          state.eshopCart.splice(index, 1)
        } else {
          state.eshopCart[index].qty = availableQty
        }
      }
      localStorage.setItem('basket', JSON.stringify(state.eshopCart))
    },
    clearBasket (state) {
      state.eshopCart = []
      localStorage.removeItem('basket')
    }
  },
  // GETTERS ===========================================================================================================================================
  getters: {
    loggedIn: state => state.token !== null
  },
  // ACTIONS ===========================================================================================================================================
  actions: {
    appActiveOrInactive ({ commit }, { type, state, route, user }) {
      // console.log({ type, state, route, user })
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/app-active-or-inactive/`, { type, state, route, user }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAPIVersion () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/version/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    newNotification () {
      const ding = new Audio(require('../assets/sounds/ding.mp3'))
      ding.muted = false
      ding.play()
    },
    // ESHOP
    pullEshopData ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/items`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getStripeIntents ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-intents`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updatePaymentMethodIntent ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-payment-method-intent/`, { propertyId: this.state.currentPropertySelected }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkPaymentStatus ({ commit }, { sessionId, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-payment`, { sessionId, type })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createOrder ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/create-order`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMyOrdersAndSubs ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/app-users/my-orders-and-subscriptions/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkDiscountCoupon ({ commit }, { val, cart }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-discount-coupon/${val}`, cart)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getShippingMethods ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-shipping-methods`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    cancelSubscription ({ commit }, subData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/cancel-subscription/`, subData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    requestEarlyOrderReturn ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/early-cancel-subscription/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCrossSaleData ({ commit }, { type }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-cross-sell-up-sell-data/${type}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getItemStockQTYs ({ commit }, { itemSKU }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-stock-qty-for-item/${itemSKU}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAllItemsStockQTY ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-stock-qty/`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAvailableBudbeeBoxes ({ commit }, { postCode }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-budbee-boxes/${postCode}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // =======
    geofenceAdded ({ commit }, { myGeoFences }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/geofence-new/${this.state.userId}`, { myGeoFences }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('geofenceAdded', myGeoFences)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    geofenceEvent ({ commit }, fences) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/geofence/events/${this.state.userId}`, fences, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeGeofence ({ commit }, { myGeoFences }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/geofence-new/${this.state.userId}`, { myGeoFences }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeGeofence', myGeoFences)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeGeofences ({ commit }, { userId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/remove-geofence-logout/${userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHelpCenterContent ({ commit }) {
      const locale = this.state.language
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      } else {
        localeActive = 'en'
      }
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_STRAPI_API}/help-centers?_locale=${localeActive}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDevicesHelpContent ({ commit }) {
      const locale = this.state.language
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      } else {
        localeActive = 'en'
      }
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_STRAPI_API}/device-helps?_locale=${localeActive}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHelpCenterContentNew ({ commit }, { type }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/help-center/get/${type}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    countViewOnHelpCenter ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/help-center/view/${id}`, { token: 'VieWCounTerOnHElPCenter20002' })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Properties -----------------------------------------------------------------------------------------------------------------------
    getMyProperties ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/properties/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editAdditionalProperty ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/properties/edit-additional-property/${data.id}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createAdditionalProperty ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/properties/create-additional-property/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyByPropertyCode ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/properties/add-property-by-property-code/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeAdditionalProperty ({ commit }, { propertyId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/properties/remove-additional-property/${propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
            commit('removeAdditionalProperty', propertyId)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    leaveAdditionalProperty ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/properties/remove-my-self-from-property/${id}`, { userId: this.state.userId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSelectedPropertyData ({ commit }, { selectProperty }) {
      console.log(selectProperty)
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/properties/get-selected-property-data/${this.state.userId}`, { selectedProperty: selectProperty }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / App user  -------------------------------------------------------------------------------------------------------------------------
    sendEmailVerification ({ commit }, { email, name }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/register/send-verification-email/`, { email, name })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    verifyMyEmail ({ commit }, { code }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/register/verify-email/${code}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    verifyPropertyCode ({ commit }, { propertyCode }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/register/verify-property-code/${propertyCode}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    SignUpNewUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/register`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeRegistrationGetUsersData ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/users/${token}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeRegistrationRegisterUser ({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/users/${token}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkGoodEmailWithPropertyCode ({ commit }, { email, propertyCode }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/register/check-good-email/${email}/${propertyCode}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeRegistrationWithPropertyCode ({ commit }, { data, propertyCode }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/register/by-property-code/${propertyCode}`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    inviteNewHouseMember ({ commit }, newUserData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/invite-house-member-new/${this.state.currentPropertySelected}`, newUserData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getInvitationToHouseData ({ commit }, { token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/accept-invitation/check/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    acceptHouseInvitation ({ commit }, { answer, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/accept-invitation/answer/${token}`, answer)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPendingInvitationsToMyHouse ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/app-users/get-pending-invitations-to-house/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeHouseInvitation ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/app-users/remove-house-invitation/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedHouseMember ({ commit }, newUserData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/edit-house-member-new/`, newUserData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHouseMembers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/app-users/get-house-members/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeHouseMember ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/app-users/delete-house-member-new/${id}/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeMyAccount ({ commit }, { id, role }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/delete-my-account/`, { id, role }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    LoginUser ({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/login`, credentials)
          .then((response) => {
            const token = response.data
            const decodedToken = jwtDecode(token)
            const userId = decodedToken.payload.user
            const landlordId = decodedToken.payload.landlordId
            const mainLandlord = decodedToken.payload.ml
            const canBeAdminOfMainLandlord = decodedToken.payload.cbadol
            const userName = decodedToken.payload.userName
            const userRole = decodedToken.payload.userRole
            const propertyId = decodedToken.payload.property
            const agentId = decodedToken.payload.agentId // Remove >>  No needed, it is being dynamically changed in getUserData depending on currentPropertySelected AgentId is token we use to identify gateway after is added
            const language = decodedToken.payload.language
            const subscribeNewsletter = decodedToken.payload.subscribeNewsletter
            const subscribeDealsOffers = decodedToken.payload.subscribeDealsOffers
            localStorage.setItem('access_token', token)
            localStorage.setItem('user', userId)
            localStorage.setItem('property_id', propertyId)
            localStorage.setItem('current_property', propertyId)
            localStorage.setItem('landlord_id', landlordId)
            localStorage.setItem('ml', mainLandlord)
            localStorage.setItem('cbadol', canBeAdminOfMainLandlord)
            localStorage.setItem('agent_id', agentId) // Remove >>  No needed, it is being dynamically changed in getUserData depending on currentPropertySelected AgentId is token we use to identify gateway after is added
            localStorage.setItem('user_name', userName)
            localStorage.setItem('user_role', userRole)
            localStorage.setItem('language', language)
            localStorage.setItem('subscribeNewsletter', subscribeNewsletter)
            localStorage.setItem('subscribeDealsOffers', subscribeDealsOffers)
            commit('retrievedToken', {
              token,
              userId,
              propertyId,
              agentId,
              userName,
              userRole,
              landlordId,
              language,
              subscribeNewsletter,
              subscribeDealsOffers,
              mainLandlord,
              canBeAdminOfMainLandlord
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    OAuthSingUpCheckCredentials ({ commit }, { name, email, idToken, type }) {
      // console.log('sending O-Auth')
      // console.log(idToken)
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/oauth/new-oauth/`, { name, email, idToken, type, appAuth: process.env.VUE_APP_OAUTH_AUTH })
          .then((response) => {
            // console.log('receivedResponse')
            // console.log(JSON.stringify(response))
            if (response.data.type === 'Success') {
              const token = response.data.token
              const decodedToken = jwtDecode(token)
              const userId = decodedToken.payload.user
              const landlordId = decodedToken.payload.landlordId
              const userName = decodedToken.payload.userName
              const userRole = decodedToken.payload.userRole
              const propertyId = decodedToken.payload.property
              const agentId = decodedToken.payload.agentId // Remove >>  No needed, it is being dynamically changed in getUserData depending on currentPropertySelected AgentId is token we use to identify gateway after is added
              const mainLandlord = decodedToken.payload.ml
              const canBeAdminOfMainLandlord = decodedToken.payload.cbadol
              const language = decodedToken.payload.language
              const subscribeNewsletter = decodedToken.payload.subscribeNewsletter
              const subscribeDealsOffers = decodedToken.payload.subscribeDealsOffers
              localStorage.setItem('access_token', token)
              localStorage.setItem('user', userId)
              localStorage.setItem('property_id', propertyId)
              localStorage.setItem('current_property', propertyId)
              localStorage.setItem('landlord_id', landlordId)
              localStorage.setItem('agent_id', agentId) // Remove >>  No needed, it is being dynamically changed in getUserData depending on currentPropertySelected AgentId is token we use to identify gateway after is added
              localStorage.setItem('ml', mainLandlord)
              localStorage.setItem('cbadol', canBeAdminOfMainLandlord)
              localStorage.setItem('user_name', userName)
              localStorage.setItem('user_role', userRole)
              localStorage.setItem('language', language)
              localStorage.setItem('subscribeNewsletter', subscribeNewsletter)
              localStorage.setItem('subscribeDealsOffers', subscribeDealsOffers)
              commit('retrievedToken', {
                token,
                userId,
                propertyId,
                agentId,
                userName,
                userRole,
                landlordId,
                language,
                subscribeNewsletter,
                subscribeDealsOffers,
                mainLandlord,
                canBeAdminOfMainLandlord
              })
            }
            resolve(response)
          })
          .catch((error) => {
            console.log('receivedError', JSON.stringify(error))
            reject(error)
          })
      })
    },
    newProfileImage ({ commit }, { profileImage }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-profile-image/${this.state.userId}`, { profileImage: profileImage }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('profileImage', profileImage)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    LogOut ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/users/logout/${data.userId}`, { pubAccessToken: process.env.VUE_APP_PUBLIC_ACCESS_TOKEN }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('destroyToken')
            resolve(response)
          })
          .catch((error) => {
            commit('destroyToken')
            reject(error)
          })
      })
      // this.removeNotificationToken()
    },
    updateUserProfile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-profile/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const userName = data.name
            localStorage.setItem('user_name', userName)
            commit('updateUserProfile', data)
            commit('updateUserName', userName)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateUserPassword ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-password/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateUserAddress ({ commit }, data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-user-address/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUserData ({ commit }) {
      console.log('gettingUserData')
      commit('startLoadingUserData')
      if (this.state.currentPropertySelected === null || this.state.currentPropertySelected === 'null') {
        console.log('getting property Id')
        localStorage.setItem('current_property', this.state.propertyId)
        commit('setCurrentPropertySelected', { propertyId: this.state.propertyId })
      }
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/user-data/${this.state.userId}/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // console.log(response)
            const user = response.data[0]
            // console.log(user)
            if (!user.length) {
              console.log('no data')
            } else {
              if (!user[0].active) {
                this.dispatch('LogOut', { userId: this.state.userId })
                  .then(() => {
                    router.replace('/login')
                  })
                  .catch(() => {
                    router.replace('/login')
                  })
              } else {
                const propertyLevelPermissions = user[0].propertyLevelPermissions.find(item => Number(item.propertyId) === Number(this.state.currentPropertySelected))
                if (propertyLevelPermissions) {
                  localStorage.setItem('user_role', propertyLevelPermissions.role)
                  commit('setRole', propertyLevelPermissions.role)
                }
                const profileImage = user[0].profileImage
                const userProperties = response.data[1]
                const propertyScenes = response.data[2]
                const agentId = userProperties[0].agentId
                commit('setAgentId', { agentId })
                const propertyRooms = response.data[3]
                const propertyDevices = response.data[4]
                const propertyNotifications = response.data[5]
                const weather = response.data[6]
                const propertyUsers = response.data[7]
                const openSensorsFoundByAutomation = user[0].openSensorsFoundByAutomation
                const propertyCameras = response.data[8]
                commit('userData', { user, userProperties, propertyDevices, propertyNotifications, weather, profileImage, propertyUsers, openSensorsFoundByAutomation, propertyCameras })
                commit('propertyScenes', propertyScenes)
                commit('propertyRooms', propertyRooms)
                // console.log(this.state.agentId)
                resolve(response)
              }
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetOpenSensorsFoundByAutomation ({ commit }, openSensorsFoundByAutomation) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/user-data/reset-open-sensors/${this.state.currentPropertySelected}`, { openSensorsFoundByAutomation: openSensorsFoundByAutomation, userId: this.state.userId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log('openSensorsFoundByAutomation: ' + response.data)
            commit('openSensorsFoundByAutomation', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changeAppLanguage ({ commit }, { language }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/change-language/${this.state.userId}`, { language }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('changeAppLanguage', language)
            localStorage.setItem('language', language)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changeNewsletter ({ commit }, { subscribeNewsletter }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/change-newsletter-subscription/${this.state.userId}`, { subscribeNewsletter }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('changeNewsletter', subscribeNewsletter)
            localStorage.setItem('subscribeNewsletter', subscribeNewsletter)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changeDealsOffers ({ commit }, { subscribeDealsOffers }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/change-dealsoffers-subscription/${this.state.userId}`, { subscribeDealsOffers }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('changeDealsOffers', subscribeDealsOffers)
            localStorage.setItem('subscribeDealsOffers', subscribeDealsOffers)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetMyPassword ({ commit }, { email }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/forgot-password/reset`, { email })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeResetPasswordGetData ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/forgot-password/check/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeResetPassword ({ commit }, { token, password }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/forgot-password/new/${token}`, { password })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submitFeedback ({ commit }, { feedback }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/feedback/${this.state.userId}`, { feedback }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    restartGateway ({ state, commit }) {
      // console.log(state.token)
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/user-data/restart-gateway/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // Alarm --------
    getAlarmStatus ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-security/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('alarmStatus', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setAlarmStatus ({ commit }, alarmSetting) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-security/${this.state.currentPropertySelected}`, { alarmSetting: alarmSetting, userId: this.state.userId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('alarmStatus', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getOpenSensors ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-security/get-open-sensors/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log(response.data.devNames)
            commit('openSensorsDetected', response.data.devNames)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // TCP UDP Comm (to be refactored )  -------------------------------------------------------------------------------------------------------------------------
    socketTcpId ({ commit }, data) {
      commit('socketTcpId', data)
    },
    tcpData ({ commit }, data) {
      commit('tcpData', data)
    },
    tcpStatus ({ commit }, message) {
      commit('tcpStatus', message.status)
    },
    clearTcpData ({ commit }, message) {
      commit('clearTcpData', message)
    },
    availableWifi ({ commit }, data) {
      commit('availableWifi', data)
    },
    gwSessionId ({ commit }, data) {
      commit('gwSessionId', data)
    },
    /// / Property socket IO----------------------------------------------------------------------- -----------------------------------------------------------
    // if socket.io crashes here we do the handling (timeout should be changed to several attempts to connect)
    socket_disconnect ({ state, getters, commit }) {
      $socket.disconnect()
      if (state.token !== null) {
        if (getters.loggedIn) {
          console.log('reconnecting')
          $socket.query = { token: state.token }
          $socket.io.opts.query = { token: state.token }
          setTimeout(() => {
            $socket.open()
          }, 1000)
        } else {
          console.log('loggedout')
        }
      } else {
        console.log('logged out - not reconnecting')
      }
    },
    // unused but we have option to send messages over the socket.io too
    sendMessageIo ({ commit }, data) {
      $socket.emit('message', data)
    },
    // initial connection to socket.io server when app loaded
    connectSocketIo ({ state, commit }) {
      if (state.token !== null) {
        if (!$socket.connected) {
          console.log('Socket not connected')
          setTimeout(() => {
            $socket.query = { token: state.token }
            $socket.io.opts.query = { token: state.token }
            $socket.open()
          }, 500)
          setTimeout(() => {
            console.log('Socket connected', $socket.connected)
          }, 1500)
        } else {
          console.log('already connected')
        }
      } else {
        console.log('logged out')
      }
    },
    socket_landlordnotification ({ state, dispatch }, message) {
      state.landlordNotifications.unshift(message)
      state.latestLandlordsNotification = message
      dispatch('newNotification')
      state.showLandlordsNotification = true
      setTimeout(() => { state.showLandlordsNotification = false }, 100)
    },
    // gateway data updates 'DEVICES' coming via socket.io
    socket_dataUpdate ({ dispatch, commit, state }, message) {
      // console.log(message)
      if (message.type === 'gwAuthOk') {
        commit('gatewayProgress', false)
      } else if (message.type === 'pullPropertyDevices') {
        dispatch('getPropertyDevices')
        commit('gatewayProgress', false)
      } else if (message.type === 'pullPropertyScenes') {
        dispatch('getPropertyScenes')
      } else if (message.type === 'deviceUpdate') {
        const devices = state.propertyDevices
        for (let i = 0; i < devices.length; i++) {
          if (devices[i].ieee === null && devices[i].zigbeeModel === message.zigbeeModel) {
            state.propertyDevices[i].ieee = message.ieee
            this.state.deviceAddStatus = 'success'
          }
        }

        const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex === -1) {
          dispatch('getPropertyDevices')
          commit('gatewayProgress', false)
        } else {
          state.propertyDevices[deviceIndex].active = message.active
          state.propertyDevices[deviceIndex].ep = message.ep
          commit('gatewayProgress', false)
        }
      } else if (message.type === 'statusUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].status = message.status
        } else {
          dispatch('getPropertyDevices')
        }
      } else if (message.type === 'humiUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].humi = message.humi
        }
      } else if (message.type === 'tempUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].temp = message.temp
        }
      } else if (message.type === 'colorTemp') {
        // const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        // if (deviceIndex !== -1) {
        //   this.state.propertyDevices[deviceIndex].colorTemp = message.colorTemp
        // }
      } else if (message.type === 'switchgear') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].switchgear = message.switchgear
        }
      } else if (message.type === 'lightLevel') {
        // const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        // if (deviceIndex !== -1) {
        //   this.state.propertyDevices[deviceIndex].lightLevel = message.lightLevel
        // }
      } else if (message.type === 'weatherUpdate') {
        commit('weatherUpdate', message)
      } else if (message.type === 'deviceAddError') {
        this.state.deviceAddStatus = 'error'
      } else if (message.type === 'deviceAddSuccess') {
        this.state.deviceAddStatus = 'success'
      } else if (message.type === 'landlordDeviceAddError') {
        this.state.statusBucketLandlord.push(message)
      } else if (message.type === 'landlordDeviceAddSuccess') {
        this.state.statusBucketLandlord.push(message)
      } else if (message.type === 'landlordFireDetected') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].fire = true
        this.state.landlordProperties[propertyIndex].fireDetectedAt = message.fireDetectedAt
      } else if (message.type === 'landlordFireOk') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].fire = false
      } else if (message.type === 'landlordWaterLeakDetected') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].waterLeak = true
        this.state.landlordProperties[propertyIndex].leakDetectedAt = message.leakDetectedAt
      } else if (message.type === 'landlordWaterLeakOk') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].waterLeak = false
      } else if (message.type === 'gwConnected') {
        commit('gatewayStatus', { message, status: true })
      } else if (message.type === 'gwDisconnected') {
        commit('gatewayStatus', { message, status: false })
      } else if (message.type === 'energyUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].currentConsumption = message.currentConsumption
          this.state.propertyDevices[deviceIndex].totalConsumption = message.totalConsumption
        }
      } else if (message.type === 'colorUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].colorY = message.colorY
          this.state.propertyDevices[deviceIndex].colorX = message.colorX
        }
      } else if (message.type === 'pullEshopData') {
        state.pullEshopData = true
        setTimeout(() => {
          state.pullEshopData = false
        }, 1000)
      } else if (message.type === 'deviceBattery') {
        const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex === -1) {
          dispatch('getPropertyDevices')
        } else {
          state.propertyDevices[deviceIndex].battery = message.status
        }
      } else if (message.type === 'deviceNotFound-removing') {
        this.state.deviceFailedToAddReason = true
      } else if (message.type === 'ikeaIntegrationEnabled') {
        this.state.isIkeaMember = true
      }
    },
    // Notifications  ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    notificationToken ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/${this.state.currentPropertySelected}`, { notificationToken: data, appUserId: this.state.userId, role: this.state.userRole }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeNotification ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/notifications/remove-one-new/${this.state.userId}/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeNotificationById', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeLandlordNotification ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/notifications/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeLandlordNotificationById', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationSetting ({ commit }, { setting, id }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting/${id}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationSetting', { setting, id })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationCameraSetting ({ commit }, { setting, id }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/camera-setting/${id}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationCameraSetting', { setting, id })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationWhenScene ({ commit }, { value, deviceId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting-when-scene/${deviceId}`, { value }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationSettingWhenScene', { value, deviceId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeAllNotification ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/notifications/remove-all-new/${this.state.userId}/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeAllNotifications')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    allNotificationsRead ({ commit }) {
      commit('allNotificationsRead')
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/notifications/${this.state.userId}/${this.state.currentPropertySelected}`, { dummy: 'dummy' }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    gradualLoadNotifications ({ commit }, { skip, propertyId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/notifications/gradual-load/${this.state.userId}/${propertyId}/${skip}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
            commit('gradualLoadNotifications', response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetUserNotifications ({ commit }) {
      commit('resetUserNotifications')
    },
    controlPushNotificationWhenAlarm ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/user-setting-push-when-alarm/${this.state.userId}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushNotificationWhenAlarm', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    controlPushWhenSceneChange ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/user-setting-when-scene-change/${this.state.userId}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushWhenSceneChange', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    controlPushWhenAlarmChange ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/user-setting-when-alarm-change/${this.state.userId}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushWhenAlarmChange', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    controlPushWhenGatewayDisconnects ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/user-setting-when-gateway-disconnects/${this.state.currentPropertySelected}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushWhenGatewayDisconnects', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationDelaysLandlord ({ commit }, { value, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/notification-delays/${this.state.userId}`, { value, type }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationDelaysLandlord', { value, type })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resolveNotification ({ commit }, { id, resolvedBy }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/resolve-notification/${id}`, { resolvedBy }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('resolveNotification', { id, resolvedBy })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Property gateway and devices ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    findGatewayByMacNonLandlord ({ commit }, { mac }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/gateway/find-gateway-by-mac/${mac}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addX5NewWayNonLandlord ({ commit }, { propertyId, gateway, buildingId, agentId, mac }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/gateway/add-x5-gateway-new-setup/${propertyId}`, { gateway, buildingId, mac, agentId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addGateway ({ commit }, data) {
      console.log('adding gateway to ::::::')
      console.log(this.state.currentPropertySelected)
      return new Promise((resolve, reject) => {
        const gateway = { gateway: data }
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/gateway/${this.state.currentPropertySelected}`, gateway, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('gatewayProgress', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    wasGatewayAdded ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/gateway/was-added/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyDevice ({ commit }, device) {
      // console.log('adddonf')
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/add-device/${this.state.currentPropertySelected}`, device, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log(response)
            commit('gatewayAddDevice', response.data)
            commit('gatewayProgress', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetAddingDeviceStatus ({ commit }) {
      commit('resetAddingDevice')
    },
    getPropertyDevices ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('devicesRefresh', response)
            commit('gatewayProgress', false)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    lightControl ({ commit }, { type, value, ieee, ep }) {
      if (this.state.communication === true) {
        return
      }
      commit('communication', true)
      const data = {
        type,
        value,
        ieee,
        ep
      }
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/light-control/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateLight', { type, value, ieee })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    switchControl ({ commit }, { type, value, ieee, ep }) {
      const data = {
        type,
        value,
        ieee,
        ep
      }
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/switch-control/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateSwitch', { type, value, ieee })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    curtainControl ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/curtain-control/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateDeviceSetting ({ commit }, deviceSetting) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/device-setting/${this.state.currentPropertySelected}`, deviceSetting, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateDeviceSetting', deviceSetting)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDeviceHistory ({ commit }, { ieee, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/device-history/${ieee}`, { type }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeDevice ({ commit }, { ieee, ep, deviceId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/remove-device/${this.state.currentPropertySelected}`, { ieee: ieee, ep: ep, deviceId: deviceId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeDevice', deviceId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    identifyDevice ({ commit }, { deviceId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/identify-device/${deviceId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetSiren ({ commit }, device) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/reset-siren/`, { device }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    testSiren ({ commit }, { testId, device }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/test-siren/`, { testId, device }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deviceLinkages ({ commit }, { type, deviceToLink, parentLinkDevice }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/device-linkages/`, { type, deviceToLink, parentLinkDevice }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateParentDeviceLinkages', { type, deviceToLink, parentLinkDevice })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    gwAddFailed ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/gateway-add-failed/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAcobaSession ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/get-camera-session/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createAcobaUser ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/create-acoba-user/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPropertyCameras ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/get-cameras/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updatePropertyCameras', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addAcobaCamera ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/add-new-camera/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editCamera ({ commit }, object) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/edit-camera/${object.cameraId}`, object, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAvailableAcobaSubscriptions ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/get-acoba-subscription-types`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteCamera ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/remove-camera/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    alarmCamera ({ commit }, { cameraId, alarmed, cameraIdentification }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/alarm-camera/${cameraId}`, { alarmed, cameraIdentification }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAcobaLiveInfo ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-cameras/get-acoba-live-info`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveLockFeatures ({ commit }, { ieee, autoLockEnabled, selectedAutoLockDelay }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/save-lock-features`, { ieee, autoLockEnabled, selectedAutoLockDelay }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateLockFeatures', { ieee, autoLockEnabled, selectedAutoLockDelay })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setLockStatus ({ commit }, { ieee, ep, switchgear }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/control-smart-lock/${this.state.currentPropertySelected}`, { ieee, ep, switchgear }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateLockStatus', { ieee, switchgear })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    smartLockChangeNotification ({ commit }, { ieee, notification, id }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/smart-lock-change-notification/${this.state.currentPropertySelected}/${ieee}`, { notification, id }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    listSmartLockKeys ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/list-smart-lock-keys/${data.propertyId}/${data.ieee}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('smartLockKeys', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    listActiveSmartLockKeys ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/list-active-smart-lock-keys/${data.propertyId}/${data.ieee}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('activeSmartLockKeys', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteSmartLockKey ({ commit }, newkey) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/delete-smart-lock-key/${this.state.currentPropertySelected}`, newkey, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createSmartLockKey ({ commit }, newkey) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/create-smart-lock-key/${this.state.currentPropertySelected}`, newkey, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateSmartLockKey ({ commit }, newkey) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/update-smart-lock-key/${this.state.currentPropertySelected}`, newkey, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSmartLockKeyDetails ({ commit }, keyId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/get-smart-lock-key-details/${keyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSmartLockKeyLogs ({ commit }, { ieee, logCount }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/get-smart-lock-key-logs/${ieee}/${logCount}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPublicSmartLockDetails ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/property-devices/get-smart-lock-details/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSmartLockKeys ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/get-smart-lock-keys/${token}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    unlockSmartLock ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/property-devices/unlock-smart-lock/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    lockSmartLock ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/property-devices/lock-smart-lock/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Property scenes ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    setPropertyScene ({ commit }, { sceneId, sceneName }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/${this.state.currentPropertySelected}`, { sceneId: sceneId, userId: this.state.userId, sceneName }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const newPropertyScenes = response.data
            commit('propertyScenes', newPropertyScenes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPropertyScenes ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const propertyScenes = response.data
            commit('propertyScenes', propertyScenes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyScene ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const newPropertyScenes = response.data
            commit('propertyScenes', newPropertyScenes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editSceneName ({ commit }, { sceneName, sceneId }) {
      // console.log(sceneName)
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/change-scene-name/${sceneId}`, { newSceneName: sceneName }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editSceneName', { sceneName, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editScenePicture ({ commit }, { scenePicture, sceneId }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/change-scene-picture/${sceneId}`, { newScenePicture: scenePicture }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editScenePicture', { scenePicture, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateDevicesOnOffValueScenes ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/update-device-on-off/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateSceneDeviceOnOff', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addDeviceToScene ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/add-device-to-scene/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('addDeviceToScene', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeDeviceFromScene ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/remove-device-from-scene/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeDeviceFromScene', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    turnOnOffSceneSecurity ({ commit }, { sceneSetting, sceneSecurityObject, sceneId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/scene-security/${sceneId}`, { securitySetting: sceneSetting, sceneSecurityObject }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('turnOnOffSceneSecurity', { sceneSetting, sceneSecurityObject, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateSecurityInScene ({ commit }, { sceneSecurityObject, sceneId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/update-security/${sceneId}`, { sceneSecurityObject }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateSecurityInScene', { sceneSecurityObject, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteScene ({ commit }, scene) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/delete-scene/${this.state.currentPropertySelected}`, scene, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('deleteScene', scene)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Automation ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    getPropertyAutomation ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-automation/${this.state.currentPropertySelected}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // commit('getPropertyAutomation', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyAutomation ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-automation/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // commit('addPropertyAutomation', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateAutomationActiveInactive ({ commit }, { active, automationId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-automation/update-active-inactive/${automationId}`, { active }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editAutomation ({ commit }, data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-automation/${data.automationId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removePropertyAutomation ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/property-automation/remove-automation/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Property rooms ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    addPropertyRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const newPropertyRooms = response.data
            commit('propertyRooms', newPropertyRooms)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/${this.state.currentPropertySelected}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editRoom', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteRoom ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('deleteRoom', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // get user properties' rooms
    getUserPropertiesRooms ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/get-properties-rooms/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // const newPropertyRooms = response.data
            // commit('propertyRooms', newPropertyRooms)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// Landlords ================================================================================================================================================================
    getWorstPerformingLandlord ({ commit }, { range }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-worst-performing-apartments/${this.state.landlordId}`, { range }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getNotesForAllApartments ({ commit }, { apartmentIds }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/count-apartment-notes/`, { ids: apartmentIds }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApartmentNotes ({ commit }, { propertyId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-apartment-notes/${propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveApartmentNote ({ commit }, { note, propertyId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/save-apartment-note/${propertyId}`, { note, whoAddNote: this.state.userName }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteNote ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/properties/delete-apartment-note/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlordsData ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/landlords/${token}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    registerLandlord ({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/landlords/${token}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPropertyManagers ({ commit }, { landlordId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/get-managers/${landlordId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateManagers ({ commit }, { managers }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/update-managers`, managers, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteManager ({ commit }, { managerId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/delete-manager/${managerId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    invitePropertyManager ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/invite-manager`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMyLandlordDetails () {
      return new Promise((resolve, reject) => {
        console.log(this.state.userId)
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/get-my-details/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateMyLandlordsDetails ({ commit }, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        console.log(this.state.userId)
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/update-my-details/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changeLandlordsPassword ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/update-password/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHistoryAlarmsForLandlord ({ commit }, { range }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/get-history-alarms/${this.state.landlordId}`, { range }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHistoryAlarmsForLandlordWithPagination ({ commit }, { range, skip, limit, type, buildingId, propertyId, primary }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/get-history-alarms-with-pagination/${this.state.landlordId}`, { range, skip, limit, type, buildingId, propertyId, primary }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    assignResponsibleManagerToBuildingAndApartments ({ commit }, { data, buildingId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/assign-responsible-manager/${buildingId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createBuilding ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/${this.state.landlordId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('createBuilding', response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordSaveEditedBuilding ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editBuilding', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordSaveBuildingSpecificSettings ({ commit }, { buildingId, targetTemperature }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/building-specific-settings/`, { buildingId, targetTemperature }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordGetBuildingInvitedTenants ({ commit }, { buildingId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/building-invited-tenants/${buildingId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordInviteTenant ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/send-tenant-invite`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlordBuildingsAndProperties ({ commit, state }) {
      // state.pullingLandlordsData = true
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/${this.state.landlordId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getLandlordBuildingsAndProperties', response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
            // state.pullingLandlordsData = false
          })
      })
    },
    landlordCreateApartment ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('landlordCreateApartment', response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createApartmentsFromFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/create-apartments-from-file`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeApartment ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/properties/remove-apartment/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordSaveEditedProperty ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/properties/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('landlordEditApartment', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApartmentDetail ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getTenantsForApartment ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/tenants/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    inviteTenantToApartment ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/tenants/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkExistingEmailsBeforeImport ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/before-import-emails-exist/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDevicesSharedToTenant ({ commit }, { tenantId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-shared-tenant-devices/${tenantId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedTenant ({ commit }, { tenantId, tenantEmail, tenantName, sharedDevices }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/properties/save-edited-tenant/${tenantId}`, { tenantEmail, tenantName, sharedDevices }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeAllTenantsFromProperty ({ commit }, { apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/properties/remove-tenants/${apartmentId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addLandlordGateway ({ commit }, { newGateway, apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/gateway/${apartmentId}`, { gateway: newGateway }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    findGatewayByMac ({ commit }, { mac }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/find-gateway-by-mac/${mac}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addX5NewWay ({ commit }, { propertyId, gateway, buildingId, agentId, mac }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/add-x5-gateway-new-setup/${propertyId}`, { gateway, buildingId, mac, agentId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordRestartGatewayInApartment ({ commit }, apartment) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/gateway/reset/${apartment.id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordAddPropertyDevice ({ commit }, { device, apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/devices/${apartmentId}`, device, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('landlordApartmentProgress', apartmentId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordRemoveDevice ({ commit }, { ieee, ep, deviceId, propertyId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/remove-device/${propertyId}`, { ieee: ieee, ep: ep, deviceId: deviceId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeDevice', deviceId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getChartDataForProperty ({ commit }, { id, dateRange, selectedFlat, selectedFloor, buildingCity }) {
      // console.log(id, dateRange)
      // console.log(typeof selectedFlat)
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-data-for-charts/${id}`, { dateRange, selectedFlat, selectedFloor, buildingCity }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDisconnectionDetail ({ commit }, { id, dateFrom }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-disconnection-detail-for-device/${id}`, { dateFrom }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDisconnectionDetailForDevice ({ commit }, { ieee }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-disconnection-detail-for-device/${ieee}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    settingVariables ({ commit }, { value, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/setting-variables/${this.state.landlordId}`, { value, type }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('settingVariables', { value, type })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMoreLandlordsNotifications ({ commit }, { dateFrom }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/get-more-landlord-notifications/${this.state.landlordId}`, { dateFrom }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getMoreLandlordsNotifications', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveNotesForApartment ({ commit }, { notes, apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/properties/save-notes-for-apartments/${apartmentId}`, { notes }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeAllApartmentsInBuilding ({ commit }, buildingId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/properties/remove-all-properties-in-building/${buildingId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeBuilding ({ commit }, buildingId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/properties/remove-building/${buildingId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlordsStrategies ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/get-strategies/${this.state.landlordId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            console.log(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedLandlordStrategy ({ commit }, { strategy }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/save-edited-strategy/${this.state.landlordId}`, { strategy }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeLandlordsStrategy ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/delete-strategy/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createNewLandlordStrategy ({ commit }, { strategy }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/create-strategy/${this.state.landlordId}`, { strategy }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApartmentDetailsFor3D ({ commit }, { id, dateRange }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-data-for-3d-apartment/${id}`, { dateRange }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApartmentWaterleakFireReason ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-apartment-waterleak-fire-reason/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAddressGeocode ({ commit }, searchterm) {
      console.log('asking mapbox')
      return new Promise((resolve, reject) => {
        axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchterm}.json?country=se&limit=10&types=place,postcode,address,poi,locality,district,neighborhood&language=sv&access_token=pk.eyJ1IjoiaG9tZXZpc2lvbiIsImEiOiJjbDJnNHNhY2UwMGk0M2x4Nm43MjJyN3AxIn0.Ojno14ZmUlPJZ4R2MUbqVg`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAppVersion ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/version/get-app-version/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkEmailExists ({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/register/check-email-exists/${email}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getActionRequiredWaterleakFire ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/notifications/check-action-required-waterleak-fire/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlordPhone ({ commit }, propertyId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/properties/get-landlord-phone/${propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submitWaterleakFireReason ({ commit }, { id, realAlarm }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/submit-waterleak-fire-reason/${id}/${this.state.userId}`, { realAlarm }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    errorReporter ({ commit }, { type, errorToReport, whereFrom }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-error-reporter/new-error`, { type, errorToReport, whereFrom, userId: this.state.userId, propertyId: this.state.currentPropertySelected, platform: this.state.platform }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSavingsForBuilding ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('https://hcu.homevision.se/api/hcu-ai/dashboard-connector/calculate-savings-for-building', data, {
        // axios.post('http://localhost:4099/api/hcu-ai/dashboard-connector/calculate-savings-for-building', data, {
          headers: {
            Authorization: 'Bearer 1'
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSavingsHelperData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-savings-helper-data`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  modules: {}
})