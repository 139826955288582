<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border edit-profile-modal">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        @click="dismissModal()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end">
    <div v-if="!loading">
      <div
        class="form--dark"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <div class="text--white mb-5">
          {{ $t('Edit property') }}
        </div>
        <ion-text class="text--gray">
          {{ $t('Please fill your address') }}
        </ion-text>

        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Property Name') }}
          </ion-label>
          <ion-input
            id="propertyName"
            v-model="propertyName"
            class="capitalize"
            type="text"
            enterkeyhint="done"
            required
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
        <ion-row
          class="ion-no-padding"
          style="margin-top: -15px;"
        >
          <ion-col
            class="ion-no-padding"
            style="margin-right: 5px;"
          >
            <ion-list
              class="result-section"
              :class="[locationslistshow ? '' : 'hidden']"
            >
              <ion-card
                v-for="item in locationsItems"
                :key="item.id"
                class="suggestions-card"
                @click="locationItemClicked(item)"
              >
                {{ item.place_name }}
              </ion-card>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Address') }}
          </ion-label>
          <ion-input
            id="street"
            v-model="street"
            class="capitalize"
            type="text"
            enterkeyhint="done"
            required
            @keydown.enter="closeKeyboard"
            @ionInput="getItems($event)"
            @ionBlur="closeList()"
          />
        </ion-item>

        <div class="d-flex">
          <ion-item
            lines="none"
            style="margin: 0 15px 0 0;"
          >
            <ion-label position="floating">
              {{ $t('City') }}
            </ion-label>
            <ion-input
              id="city"
              v-model="city"
              class="capitalize"
              type="text"
              required
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-item
            lines="none"
            style="margin: 0;"
          >
            <ion-label position="floating">
              {{ $t('Post Code') }}
            </ion-label>
            <ion-input
              id="post-code"
              v-model="postCode"
              type="text"
              required
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
        </div>
        <ion-item
          lines="none"
        >
          <ion-label position="floating">
            {{ $t('Country') }}
          </ion-label>
          <ion-input
            id="country"
            v-model="country"
            class="capitalize"
            type="text"
            required
            enterkeyhint="done"
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
        <ion-button
          fill="clear"
          class="next-button mt-15"
          expand="block"
          :disabled="!street || !country || !city || !postCode || !propertyName"
          @click="saveEditedApartment"
        >
          {{ $t('Save') }}
        </ion-button>
      </div>
    </div>
    <div v-else>
      <ion-spinner
        v-if="loading"
        color="light"
        name="crescent"
      />
    </div>
  </ion-content>
</template>

<script>
import { alertController } from '@ionic/vue'
import { closeOutline, addOutline, checkmarkDoneOutline, personCircleOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Keyboard } from '@capacitor/keyboard'

import _ from 'lodash'

export default defineComponent({
  name: 'Members',
  props: {
    close: { type: Function, default: null },
    propertyToEdit: { type: Object, default: () => {} }
  },
  data () {
    return {
      lodash: _,
      loading: false,
      closeOutline,
      checkmarkDoneOutline,
      personCircleOutline,
      addOutline,
      locationsItems: [],
      locationslistshow: false,
      propertyId: null,
      propertyName: '',
      street: '',
      city: '',
      postCode: '',
      country: ''
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    // this.getMyProperties()
    this.propertyName = this.propertyToEdit.propertyName
    this.street = this.propertyToEdit.street
    this.city = this.propertyToEdit.city
    this.postCode = this.propertyToEdit.postCode
    this.country = this.propertyToEdit.country
    this.propertyId = this.propertyToEdit.id
  },
  methods: {
    dismissModal () {
      this.close()
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    getItems (ev) {
      const val = ev.target.value
      this.$store.dispatch('getAddressGeocode', val).then(response => {
        if (response) {
          this.locationsItems = response.data.features
          this.locationslistshow = true
        } else {
          this.locationslistshow = false
          console.log('err')
        }
      })
    },
    locationItemClicked (item) {
      item.context.forEach(location => {
        if (location.id.indexOf('postcode') > -1) {
          this.postCode = location.text
        } else if (location.id.indexOf('place') > -1) {
          this.city = location.text
        } else if (location.id.indexOf('country') > -1) {
          this.country = location.text
        }
      })
      // remove country
      let address = item.place_name.replace(/,[^,]+$/, '')
      // remove city
      address = address.replace(/,[^,]+$/, '')
      this.street = address
      this.locationslistshow = false
    },
    closeList () {
      setTimeout(() => { this.locationslistshow = false }, 500)
    },
    saveEditedApartment () {
      this.$store.dispatch('editAdditionalProperty', {
        id: this.propertyId,
        propertyName: this.propertyName,
        street: this.street,
        city: this.city,
        postCode: this.postCode,
        country: this.country
      })
        .then(() => {
          this.dismissModal()
        })
        .catch(err => {
          console.log(err)
          this.errorAlert(`MH001 ${this.$t('somethingHasFailed')}`)
        })
    },
    async errorAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: [this.$t('Dismiss')]
        })
      await alert.present()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_backgrounds';
.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
img {
  max-width: 40px;
}
.text--right {
  text-align: end;
}
</style>
