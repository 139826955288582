
import pkg from '../../package.json'

const getAppVersion = () => {
  return `${pkg.version}-${process.env.VUE_APP_ENVIRONMENT}`
}

const availableZigbeeDevices = () => {
  return [
    { zigbeeModel: 'SensorDoorWindow', name: this.$t('doorWindowSensor') },
    { zigbeeModel: 'SensorMotion', name: this.$t('motionSensor') },
    { zigbeeModel: 'SensorWaterLeak', name: this.$t('leakSensor') },
    { zigbeeModel: 'SmartLightColorDimmable', name: this.$t('dimmableBulb') },
    { zigbeeModel: 'SmartLightColorChangeable', name: this.$t('colorBulb') },
    { zigbeeModel: 'SensorSmoke', name: this.$t('vibrationSensor') },
    { zigbeeModel: 'SensorVibration', name: this.$t('smokeSensor') },
    { zigbeeModel: 'WallSwitch', name: this.$t('wallSwitch') },
    { zigbeeModel: 'SmartPlug', name: this.$t('smartPlug') },
    { zigbeeModel: 'IASWarning', name: this.$t('smartSiren') },
    { zigbeeModel: 'SensorTemperatureOutdoor', name: this.$t('sensorTemperatureOutdoor') }
  ]
}

const defaultDeviceNames = (typeI) => {
  if (typeI === 'SensorDoorWindow') {
    return 'Door/Window Sensor'
  } else if (typeI === 'SensorMotion') {
    return 'Motion Sensor'
  } else if (typeI === 'SensorWaterLeak') {
    return 'Water Leak Sensor'
  } else if (typeI === 'SensorMulti') {
    return 'Multi Sensor'
  } else if (typeI === 'SensorSmoke') {
    return 'Smoke Sensor'
  } else if (typeI === 'SensorVibration') {
    return 'Vibration Sensor'
  } else if (typeI === 'SensorTemperatureOutdoor') {
    return 'Outdoor Temp/Humi Sensor'
  } else if (typeI === 'SensorTemperatureIndoor') {
    return 'Indoor Temp/Humi Sensor'
  } else if (typeI === 'IASWarning') {
    return 'Smart Siren'
  } else if (typeI === 'SmartPlug') {
    return 'Smart Plug'
  } else {
    return typeI
  }
}

export {
  getAppVersion,
  availableZigbeeDevices,
  defaultDeviceNames
}

// { zigbeeModel: 'SensorDoorWindow', typeI: 'SensorDoorWindow', name: this.$t('doorWindowSensor') },
// { zigbeeModel: 'SensorMotion', typeI: 'SensorMulti', name: this.$t('multiSensor') },
// { zigbeeModel: 'SensorMotion', typeI: 'SensorMotion', name: this.$t('motionSensor') },
// { zigbeeModel: 'SensorWaterLeak', typeI: 'SensorWaterLeak', name: this.$t('leakSensor') },
// { zigbeeModel: 'SmartLightColorDimmable', typeI: 'SmartLightColorDimmable', name: this.$t('dimmableBulb') },
// { zigbeeModel: 'SmartLightColorChangeable', typeI: 'SmartLightColorChangeable', name: this.$t('colorBulb') },
// { zigbeeModel: 'SensorSmoke', typeI: 'SensorSmoke', name: this.$t('smokeSensor') },
// // { zigbeeModel: 'WallSwitch', name: this.$t('wallSwitch') },
// { zigbeeModel: 'SmartPlug', typeI: 'SmartPlug', name: this.$t('smartPlug') },
// { zigbeeModel: 'IASWarning', typeI: 'IASWarning', name: this.$t('smartSiren') },
// { zigbeeModel: 'SensorTemperatureOutdoor', typeI: 'SensorTemperatureOutdoor', name: this.$t('sensorTemperatureOutdoor') },
// { zigbeeModel: 'SensorTemperatureOutdoor', typeI: 'SensorTemperatureIndoor', name: this.$t('sensorTemperatureIndoor') }