<template>
  <div :class="userRole === 'Landlord' ? 'background--modal-landlord' : 'background--modal'" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === 1"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else-if="selectedView > 1 && selectedViewNotification === 1"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = 1"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else-if="selectedViewNotification === 2 || selectedViewNotification === 3"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedViewNotification = 1, notificationToEdit = {}"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title class="text--white text-18">
        {{ selectedViewName }}
      </ion-title>
      <!-- <ion-button
        v-if="selectedView > 1"
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
      >
        <ion-icon
          color="primary"
          size="large"
          :icon="checkmarkDoneOutline"
        />
      </ion-button> -->
    </ion-toolbar>
  </ion-header>
  <ion-content
    overflow-scroll="true"
    class="ion-padding-start ion-padding-end ion-padding-bottom"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="selectedView === 1">
        <ion-item
          button
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners"
          @click="selectedView = 6"
        >
          <i
            slot="start"
            class="mdi mdi-tune-variant text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('applicationSettings') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          button
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          @click="openFeedbackModal"
        >
          <i
            slot="start"
            class="mdi mdi-message-draw text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('feedback') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          v-if="userRole !== 'Landlord'"
          button
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          @click="selectedView = 2"
        >
          <i
            slot="start"
            class="mdi mdi-router-wireless text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('myGateway') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          v-if="userRole !== 'Landlord'"
          detail="false"
          button
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          @click="selectedView = 3"
        >
          <i
            slot="start"
            class="mdi mdi-bell-ring text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('notifications') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          v-if="userRole !== 'Landlord'"
          detail="false"
          button
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          :class="userPermissions.canChangeGeofence === false ? 'disabled' : ''"
          @click="selectedView = 5"
        >
          <i
            slot="start"
            class="mdi mdi-map-marker-outline text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            Geofence
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          v-if="userRole !== 'Landlord'"
          detail="false"
          button
          disabled
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          @click="selectedView = 9"
        >
          <i
            slot="start"
            class="mdi mdi-radiator text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('House Heating') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <ion-item
          v-if="userRole === 'Landlord'"
          detail="false"
          button
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          @click="selectedView = 8"
        >
          <i
            slot="start"
            class="mdi mdi-cog-outline text--gray"
            style="font-size: 22px;"
          />
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            Global Variables
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <div class="version">
          <div style="position: relative; left: -50%;">
            API Version: {{ APIVersion }}<br>
            APP ID: {{ propertyId }}
          </div>
        </div>
      </div>
      <!-- gateway settings -->
      <div v-else-if="selectedView === 2">
        <div>
          <div class="mt-20">
            <img
              v-if="getGatewayModel() === 'SEG-X3'"
              style="margin: 0 auto; max-width: 180px;"
              :src="require('../assets/devices/gateway.png')"
            >
            <img
              v-else-if="getGatewayModel() === 'SEG-X5'"
              style="margin: 0 auto; max-width: 180px;"
              :src="require('../assets/devices/gateway2.png')"
            >
          </div>
        </div>
        <div class="text--white mt-30 text-left">
          <div>Status: <span :class="$store.state.userProperties[0].gatewayActive ? 'text--green' : 'text--danger'">{{ $store.state.userProperties[0].gatewayActive ? $t('Active') : $t('Inactive') }} <span v-if="!$store.state.userProperties[0].gatewayActive"> <i class="mdi mdi-alert" /></span></span></div>
          <div v-if="$store.state.userProperties[0].disconnectedAt">
            <div class="mt-8">
              Last disconnected: <span>{{ new Date(Number($store.state.userProperties[0].disconnectedAt)).toLocaleString('sv-SE') }}</span>
            </div>
          </div>
        </div>
        <!-- <ion-item
        v-if="$store.state.userRole === 'Home Owner'"
        detail="false"
        button
        lines="none"
        color="danger"
        class="h-65 rounded-corners mt-30"
        @click="selectedView = 4"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('Remove Gateway') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item> -->
        <ion-button
          v-if="getMyRole() === 'Home Owner' && gatewayExistsInProperty()"
          color="danger"
          class="mt-30"
          @click="selectedView = 4"
        >
          <span>{{ $t('Remove my gateway') }}</span>
        </ion-button>
        <div class="text-left pt-5 pb-5">
          <!-- <span class="text--gray text-14">{{ $t('descriptionWhenRemovingGateway') }}</span> -->
        </div>
      </div>
      <!-- gateway reset -->
      <div v-else-if="selectedView === 4">
        <div class="text--white text-18 text--bold">
        <!-- Reseting your gateway -->
        </div>
        <div class="mt-30 text--white">
          {{ $t('Please make sure you understand folowing') }}: <br>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white mt-10 text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('This action will delete all your scenes') }} <br>
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('This action will delete all your rooms') }} <br>
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('This action will delete all your devices') }} <br>
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('You will need to restart the gateway') }}<br>
          </ion-item>
          <div class="mt-30">
            <span class="text--white">{{ $t('Once process completed you will be logged out and need to log back in') }}</span>
          </div>
          <div class="mt-30">
            <ion-item
              color="transparent"
              lines="none"
              class="text--primary"
            >
              <ion-checkbox v-model="understand" /> {{ $t('Understand and wish to continue') }}
            </ion-item>
            <ion-item
              color="transparent"
              lines="none"
              class="text--primary"
            >
              <ion-checkbox v-model="gatewayReset" /> {{ $t('I have restarted my gateway') }}
            </ion-item>
          </div>
          <div class="mt-30">
            <ion-button
              :disabled="!understand || !gatewayReset || loading"
              color="danger"
              @click="restartGateway"
            >
              <ion-spinner
                v-if="loading"
                name="crescent"
              />
              <span v-else>{{ $t('Remove my gateway') }}</span>
            </ion-button>
          </div>
        </div>
      </div>
      <!-- notifications -->
      <div v-else-if="selectedView === 3">
        <div
          v-if="selectedViewNotification === 1"
          class="text-left text--gray pb-10"
        >
          {{ $t('notificationCameraSettingText') }}
        </div>
        <div
          v-else-if="selectedViewNotification === 2"
          class="text--white pb-10"
        >
          {{ notificationToEdit.deviceName }} <span class="text-14 text--gray">{{ nameMapper(notificationToEdit.zigbeeModel) }}</span>
        </div>
        <div
          v-else
          class="text--white pb-10"
        >
          {{ notificationToEdit.cameraName }} <span class="text-14 text--gray">Camera</span>
        </div>
        <div v-if="selectedViewNotification === 1">
          <ion-item
            v-for="{id, acobaCameraName, notification} in cameras"
            :key="id"
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
            @click="notificationCameraView(id, acobaCameraName, notification)"
          >
            <ion-avatar
              slot="start"
              style="--border-radius: 0;"
            >
              <img :src="require('../assets/devices/addCamera.png')">
            </ion-avatar>
            <ion-text
              class="text--white"
            >
              {{ acobaCameraName }}<br><span class="text-12 text--gray">Camera</span>
            </ion-text>
            <!-- <ion-toggle
            slot="end"
            :value="notification ? false : true"
            :checked="notification"
            @ionChange="notificationSetting($event.target.value, id)"
          /> -->
            <ion-text
              slot="end"
              class="text--white"
            >
              {{ notification ? $t('On') : $t('Off') }}
            </ion-text>
            <ion-icon
              slot="end"
              size="small"
              color="primary"
              :icon="chevronForwardOutline"
            />
          </ion-item>
          <div v-if="!cameras.length">
            <i
              class="mdi mdi-webcam-off text--primary"
              style="font-size: 40px;"
            />
          </div>
        </div>
        <div
          v-if="selectedViewNotification === 1"
          class="text-left text--gray pb-10 mt-30"
        >
          {{ $t('notificationSettingText') }}
        </div>
        <div v-if="selectedViewNotification === 1">
          <ion-item
            v-for="{id, deviceName, notification, notificationWhenScene, zigbeeModel, roomName} in devices"
            :key="id"
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
            @click="notificationView(id, deviceName, notification, notificationWhenScene, zigbeeModel)"
          >
            <ion-avatar
              slot="start"
              style="--border-radius: 0;"
            >
              <img :src="require('../assets/devices/' + zigbeeModel + '.png')">
            </ion-avatar>
            <ion-text
              class="text--white"
            >
              {{ deviceName }}<br><span class="text-12 text--gray">{{ roomName }}</span>
            </ion-text>
            <!-- <ion-toggle
            slot="end"
            :value="notification ? false : true"
            :checked="notification"
            @ionChange="notificationSetting($event.target.value, id)"
          /> -->
            <ion-text
              slot="end"
              class="text--white"
            >
              {{ notification ? $t('On') : $t('Off') }}
            </ion-text>
            <ion-icon
              slot="end"
              size="small"
              color="primary"
              :icon="chevronForwardOutline"
            />
          </ion-item>
          <div v-if="!devices.length">
            <i
              class="mdi mdi-grid-off text--primary"
              style="font-size: 40px;"
            />
          </div>
          <div class="text-left text--primary mt-30">
            {{ $t('General Notifications') }}
          </div>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <ion-text
              slot="start"
              class="text--white"
            >
              {{ $t('alarmNotifcation') }}
            </ion-text>
            <ion-toggle
              slot="end"
              :value="userData.pushNotificationWhenAlarm ? false : true"
              :checked="userData.pushNotificationWhenAlarm"
              @ionChange="controlPushWhenAlarm($event.target.value)"
            />
          </ion-item>
          <div class="text-left pt-5 pb-5">
            <span class="text--gray text-14">{{ $t('descriptionWhenAlarm') }}</span>
          </div>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <ion-text
              slot="start"
              class="text--white"
            >
              {{ $t('Notification scene change') }}
            </ion-text>
            <ion-toggle
              slot="end"
              :value="userData.pushWhenSceneChange ? false : true"
              :checked="userData.pushWhenSceneChange"
              @ionChange="controlPushWhenSceneChange($event.target.value)"
            />
          </ion-item>
          <div class="text-left pt-5 pb-5">
            <span class="text--gray text-14">{{ $t('descriptionWhenSceneChange') }}</span>
          </div>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <span
              slot="start"
              class="text--white"
            >
              {{ $t('Notification alarm change') }}
            </span>
            <ion-toggle
              slot="end"
              :value="userData.pushWhenAlarmChange ? false : true"
              :checked="userData.pushWhenAlarmChange"
              @ionChange="controlPushWhenAlarmChange($event.target.value)"
            />
          </ion-item>
          <div
            class="text-left pt-5 pb-5"
          >
            <span class="text--gray text-14">{{ $t('alarmStatusNotification') }}</span>
          </div>
          <div v-if="userRole === 'Tenant' || userRole === 'Homeowner'">
            <ion-item
              detail="false"
              lines="none"
              color="transparent"
              class="device-item rounded-corners mt-8 text--white"
            >
              <ion-text
                class="text--white"
              >
                {{ $t('Notification when gateway disconnected') }}
              </ion-text>
              <ion-toggle
                slot="end"
                :value="userProperties.notificationWhenGatewayDisconnects ? false : true"
                :checked="userProperties.notificationWhenGatewayDisconnects"
                @ionChange="controlPushWhenGatewayDisconnects($event.target.value)"
              />
            </ion-item>
            <div
              class="text-left pt-5"
              style="padding-bottom: 50px;"
            >
              <span class="text--gray text-14">{{ $t('gatewayDisconnectedNotification') }}</span>
            </div>
          </div>
        </div>
        <div v-else-if="selectedViewNotification === 2">
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <ion-label slot="start">
              {{ $t('Allow notifications') }}
            </ion-label>
            <ion-toggle
              slot="end"
              :value="notificationToEdit.notification ? false : true"
              :checked="notificationToEdit.notification"
              @ionChange="notificationSetting($event.target.value, notificationToEdit.id)"
            />
          </ion-item>
          <ion-item
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <ion-label>
              {{ $t('When scene is') }}
            </ion-label>
            <ion-select
              v-model="notificationToEdit.notificationWhenScene"
              :placeholder="$t('Off')"
              cancel-text="Avbryt"
              @ionChange="notificationWhenSceneChange($event.target.value, notificationToEdit)"
            >
              <ion-select-option
                key="Off"
                value="Off"
              >
                {{ $t('Off') }}
              </ion-select-option>
              <ion-select-option
                v-for="{sceneName} in propertyScenes"
                :key="sceneName"
                :value="sceneName"
              >
                {{ sceneName }}
              </ion-select-option>
            </ion-select>
            <i class="mdi mdi-chevron-down text--primary pl-10" />
          </ion-item>
          <!-- <ion-item
          button
          detail="false"
          lines="none"
          class="h-65 rounded-corners mt-8 text--white"
        >
          <ion-label>
            People
          </ion-label>
          <ion-select placeholder="Select One">
            <ion-select-option
              v-for="{option} in peopleOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </ion-select-option>
          </ion-select>
        </ion-item> -->
          <div class="mt-10 text-14 text-left text--gray">
            {{ $t('notificationSettingHelpText') }}
          </div>
        </div>
        <div v-else>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <ion-label slot="start">
              {{ $t('Allow notifications') }}
            </ion-label>
            <ion-toggle
              slot="end"
              :value="notificationToEdit.notification ? false : true"
              :checked="notificationToEdit.notification"
              @ionChange="notificationCameraSetting($event.target.value, notificationToEdit.id)"
            />
          </ion-item>
        </div>
      </div>
      <!-- Geo fencing -->
      <div v-else-if="selectedView === 5">
        <ion-item
          v-if="myProperties.length > 1"
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>Hems</ion-label>
          <ion-select v-model="selectedHomeFence">
            <ion-select-option
              v-for="property in myProperties"
              :key="property"
              :value="property"
            >
              {{ mapPropertyName(property) }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--gray" />
        </ion-item>

        <ion-item
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label slot="start">
            {{ $t('Allow geofence') }}
          </ion-label>
          <ion-toggle
            slot="end"
            v-model="geofenceEnabled"
            :disabled="!locationPermissions"
          />
        </ion-item>
        <ion-item
          v-if="geofenceEnabled"
          button
          detail="false"
          color="transparent"
          lines="none"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>
            {{ $t('Geofence radius') }}
          </ion-label>
          <ion-select
            v-model="radius"
            placeholder="Select One"
          >
            <ion-select-option
              v-for="{value, name} in radiusOptions"
              :key="value"
              :value="value"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--gray" />
        </ion-item>
        <div
          v-if="geofenceEnabled"
          lines="none"
          class="mt-10 mb-10 rounded-corners"
          style="height: 45vh; z-index: 10;"
        >
          <GMapMap
            class="rounded-corners"
            :center="center"
            :options="mapOptions"
            :zoom="16"
            map-type-id="terrain"
            style="width: 100%; height: 100%; z-index: -10; border-radius: 10px;"
          >
            <GMapMarker
              :position="center"
              :clickable="true"
              :draggable="true"
              @dragend="newPosition($event)"
            />
            <GMapCircle
              :options="circleOptions"
              :radius="radius"
              :center="center"
            />
          </GMapMap>
        </div>
        <ion-button
          v-if="geofenceEnabledAnswer === false && geofenceEnabled"
          @click="turnOnGeolocationFence"
        >
          <ion-label v-if="!loading">
            {{ $t('Add geofence') }}
          </ion-label>
          <ion-spinner
            v-else
            name="crescent"
          />
        </ion-button>
        <ion-button
          v-if="geofenceEnabledAnswer === true && geofenceEnabled"
          @click="removeGeofece(selectedHomeFence)"
        >
          <ion-label v-if="!loading">
            {{ $t('Remove geofence') }}
          </ion-label>
          <ion-spinner
            v-else
            name="crescent"
          />
        </ion-button>

        <!-- allow location -->
        <div
          v-if="!locationPermissions"
          class="mt-30"
        >
          <div v-if="platform.includes('android')">
            <span class="text--white">{{ $t('Geolocation disabled, if you wish to use geofence please click on button below') }}</span>
            <ion-button
              class="mt-30"
              @click="requestAndroidPermission"
            >
              {{ $t('Request permissions') }}
            </ion-button>
          </div>
          <div v-else-if="platform.includes('ios')">
            <span class="text--white">{{ $t('Geolocation disabled, if you wish to use geofence please click on button belowIOS') }}</span>
            <ion-button
              class="mt-30"
              @click="openIosPermissions"
            >
              {{ $t('Allow Location') }}
            </ion-button>
          </div>
          <div v-else>
            <span>Not supported platform</span>
          </div>
        </div>
      </div>
      <!-- App settings -->
      <div v-else-if="selectedView === 6">
        <ion-item
          class="device-item rounded-corners text--white"
          color="transparent"
          lines="none"
        >
          <ion-label>{{ $t('appLanguage') }}</ion-label>
          <ion-select
            v-model="language"
            placeholder="Select One"
            cancel-text="Avbryt"
          >
            <ion-select-option value="EN">
              English
            </ion-select-option>
            <ion-select-option value="SE">
              Svenska
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item
          color="transparent"
          class="device-item rounded-corners text--white mt-8"
          lines="none"
        >
          <ion-label>{{ $t('homevisionNewsletter') }}</ion-label>
          <!--<ion-toggle
          slot="end"
          :value="subscribed"
          :checked="subscribed"
          @ionChange="newsletterSubscription($event.target.value)"
        /> -->
          <ion-toggle
            slot="end"
            :value="subscribedNewsletter ? false : true"
            :checked="subscribedNewsletter"
            @ionChange="controlNewsletterChange($event.target.value)"
          />
        </ion-item>
        <ion-item
          color="transparent"
          class="device-item rounded-corners text--white mt-8"
          lines="none"
        >
          <ion-label>{{ $t('homevisionDealsandOffers') }}</ion-label>
          <!--<ion-toggle
          slot="end"
          :value="subscribed"
          :checked="subscribed"
          @ionChange="newsletterSubscription($event.target.value)"
        /> -->
          <ion-toggle
            slot="end"
            :value="subscribedDealsOffers ? false : true"
            :checked="subscribedDealsOffers"
            @ionChange="controlDealsOffersChange($event.target.value)"
          />
        </ion-item>
        <ion-item
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-30"
          @click="selectedView = 7"
        >
          <ion-text
            slot="start"
            class="text--primary text-16"
          >
            {{ $t('Remove my account') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
      </div>
      <!-- Remove Account -->
      <div v-else-if="selectedView === 7">
        <div
          v-if="userData.role === 'Home Owner'"
          class="text--primary text--uppercase"
        >
          <span class="text-22">{{ $t('defaultHomeUser') }}</span><br><br>
          {{ $t('deletingAccountHomeOwnerText1') }}<br><br>
          {{ $t('deletingAccountHomeOwnerText2') }}
        </div>
        <div
          v-else-if="userData.role === 'Tenant'"
          class="text--primary text--uppercase"
        >
          <span class="text-22">{{ $t('defaultHomeUser') }}</span><br><br>
          {{ $t('deletingAccountTenantText1') }}<br><br>
          {{ $t('deletingAccountTenantText2') }}
        </div>
        <div
          v-else
          class="text--primary text--uppercase"
        >
          {{ $t('Are you sure you want to delete your account?') }}
        </div>
        <div class="mt-30">
          <ion-item
            color="transparent"
            lines="none"
            class="text--primary"
          >
            <ion-checkbox v-model="understandToDeleteMyAccount" /> {{ $t('Understand and wish to continue') }}
          </ion-item>
        </div>
        <ion-button
          class="mt-30"
          :disabled="!understandToDeleteMyAccount"
          color="danger"
          @click="deleteMyAccount(userData)"
        >
          <ion-spinner
            v-if="loading"
            name="crescent"
          />
          <span v-else>{{ $t('Remove my account') }}</span>
        </ion-button>
      </div>
      <!-- Landlord Variables -->
      <div v-else-if="selectedView === 8">
        <ion-item
          lines="none"
          color="transparent"
          class="device-item rounded-corners text--white"
        >
          <ion-label>
            Fire notification delay
          </ion-label>
          <ion-select
            v-model="userData.fireNotificationDelay"
            cancel-text="Avbryt"
            @ionChange="fireNotificationDelayLandlord($event.target.value)"
          >
            <ion-select-option
              v-for="{time, name} in timeDelay"
              :key="time"
              :value="time"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--primary pl-10" />
        </ion-item>
        <div class="text-left pt-5 pb-5">
          <span class="text--gray text-14">It can happen to anyone, somebody can leave slice of bread in toaster
            bit longer and it may cause false fire alarm. This setting allows you to create some kind of time
            buffer and you wont be bothered unless the alarm persists for longer than e.g. 30 seconds. </span>
        </div>
        <ion-item
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>
            Waterleak notification delay
          </ion-label>
          <ion-select
            v-model="userData.waterLeakNotificationDelay"
            cancel-text="Avbryt"
            @ionChange="waterLeakNotificationDelayLandlord($event.target.value)"
          >
            <ion-select-option
              v-for="{time, name} in timeDelay"
              :key="time"
              :value="time"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--primary pl-10" />
        </ion-item>
        <div class="text-left pt-5 pb-5">
          <span class="text--gray text-14">It can happen to anyone, somebody can leave slice of bread in toaster
            bit longer and it may cause false fire alarm. This setting allows you to create some kind of time
            buffer and you wont be bothered unless the alarm persists for longer than e.g. 30 seconds. </span>
        </div>
        <ion-item
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>
            Climate alert if temperature is below
          </ion-label>
          <ion-select
            v-model="userData.climateWarningBelow"
            cancel-text="Avbryt"
            @ionChange="climateWarningBelowLandlord($event.target.value)"
          >
            <ion-select-option
              v-for="{temp, name} in climateWarning"
              :key="temp"
              :value="temp"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--primary pl-10" />
        </ion-item>
        <ion-item
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>
            Climate alert if temperature is above
          </ion-label>
          <ion-select
            v-model="userData.climateWarningAbove"
            cancel-text="Avbryt"
            @ionChange="climateWarningAboveLandlord($event.target.value)"
          >
            <ion-select-option
              v-for="{temp, name} in climateWarning"
              :key="temp"
              :value="temp"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--primary pl-10" />
        </ion-item>
        <div class="text-left pt-5 pb-5">
          <span class="text--gray text-14">We will let you know if any of the apartments overheating or if there is too cold </span>
        </div>
        <ion-item
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>
            Climate alert delay
          </ion-label>
          <ion-select
            v-model="userData.climateWarningDelayDays"
            cancel-text="Avbryt"
            @ionChange="climateWarningDelayDaysLandlord($event.target.value)"
          >
            <ion-select-option
              v-for="{days, name} in climateWarningDelay"
              :key="days"
              :value="days"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--primary pl-10" />
        </ion-item>
        <div class="text-left pt-5 pb-5">
          <span class="text--gray text-14">For how long we should delay the warning before letting you know?</span>
        </div>
      </div>
      <div v-else-if="selectedView === 9">
        <div v-if="currentPropertySelectedPermissions.role === 'Tenant'">
          <div><i class="mdi mdi-alert-circle-outline text--primary text-24" /></div>
          <span class="text--primary">{{ $t('Unfortunately you cannot control heating system in this apartment') }}</span>
        </div>
        <div v-else-if="currentPropertySelectedPermissions.role === 'House Member'">
          <div><i class="mdi mdi-alert-circle-outline text--primary text-24" /></div>
          <span class="text--primary">{{ $t('Unfortunately you cannot control heating in this apartment/house') }}</span>
        </div>
        <div v-else-if="currentPropertySelectedPermissions.role === 'Home Owner'">
          <div class="text--grayish text-left">
            <i class="mdi mdi-home-map-marker text-18 text--primary" />
            {{ findCurrentHouseAddress() }}
          </div>
          <div class="text--primary text-left mt-20">
            General settings
          </div>
          <div class="text-left text-12 text--grayish mt-5">
            Homevision's AI heating solution can predict and adapt heating settings to maximize savings while keeping your house warm <span class="text--underline text--primary">See more information</span>
          </div>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <i
              slot="start"
              class="mdi mdi-radiator text--primary"
            />
            <ion-text
              slot="start"
              class="text--white"
            >
              {{ $t('Heating enabled') }}
            </ion-text>
            <ion-toggle
              slot="end"
              v-model="propertyHeatingEnabled"
            />
          </ion-item>
          <ion-item
            :disabled="!propertyHeatingEnabled"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8 text--white"
          >
            <i
              slot="start"
              class="mdi mdi-thermometer text--primary"
            />
            <ion-label>
              {{ $t('Target temperature') }}
            </ion-label>
            <ion-select
              v-model="propertyTargetTemperature"
              class="text--primary"
              cancel-text="Avbryt"
            >
              <ion-select-option
                v-for="{temperature, name} in temperatures"
                :key="temperature"
                :value="temperature"
              >
                {{ name }}
              </ion-select-option>
            </ion-select>
            <i class="mdi mdi-chevron-down text--grayish pl-5" />
          </ion-item>
          <div class="text--primary text-left mt-20">
            Customizations
          </div>
          <div class="text-left text-12 text--grayish mt-5">
            Auto-ON / auto-OFF AI heating when indoor temperature goes below/above target temperature and weather forecast indicates radical change
          </div>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="mt-8 text--white"
          >
            <i
              slot="start"
              class="mdi mdi-snowflake text--yellow"
            />
            <ion-text
              slot="start"
              class="text--white"
            >
              AI Auto-ON heating
            </ion-text>
            <ion-toggle
              slot="end"
              v-model="propertyHeatingEnabled"
            />
          </ion-item>
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="mt-8 text--white"
          >
            <i
              slot="start"
              class="mdi mdi-white-balance-sunny text--danger"
            />
            <ion-text
              slot="start"
              class="text--white"
            >
              AI Auto-OFF heating
            </ion-text>
            <ion-toggle
              slot="end"
              v-model="propertyHeatingEnabled"
            />
          </ion-item>
          <ion-button
            class="mt-20"
            @click="saveHeatingSettings"
          >
            <ion-label v-if="!loading">
              {{ $t('Save') }}
            </ion-label>
            <ion-spinner
              v-else
              name="crescent"
            />
          </ion-button>
        </div>
        <div v-else>
          <div><i class="mdi mdi-alert-circle-outline text--primary text-24" /></div>
          <span class="text--primary">{{ $t('Something has failed please try that again') }}</span>
        </div>
      </div>
    </transition>
  </ion-content>
</template>

<script>
// import { Plugins } from '@capacitor/core'
import {
  closeOutline,
  addCircleOutline,
  checkmarkDoneOutline,
  closeCircleOutline,
  chevronForwardOutline,
  alertOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Geofence } from '@ionic-native/geofence/'
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'
import { Geolocation } from '@ionic-native/geolocation/'
import { AndroidPermissions } from '@ionic-native/android-permissions'
import { Diagnostic } from '@ionic-native/diagnostic'
import { modalController, toastController } from '@ionic/vue'

import { PushNotifications } from '@capacitor/push-notifications'

import feedbackModal from './FeedbackModal.vue'

import $socket from '../lib/socketIo'

import { Device } from '@capacitor/device'

export default defineComponent({
  components: {
  },
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      myPropertiesData: [],
      temperatures: [{ temperature: 15, name: '15°C' }, { temperature: 16, name: '16°C' }],
      myProperties: [],
      propertyHeatingEnabled: true,
      propertyTargetTemperature: 15,
      currentPropertySelected: null,
      currentPropertySelectedPermissions: null,
      myGeoFences: [],
      selectedHomeFence: null,
      geofenceEnabled: false,
      APIVersion: 0,
      diagnostic: Diagnostic,
      androidPermissions: AndroidPermissions,
      geolocation: Geolocation,
      closeOutline,
      addCircleOutline,
      checkmarkDoneOutline,
      closeCircleOutline,
      chevronForwardOutline,
      selectedView: 1,
      selectedViewNotification: 1,
      notificationToEdit: {},
      peopleOptions: [
        { option: 'No one is at home' }
      ],
      gatewayReset: false,
      understand: false,
      loading: false,
      understandToDeleteMyAccount: false,
      // geofenceEnabled: false,
      locationPermissions: false,
      osVersion: '',
      operatingSystem: '',
      devicePlatform: '',
      mapOptions: {
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }]
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }]
          }
        ]
      },
      geofenceId: 0,
      radius: 200,
      center: { lat: 49.05086915589208, lng: 18.923319988021966 },
      radiusOptions: [
        { value: 200, name: '200 Meter' },
        { value: 250, name: '250 Meter' },
        { value: 300, name: '300 Meter' }
      ],
      circleOptions: {
        strokeColor: '#ffd4a4',
        strokeOpacity: 0.5,
        strokeWeight: 0.5,
        fillColor: '#ffd4a4',
        fillOpacity: 0.35
      },
      event: 'Events',
      timeDelay: [
        { time: 30, name: '30 sec' },
        { time: 60, name: '1 min' },
        { time: 120, name: '2 min' }
      ],
      climateWarning: [
        { temp: 17, name: '17°C' },
        { temp: 18, name: '18°C' },
        { temp: 19, name: '19°C' },
        { temp: 20, name: '20°C' },
        { temp: 21, name: '21°C' },
        { temp: 22, name: '22°C' },
        { temp: 23, name: '23°C' },
        { temp: 24, name: '24°C' },
        { temp: 25, name: '25°C' },
        { temp: 26, name: '26°C' }
      ],
      climateWarningDelay: [
        { days: 1, name: '1 Day' },
        { days: 2, name: '2 Days' },
        { days: 3, name: '3 Days' },
        { days: 4, name: '4 Days' },
        { days: 5, name: '5 Days' },
        { days: 6, name: '6 Days' },
        { days: 7, name: '7 Days' },
        { days: 8, name: '8 Days' }
      ],
      userId: this.$store.state.userId
    }
  },
  computed: {
    language: {
      get () {
        return this.$store.state.language
      },
      set (value) {
        const language = value
        this.$i18n.locale = language
        this.$store.dispatch('changeAppLanguage', { language: language })
      }
    },
    propertyScenes () {
      return this.$store.state.propertyScenes
    },
    devices () {
      return this.$store.state.propertyDevices.filter(item => item.security === true || item.alarm === true)
    },
    cameras () {
      return this.$store.state.propertyCameras
    },
    selectedViewName () {
      const view = this.selectedView
      let selectedView = ''
      if (view === 1) {
        selectedView = this.$t('settingsTitle')
      }
      if (view === 2) {
        selectedView = this.$t('myGateway')
      }
      if (view === 3) {
        selectedView = this.$t('notifications')
      }
      if (view === 4) {
        selectedView = ''
      }
      if (view === 5) {
        selectedView = this.$t('geoFencing')
      }
      if (view === 9) {
        selectedView = 'House Heating'
      }
      return selectedView
    },
    userData () {
      return this.$store.state.userData[0]
    },
    userProperties () {
      return this.$store.state.userProperties[0]
    },
    userRole () {
      return this.$store.state.userRole
    },
    platform () {
      return this.$store.state.platform
    },
    propertyId () {
      return this.$store.state.currentPropertySelected
    },
    subscribedNewsletter () {
      return this.$store.state.subscribeNewsletter
    },
    subscribedDealsOffers () {
      return this.$store.state.subscribeDealsOffers
    },
    // canChangeGeofence () {
    userPermissions () {
      const data = this.$store.state.userData.length ? this.$store.state.userData[0].propertyLevelPermissions || [] : undefined
      const currentProperty = this.$store.state.currentPropertySelected ? parseInt(this.$store.state.currentPropertySelected) : undefined

      const response = { canChangeScenes: true, canEditCreateScenes: true, canEditCreateRooms: true, canChangeGeofence: true }

      if (data !== undefined && currentProperty !== undefined) {
        const found = data.find(item => parseInt(item.propertyId) === parseInt(currentProperty))
        console.log(found)
        if (found) {
          response.canChangeScenes = found.userCanChangeScenes
          response.canEditCreateScenes = found.userCanEditCreateScenes
          response.canEditCreateRooms = found.userCanEditCreateRooms
          response.canChangeGeofence = found.userCanChangeGeofence
        }
      }
      return response
    },
    // geofenceEnabled () {
    //   const propertyId = parseInt(this.selectedHomeFence)
    //   let found = this.myGeoFences.find(item => item.propertyId === propertyId)
    //   if (!found) {
    //     found = this.$store.state.userData[0].myGeoFences.find(item => item.propertyId === propertyId)
    //   }
    //   console.log(found)
    //   return !!found
    // },
    geofenceEnabledAnswer () {
      const propertyId = parseInt(this.selectedHomeFence)
      const found = this.myGeoFences.find(item => item.propertyId === propertyId)
      let answer = false
      if (found) {
        answer = true
      }

      return answer
    }
  },
  watch: {
    selectedView: function (value) {
      // console.log(value)
      if (value === 5) {
        if (this.platform.includes('android')) {
          this.checkAndroidPermissions()
        } else if (this.platform.includes('ios')) {
          this.checkIosPermissions()
        }
      }
    },
    selectedHomeFence: function (val) {
      const find = this.myGeoFences.find(item => item.propertyId === val)
      console.log('found fence')
      console.log(find)
      if (find) {
        this.geofenceEnabled = find.geofenceEnabled
        this.radius = find.radius
        this.center = { lat: find.lat, lng: find.lng }
      } else {
        this.geofenceEnabled = false
        this.radius = 200
        this.center = this.$store.state.initLocation.lat ? this.$store.state.initLocation : { lat: 49.05086915589208, lng: 18.923319988021966 }
      }

      console.log(this.center)
    },
    geofenceEnabled: function (val) {
      if (val === false && this.geofenceEnabledAnswer === true) {
        this.removeGeofece(this.selectedHomeFence)
      }
    }
  },
  mounted () {
    // console.log(this.$store.state.userData[0])
    this.currentPropertySelected = parseInt(this.$store.state.currentPropertySelected)
    const userPropertyPermissions = this.$store.state.userData[0].propertyLevelPermissions.find(item => item.propertyId === this.currentPropertySelected)
    this.currentPropertySelectedPermissions = userPropertyPermissions
    this.myGeoFences = this.$store.state.userData[0].myGeoFences
    const myPropertyIds = this.$store.state.userData[0].myPropertyIds.split(',')
    myPropertyIds.pop()
    myPropertyIds.forEach(item => {
      this.myProperties.push(Number(item))
    })
    this.selectedHomeFence = this.myProperties[0]
    // console.log('my fences')
    // console.log(this.myGeoFences)
    // console.log('my properties')
    // console.log(this.myProperties)
    // console.log('selected house')
    // console.log(this.selectedHomeFence)
  },
  created () {
    this.getAPIVersion()
    this.getSubscribedNewsletter()
    this.getSubscribedDealsOffers()
    this.getMyPropertiesData()
    this.getDevInfo()
    this.getMyRole()
  },
  methods: {
    async presentToast (message) {
      let messageToDisplay = message
      let icon
      let css
      if (message === 'error') {
        messageToDisplay = this.$t('Something has failed please try that again')
        css = 'action-toast-error'
        icon = alertOutline
      } else if (message === 'success') {
        messageToDisplay = this.$t('updatedSuccessfully')
        css = 'action-toast-success'
        icon = checkmarkDoneOutline
      }
      const toast = await toastController.create({
        message: messageToDisplay,
        duration: 1000,
        position: 'bottom',
        icon: icon,
        cssClass: css
      })

      await toast.present()
    },
    gatewayExistsInProperty () {
      return this.userProperties.propertyGateway !== null
    },
    getMyRole () {
      const userProperties = this.userProperties
      const userData = this.userData

      const find = userData.propertyLevelPermissions.find(item => item.propertyId === userProperties.id)
      if (find) {
        return find.role
      } else {
        return 'error'
      }
    },
    mapPropertyName (stringId) {
      const propertyId = parseInt(stringId)
      const found = this.myPropertiesData.find(item => item.id === propertyId)
      return found.propertyName || this.$store.state.userData[0].name.split(' ')[0] + '`s Hem'
    },
    getMyPropertiesData () {
      this.loading = true
      this.$store.dispatch('getMyProperties')
        .then(response => {
          this.myPropertiesData = response.data
          this.loading = false
        })
    },
    getGatewayModel () {
      const propertyDetail = this.$store.state.userProperties
      return propertyDetail.length ? propertyDetail[0].propertyGateway ? propertyDetail[0].propertyGateway.gateway : 'No Gateway' : 'No Gateway'
    },
    getAPIVersion () {
      this.$store.dispatch('getAPIVersion').then((resp) => {
        this.APIVersion = resp.data.version
      })
    },
    restartGateway () {
      this.loading = true
      this.$store.dispatch('restartGateway').then(() => {
        this.$store.dispatch('LogOut', { userId: this.userId }).then(() => {
          PushNotifications.removeAllListeners()
          this.dismissModal()
          this.$router.push('/login')
        })
      })
        .catch(error => {
          console.log(error)
        })
    },
    notificationSetting (value, id) {
      // console.log(value + ' ' + id)
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('notificationSetting', { setting, id })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'notificationSettings', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    notificationCameraSetting (value, id) {
      // console.log(value + ' ' + id)
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('notificationCameraSetting', { setting, id })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'notificationCameraSetting', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    notificationWhenSceneChange (value, device) {
      // console.log(value)
      // console.log(device)
      this.$store.dispatch('notificationWhenScene', { value, deviceId: device.id })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'notificationWhenScene', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    nameMapper (name) {
      if (name === 'SensorDoorWindow') {
        return this.$t('doorWindowSensor')
      } else if (name === 'SensorWaterLeak') {
        return this.$t('leakSensor')
      } else if (name === 'SensorMotion') {
        return this.$t('motionSensor')
      } else if (name === 'SensorSmoke') {
        return this.$t('smokeSensor')
      }
    },
    notificationView (id, deviceName, notification, notificationWhenScene, zigbeeModel) {
      this.selectedViewNotification = 2
      this.notificationToEdit = {
        id,
        deviceName,
        notification,
        notificationWhenScene,
        zigbeeModel
      }
    },
    notificationCameraView (id, cameraName, notification) {
      this.selectedViewNotification = 3
      this.notificationToEdit = {
        id,
        cameraName,
        notification
      }
    },
    controlPushWhenAlarm (value) {
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushNotificationWhenAlarm', { setting })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'controlPushNotificationWhenAlarm', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    controlPushWhenSceneChange (value) {
      let setting = false
      if (value === 'true' || value === true) {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushWhenSceneChange', { setting })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'controlPushWhenSceneChange', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    controlPushWhenAlarmChange (value) {
      let setting = false
      if (value === 'true' || value === true) {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushWhenAlarmChange', { setting })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'controlPushWhenAlarmChange', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    controlPushWhenGatewayDisconnects (value) {
      let setting = false
      if (value === 'true' || value === true) {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushWhenGatewayDisconnects', { setting })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'controlPushWhenGatewayDisconnects', errorToReport: error, whereFrom: 'SettingsModal' })
        })
    },
    deleteMyAccount (user) {
      // console.log(user)
      const id = user.id
      const role = user.role
      this.loading = true
      this.$store.dispatch('removeMyAccount', { id, role }).then(() => {
        this.$store.dispatch('LogOut', { userId: this.userId }).then(() => {
          this.$router.replace('/signup')
          $socket.disconnect()
          PushNotifications.removeAllListeners()
          this.dismissModal()
        })
      })
    },
    newPosition ($event) {
      // console.log('event')
      this.$nextTick(() => {
        this.center.lat = $event.latLng.lat()
        this.center.lng = $event.latLng.lng()
      })
      // console.log(this.center.lat)
    },
    // enableFenceInHome (value, home) {
    //   console.log(value, home)
    // },
    // addFenceInToHome () {

    // },
    // removeFenceFromHome () {

    // },
    turnOnGeolocationFence () {
      if (this.$store.state.initLocation.lat) {
        this.center = this.$store.state.initLocation
        // console.log(this.center)
        const propertyId = this.selectedHomeFence
        const geofenceEnabled = {
          propertyId: parseInt(propertyId)
        }
        this.myGeoFences.push(geofenceEnabled)
        this.initGeofence()
      } else {
        // console.log('request location')
        this.geolocation.getCurrentPosition().then((resp) => {
          // console.log(resp.coords.latitude)
          // console.log(resp.coords.longitude)
          this.$store.commit('initLocation', { lat: resp.coords.latitude, lng: resp.coords.longitude })
          this.center = { lat: resp.coords.latitude, lng: resp.coords.longitude }
          // console.log(this.center)
          const propertyId = this.selectedHomeFence
          const geofenceEnabled = {
            propertyId: parseInt(propertyId)
          }
          this.myGeoFences.push(geofenceEnabled)
          this.initGeofence()
        }).catch((error) => {
          console.log('Error getting location', error)
        })
      }
    },
    async initGeofence () {
      this.loading = true
      const center = this.center
      const lat = center.lat
      const lng = center.lng
      BackgroundGeolocation.addGeofence({
        identifier: String(this.selectedHomeFence),
        radius: this.radius,
        latitude: lat,
        longitude: lng,
        notifyOnEntry: true,
        notifyOnExit: true,
        extras: {
          userId: this.$store.state.userId,
          propertyId: parseInt(this.selectedHomeFence)
        }
      }).then((success) => {
        // console.log('geofence init')
        const status = true
        this.addGeofence(status)
      }).catch((error) => {
        console.log('[addGeofence] FAILURE: ', error)
      })
    },
    async addGeofence (status) {
      if (status) {
        BackgroundGeolocation.startGeofences()
        const index = this.myGeoFences.findIndex(item => item.propertyId === parseInt(this.selectedHomeFence))
        this.myGeoFences[index].lat = this.center.lat
        this.myGeoFences[index].lng = this.center.lng
        this.myGeoFences[index].radius = this.radius
        this.myGeoFences[index].whereAmI = 'home'
        this.myGeoFences[index].geofenceEnabled = true
        this.$store.dispatch('geofenceAdded', { myGeoFences: this.myGeoFences })
        this.loading = false
      }
      this.loading = false
    },
    removeGeofece (propertyIdString) {
      BackgroundGeolocation.removeGeofence(String(propertyIdString))
        .then(success => {
          // BackgroundGeolocation.stop()
          // Geofence.removeAll() // REMOVE
          // console.log('geofence removed')
          this.geofenceId = 0
          const index = this.myGeoFences.findIndex(item => item.propertyId === parseInt(this.selectedHomeFence))
          this.myGeoFences.splice(index, 1)
          this.geofenceEnabled = false
          console.log(this.myGeoFences)
          if (this.myGeoFences.length < 1) {
            BackgroundGeolocation.stop()
            Geofence.removeAll() // REMOVE
          }
          this.$store.dispatch('removeGeofence', { myGeoFences: this.myGeoFences })
        })
        .catch(error => console.log(error, 'fence error'))
    },
    fireNotificationDelayLandlord (value) {
      this.$store.dispatch('notificationDelaysLandlord', { value, type: 'fireNotificationDelay' })
    },
    waterLeakNotificationDelayLandlord (value) {
      this.$store.dispatch('notificationDelaysLandlord', { value, type: 'waterLeakNotificationDelay' })
    },
    climateWarningBelowLandlord (value) {
      this.$store.dispatch('settingVariables', { value, type: 'climateWarningBelow' })
    },
    climateWarningAboveLandlord (value) {
      this.$store.dispatch('settingVariables', { value, type: 'climateWarningAbove' })
    },
    climateWarningDelayDaysLandlord (value) {
      this.$store.dispatch('settingVariables', { value, type: 'climateWarningDelayDays' })
    },
    dismissModal () {
      this.close()
    },
    checkAndroidPermissions () {
      // console.log('-info-')
      // console.log(this.operatingSystem, this.osVersion, this.devicePlatform)
      if (this.osVersion <= 9) {
        this.androidPermissions.checkPermission('android.permission.ACCESS_FINE_LOCATION').then(
          result => {
            // console.log('Android status permissions <= 9')
            // console.log('PermissionResult          ' + JSON.stringify(result))
            if (!result.hasPermission) {
              this.locationPermissions = false
            } else {
              this.locationPermissions = true
            }
          },
          err => {
            console.log(err)
          }
        )
      } else {
        this.androidPermissions.checkPermission('android.permission.ACCESS_BACKGROUND_LOCATION').then(
          result => {
            // console.log('Android status permissions > 9')
            // console.log('PermissionResult          ' + JSON.stringify(result))
            if (!result.hasPermission) {
              this.locationPermissions = false
            } else {
              this.locationPermissions = true
            }
          },
          err => {
            console.log(err)
          }
        )
      }
    },
    requestAndroidPermission () {
      this.androidPermissions.requestPermissions(
        [
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
          'android.permission.ACCESS_BACKGROUND_LOCATION'
        ]
      ).then(
        this.$nextTick(() => {
          this.androidPermissions.checkPermission('android.permission.ACCESS_BACKGROUND_LOCATION').then(
            result => {
              // console.log('Android status permissions')
              // console.log('PermissionResult          ' + JSON.stringify(result))
              if (!result.hasPermission) {
                this.locationPermissions = false
              } else {
                this.locationPermissions = true
              }
            },
            err => {
              console.log(err)
            }
          )
        })
      )
    },
    checkIosPermissions () {
      this.diagnostic.isLocationAuthorized().then((isEnabled) => {
        if (isEnabled) {
          this.locationPermissions = true
        } else {
          this.locationPermissions = false
        }
      })
    },
    openIosPermissions () {
      window.cordova.plugins.settings.open('locations', () => {
        console.log('wifi settings opened')
      })
      this.locationPermissions = true
    },
    openFeedbackModal () {
      return modalController
        .create({
          component: feedbackModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    getSubscribedNewsletter () {
      this.subscribedNewsletter = this.$store.state.subscribeNewsletter
    },
    getSubscribedDealsOffers () {
      this.subscribedDealsOffers = this.$store.state.subscribeDealsOffers
    },
    controlNewsletterChange (value) {
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('changeNewsletter', { subscribeNewsletter: setting })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'changeNewsletter', errorToReport: error, whereFrom: 'SettingsModal' })
        })
      // console.log(this.$store.state.subscribeNewsletter)
    },
    controlDealsOffersChange (value) {
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('changeDealsOffers', { subscribeDealsOffers: setting })
        .then(() => {
          this.presentToast('success')
        })
        .catch(error => {
          this.presentToast('error')
          this.$store.dispatch('errorReporter', { type: 'changeDealsOffers', errorToReport: error, whereFrom: 'SettingsModal' })
        })
      // console.log(this.$store.state.subscribeNewsletter)
    },
    async getDevInfo () {
      const info = await Device.getInfo()
      this.operatingSystem = info.operatingSystem
      this.osVersion = info.osVersion
      this.devicePlatform = info.platform
    },
    saveHeatingSettings () {
      this.loading = true
      setTimeout(() => { this.loading = false }, 1000)
    },
    findCurrentHouseAddress () {
      const myHouse = this.$store.state.userProperties.find(item => item.id === this.currentPropertySelected)
      return `${myHouse.propertyName}, ${myHouse.street}, ${myHouse.city}`
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}

#mapid { height: 180px; }

.version {
    position: absolute;
    width: 300px;
    bottom: 20px;
    left: 50%;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.288);
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
