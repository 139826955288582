<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border text-center">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedViewCondition === '1'"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-button
        v-else
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedViewCondition = '1'"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title
        v-if="selectedViewCondition === '1'"
        class="text--white text-18"
      >
        {{ pageTitle }}
      </ion-title>
      <ion-title
        v-else
        class="text--white text-18"
      >
        {{ viewName(selectedViewCondition) }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
      />
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="">
      <div
        class="form--dark mb-10 flex-1 ion-padding-bottom"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item
          lines="none"
        >
          <ion-label
            class="label"
            position="floating"
          >
            {{ $t('First and last name') }}
          </ion-label>
          <ion-input
            v-model="newKey.userName"
            enterkeyhint="done"
            type="text"
            class="text--capitalize"
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
        <ion-item
          lines="none"
        >
          <ion-label
            class="label"
            position="floating"
          >
            {{ $t('Access Level') }}
          </ion-label>
          <ion-input
            :value="newKey.userId===null ? $t('guest') : $t('member')"
            enterkeyhint="done"
            type="text"
            disabled="true"
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
        <div
          align="left"
          class="mt-10"
        >
          <ion-text
            class="text--white text-14 ml-10"
          >
            <i class="mdi mdi-check text--primary text-18 mr-10" />{{ $t('Can lock and unlock door') }}
          </ion-text>
        </div>
        <div
          align="left"
          class="mt-10"
        >
          <ion-text
            class="text--white text-14 ml-10"
          >
            <i class="mdi mdi-check text--primary text-18 mr-10" />{{ $t('Can') }} <span class="rednot">{{ $t('not') }}</span> {{ $t('invite/remove guests and owners') }}
          </ion-text>
        </div>
        <div
          align="left"
          class="mt-10"
        >
          <ion-text
            class="text--white text-14 ml-10"
          >
            <i class="mdi mdi-check text--primary text-18 mr-10" />{{ $t('Can') }} <span class="rednot">{{ $t('not') }}</span> {{ $t('edit settings') }}
          </ion-text>
        </div>
        <div
          align="left"
          class="mt-10"
        >
          <ion-text
            class="text--white text-14 ml-10"
          >
            <i class="mdi mdi-check text--primary text-18 mr-10" />{{ $t('Can') }} <span class="rednot">{{ $t('not') }}</span> {{ $t('view history log') }}
          </ion-text>
        </div>
        <div
          align="left"
          class="mt-10"
        >
          <ion-text
            class="text--white text-14 ml-10"
          >
            <i class="mdi mdi-check text--primary text-18 mr-10" />{{ $t('Can') }} <span class="rednot">{{ $t('not') }}</span> {{ $t('view active keys') }}
          </ion-text>
        </div>
        <ion-item
          lines="none"
        >
          <ion-label
            class="label"
            position="floating"
          >
            {{ $t('App Access') }}
          </ion-label>
          <span
            slot="end"
            class="mdi mdi-chevron-down text--white text-18 mt-15"
          />
          <ion-select
            v-model="newKey.accessType"
            placeholder="Select One"
            cancel-text="Avbryt"
            @ionChange="accessTypeChange"
          >
            <ion-select-option
              v-if="!(newKey.userId===null && newKey.id===0)"
              value="no_access"
            >
              {{ $t('no_access') }}
            </ion-select-option>
            <ion-select-option value="temporary">
              {{ $t('temporary') }}
            </ion-select-option>
            <ion-select-option value="recurring">
              {{ $t('recurring') }}
            </ion-select-option>
            <ion-select-option value="always">
              {{ $t('always') }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </div>
    <div v-if="newKey.accessType==='temporary'">
      <div class="d-flex justify-content-center">
        <ion-grid
          align="center"
          class="tempgrid"
        >
          <ion-row>
            <ion-col size="12">
              <ion-row>
                <ion-item
                  lines="none"
                  class="text--white ion-no-padding text--bold labelpart"
                  color="transparent"
                >
                  <ion-text class="datelabel">
                    {{ $t('Start Date') }}
                  </ion-text>
                </ion-item>
              </ion-row>
              <ion-row>
                <ion-item
                  lines="none"
                  class="text--primary ion-no-padding text--bold dateitem"
                  color="transparent"
                >
                  <ion-datetime
                    v-model="newKey.startDate"
                    display-format="M/D/YYYY"
                    :min="todayDateTime"
                    max="2050"
                    class="datepart"
                  />
                </ion-item>
              </ion-row>
              <ion-row>
                <ion-item
                  lines="none"
                  class="text--primary ion-no-padding text--bold timeitem"
                  color="transparent"
                >
                  <ion-datetime
                    v-model="newKey.startTime"
                    display-format="HH:mm"
                    class="timepart timeparttemp"
                    @ionChange="changedStartTime"
                  />
                </ion-item>
              </ion-row>
            </ion-col>
            <ion-col size="12">
              <ion-row>
                <ion-item
                  lines="none"
                  class="text--white ion-no-padding text--bold labelpart"
                  color="transparent"
                >
                  <ion-text class="datelabel">
                    {{ $t('End Date') }}
                  </ion-text>
                </ion-item>
              </ion-row>
              <ion-row>
                <ion-item
                  lines="none"
                  class="text--primary ion-no-padding text--bold dateitem"
                  color="transparent"
                >
                  <ion-datetime
                    v-model="newKey.endDate"
                    display-format="M/D/YYYY"
                    :min="todayDateTime"
                    max="2050"
                    class="datepart"
                  />
                </ion-item>
              </ion-row>
              <ion-row>
                <ion-item
                  lines="none"
                  class="text--primary ion-no-padding text--bold timeitem"
                  color="transparent"
                >
                  <ion-datetime
                    v-model="newKey.endTime"
                    display-format="HH:mm"
                    class="timepart timeparttemp"
                    @ionChange="changedEndTime"
                  />
                </ion-item>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-if="newKey.accessType==='recurring'">
      <div class="d-flex justify-content-center mt-20">
        <ion-item
          lines="none"
          class="text--white ion-no-padding text--bold text--14"
          color="transparent"
        >
          <ion-text class="pr-10 pl-10">
            {{ $t('Start Time') }}
          </ion-text>
        </ion-item>
        <ion-item
          lines="none"
          class="text--white ion-no-padding text--bold text--14"
          color="transparent"
        >
          <ion-text class="pr-10 pl-10">
            {{ $t('End Time') }}
          </ion-text>
        </ion-item>
      </div>
      <div>
        <ion-item
          lines="none"
          class="text--primary ion-no-padding text--bold recurTime"
          color="transparent"
        >
          <ion-datetime
            v-model="newKey.startTime"
            display-format="HH:mm"
            locale="sv-SV"
            presentation="time"
            class="endrectime"
            @ionChange="changedStartTime"
          />
        </ion-item>
      </div>
      <div>
        <ion-item
          lines="none"
          class="text--primary ion-no-padding text--bold recurTime"
          color="transparent"
        >
          <ion-datetime
            v-model="newKey.endTime"
            display-format="HH:mm"
            locale="sv-SV"
            presentation="time"
            class="endrectime"
            @ionChange="changedEndTime"
          />
        </ion-item>
      </div>
      <div class="mt-30 text-left">
        <ion-text class="text--uppercase text--white text--bold">
          {{ $t('select days') }}
        </ion-text>
        <ion-row
          lines="none"
          class="ion-no-padding mt-10"
          color="transparent"
        >
          <ion-button
            class="day-button"
            :class="dayExists('Mon') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Mon')"
          >
            {{ $t('Mon') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Tue') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Tue')"
          >
            {{ $t('Tue') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Wed') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Wed')"
          >
            {{ $t('Wed') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Thu') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Thu')"
          >
            {{ $t('Thu') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Fri') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Fri')"
          >
            {{ $t('Fri') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Sat') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Sat')"
          >
            {{ $t('Sat') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Sun') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Sun')"
          >
            {{ $t('Sun') }}
          </ion-button>
        </ion-row>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div class="mt-30 text-left">
      <ion-button
        class="savebtn"
        color="primary"
        :disabled="newKey.userName===''"
        @click="saveKey()"
      >
        {{ (newKey.userId === null && newKey.id === 0) ? $t('Send Invite') : $t('Save') }}
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import { alertController, modalController } from '@ionic/vue'
import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'
import { v4 as uuid } from 'uuid'
import { Share } from '@capacitor/share'
import { Keyboard } from '@capacitor/keyboard'
import dayjs from 'dayjs'

import doneSharing from './DoneSharing'

export default {
  props: {
    lock: { type: Object, default: () => {} },
    close: { type: Function, default: null },
    ifConditionToSet: { type: Function, default: () => {} }
  },
  data () {
    return {
      closeOutline,
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      selectedViewCondition: '1',
      scheduleTime: {
        startTime: '15:00',
        startDate: '1/1/2000',
        endTime: '15:45',
        endDate: '1/1/2000',
        appliedDays: []
      },
      errorMessage: '',
      newKey: {
        id: '',
        ieee: '',
        userId: null,
        userName: '',
        keyName: '',
        keyToken: '',
        fromDate: '',
        toDate: '',
        accessType: 'no_access',
        startTime: '15:00',
        startDate: '1/1/2000',
        endTime: '15:45',
        endDate: '1/1/2000',
        appliedDays: []
      },
      saving: false,
      dismisstxt: '',
      todayDateTime: 0,
      tomorrowDateTime: 0,
      pageTitle: ''
    }
  },
  computed: {
    propertyId () {
      return this.$store.state.currentPropertySelected
    },
    platform () {
      return this.$store.state.platform
    }
  },
  watch: {
  },
  created () {
    // console.log('in edit: key')
    // console.log(this.lock)
    this.newKey = this.lock

    const today = dayjs()
    const tomorrow = dayjs().add(1, 'day')

    this.todayDateTime = today.toISOString()
    this.tomorrowDateTime = tomorrow.toISOString()

    this.newKey.startDate = today.format('YYYY-MM-DDTHH:mmZ')
    this.newKey.startTime = ('0' + today.get('hour')).slice(-2) + ':' + ('0' + today.get('minute')).slice(-2)
    this.newKey.endDate = tomorrow.format('YYYY-MM-DDTHH:mmZ')
    this.newKey.endTime = ('0' + tomorrow.get('hour')).slice(-2) + ':' + ('0' + tomorrow.get('minute')).slice(-2)

    this.dismisstxt = this.$t('Dismiss')

    console.log(this.newKey.startDate, this.newKey.startTime, this.newKey.endDate, this.newKey.endTime)
  },
  mounted () {
    this.getSmartLockKeyDetails(this.newKey.id)
    if (this.newKey.userId === null && this.newKey.id === 0) {
      this.pageTitle = this.$t('Share Key')
    } else {
      this.pageTitle = this.$t('Edit access')
    }
  },
  methods: {
    getSmartLockKeyDetails (keyId) {
      console.log(this.newKey.startDate, this.newKey.startTime, this.newKey.endDate, this.newKey.endTime)
      if (keyId !== 0) {
        this.$store.dispatch('getSmartLockKeyDetails', keyId).then(resp => {
          // console.log(resp)
          this.newKey = resp.data
          // console.log('from get')
          console.log(this.newKey)
          // this.newKey.appliedDays = JSON.parse(this.newKey.appliedDays)
          if (this.newKey.startDate === null || this.newKey.endDate === null) {
            const today = new Date()
            const tomorrow = new Date()
            tomorrow.setDate(today.getDate() + 1)
            this.newKey.startDate = today.toISOString()
            this.newKey.endDate = tomorrow.toISOString()
          }
          const dayfrom = dayjs(this.newKey.startDate)
          const dayto = dayjs(this.newKey.endDate)
          this.newKey.startDate = dayfrom.format('YYYY-MM-DDTHH:mmZ')
          this.newKey.startTime = ('0' + dayfrom.get('hour')).slice(-2) + ':' + ('0' + dayfrom.get('minute')).slice(-2)
          this.newKey.endDate = dayto.format('YYYY-MM-DDTHH:mmZ')
          this.newKey.endTime = ('0' + dayto.get('hour')).slice(-2) + ':' + ('0' + dayto.get('minute')).slice(-2)
        })
      }
    },
    saveKey () {
      console.log(this.newKey)
      console.log(this.newKey.startDate, this.newKey.startTime, this.newKey.endDate, this.newKey.endTime)
      if (this.newKey.id === 0) {
        // console.log('create')
        this.createKey()
      } else {
        // console.log('update')
        this.updateKey()
      }
      this.listSmarLockKeys()
      this.getSmartLockKeys(this.propertyId, this.newKey.ieee)
    },
    createKey () {
      if (this.saving) return
      console.log(this.newKey.startDate, this.newKey.startTime, this.newKey.endDate, this.newKey.endTime)
      this.saving = true
      this.newKey.userName = this.newKey.userName.trim()
      this.newKey.keyToken = uuid()
      // this.newKey.appliedDays = JSON.stringify(this.newKey.appliedDays)
      this.$store.dispatch('createSmartLockKey', { newKey: this.newKey }).then(resp => {
        // console.log(resp.data.keyToken)
        this.showWarning(resp.data)
        this.saving = false
      })
      // this.showShare()
    },
    updateKey () {
      if (this.saving) return

      this.saving = true
      this.newKey.userName = this.newKey.userName.trim()
      // this.newKey.appliedDays = JSON.stringify(this.newKey.appliedDays)
      this.$store.dispatch('updateSmartLockKey', { newKey: this.newKey }).then(resp => {
        // console.log(resp.data)
        this.dismissModal()
        this.saving = false
      })
      // this.showShare()
    },
    listSmarLockKeys () {
      this.$store.dispatch('listSmartLockKeys', { propertyId: this.propertyId, ieee: this.newKey.ieee }).then(resp => {
      })
    },
    getSmartLockKeys (propertyId, ieee) {
      this.$store.dispatch('listActiveSmartLockKeys', { propertyId, ieee })
        .then(resp => {
        })
        .catch(err => {
          console.log(err)
        })
    },
    accessTypeChange () {
      if (this.newKey.accessType === 'recurring') {
        // if (this.newKey.startTime > this.newKey.endTime) {
        //  this.newKey.startTime = this.newKey.endTime
        // }
        const today = dayjs()

        this.newKey.startDate = today.format('YYYY-MM-DDTHH:mmZ')
        this.newKey.startTime = ('0' + today.get('hour')).slice(-2) + ':' + ('0' + today.get('minute')).slice(-2)
        this.newKey.endDate = today.format('YYYY-MM-DDTHH:mmZ')
        this.newKey.endTime = ('0' + today.get('hour')).slice(-2) + ':' + ('0' + today.get('minute')).slice(-2)
        console.log(this.newKey.startDate, this.newKey.endDate)
      }
    },
    changedStartTime () {
      const sdate = this.newKey.startDate
      const stime = this.newKey.startTime
      const szone = sdate.split('T')[1].split('+')[1]
      this.newKey.startDate = sdate.split('T')[0].split('+')[0] + 'T' + stime + '+' + szone
      console.log('changed start')
      console.log(sdate, stime, szone, this.newKey.startDate)
    },
    changedEndTime () {
      const sdate = this.newKey.endDate
      const stime = this.newKey.endTime
      const szone = sdate.split('T')[1].split('+')[1]
      this.newKey.endDate = sdate.split('T')[0].split('+')[0] + 'T' + stime + '+' + szone
      console.log('changed end')
      console.log(sdate, stime, szone, this.newKey.endDate)
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    async showWarning (key) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Varning'),
          message: this.$t('You are about the share your door lock key. Are you sure you want to proceed?'),
          buttons: [
            {
              text: this.$t('Ja'),
              cssClass: 'secondary',
              handler: () => {
                this.canShare(key)
              }
            },
            {
              text: this.$t('Avbryt'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('')
                this.dismissModal()
              }
            }
          ]
        })
      await alert.present()
    },
    async canShare (key) {
      // console.log(this.getKeyAccess(key))
      await Share.canShare().then(resp => {
        if (resp.value) {
          this.showShare(key)
        } else {
          console.log('cannot share')
          this.errorAlert(this.$t('Share API not available in this browser'))
        }
      })
    },
    async showShare (key) {
      const sharingTxt = this.getKeyAccess(key)
      await Share.share({
        title: this.$t('Homevision Key'),
        text: sharingTxt,
        url: 'https://app.homevision.se/control-lock/' + key.keyToken,
        dialogTitle: this.$t('Homevision Key')
      })
        .then(resp => {
          this.dismissModal()
          this.doneSharing()
          // alert(JSON.stringify(resp))
        })
        .catch((error) => console.log('Error sharing ::: ', error))
    },
    async errorAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: [this.dismisstxt]
        })
      await alert.present()
    },
    viewName (view) {
      const viewIndex = this.conditions.findIndex(item => item.view === view)
      return this.conditions[viewIndex].displayName
    },
    // Schedule time================================================================================================================
    addRemoveDay (day) {
      const dayIndex = this.newKey.appliedDays.findIndex(item => item === day)
      if (dayIndex === -1) {
        this.newKey.appliedDays.push(day)
      } else {
        this.newKey.appliedDays.splice(dayIndex, 1)
      }
    },
    dayExists (day) {
      return this.newKey.appliedDays.includes(day)
    },
    error (error) {
      this.errorMessage = error
      setTimeout(() => { this.errorMessage = '' }, 2000)
    },
    dismissModal () {
      this.close()
    },
    mapDayName (name) {
      return this.$t(name)
    },
    doneSharing () {
      return modalController
        .create({
          component: doneSharing,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    getKeyAccess (key) {
      let accessStr = this.$t('Hi') + ' ' + key.userName + ', \r\n\r\n'
      accessStr += this.$t('Here is your digital key!') + ' ' + this.$t('Use the link for access.')
      accessStr += '\r\n\r\n'
      accessStr += this.$t('Access Level') + ': ' + this.$t(key.accessType) + '\r\n'
      if (key.accessType === 'temporary') {
        const dayfrom = dayjs(key.startDate)
        const dayto = dayjs(key.endDate)
        accessStr += this.$t('App Access') + ': ' + dayfrom.format('MMM D, YYYY HH:mm') + ' - ' + dayto.format('MMM D, YYYY HH:mm')
      }
      if (key.accessType === 'recurring') {
        const dayfrom = dayjs(key.startDate)
        const dayto = dayjs(key.endDate)
        let days = ''
        key.appliedDays.forEach(item => {
          days += ', ' + this.$t(item)
        })
        accessStr += this.$t('App Access') + ': ' + dayfrom.format('HH:mm') + '-' + dayto.format('HH:mm') + days
      }
      return accessStr
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

ion-list {
    background: none;
}

.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
.time-selector {
  max-width: 250px;
}
ion-datetime {
  --background: #2c3036cf;
  border-radius: 5px;
    font-size: 34px;
}
.day-button {
    height: 45px;
    width: 45px;
}
.day-inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
}
.day-active {
    --background: #3F424A;
    color: colors(primary, base);
    box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.15), inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}
.day-active.ion-focusable{
  color:#fff !important
}
.alert-checkbox-label.sc-ion-alert-ios {
  color: white!important;
}

.rednot {
  color:#EC0E0E;
}
.savebtn{
  --ion-color-contrast:#23262F !important;
  height:50px;
}
.tempgrid{
  padding:0px;
}
.labelpart, .datelabel{
  width:100%;
  text-align:left;
  padding-bottom: 5px;
  --min-height:25px;
}
.dateitem, .timeitem{
  width:100%;
  text-align:center;
  --min-height:38px;
  color: white!important;
}
.timepart{
  font-size:24px;
  padding-top:0px;
  width:100%;
}
.timeparttemp{
  font-size:24px;
  padding-top:0px;
  padding-right:0px;
  width:100%;
}
.datepart{
  --color: white;
  font-size:30px;
  padding: 0;
  width:100%;
  ion-label {
    --color: white
  }
}
// ion-picker-column.month {
//   --color-highlight: red;
// }
.recurTime ion-datetime{
  padding-top:0px;
}
ion-datetime::part(text) {
  text-align: center;
}
.endrectime {
  padding-left:10px;
}
</style>
