<template>
  <ion-header
    v-if="$store.state.userRole !== 'Landlord'"
    class="header-wrapper no-shadow"
  >
    <ion-toolbar>
      <ion-title v-if="$store.state.userRole !== 'Landlord'">
        <ion-item
          button
          lines="none"
          detail="false"
          class="header-item"
          @click="editMyProfile('EditMyProfileModal'); closeMenu();"
        >
          <ion-avatar>
            <ion-img
              v-if="profileImage"
              class="profileImage"
              :src="profileImage"
            />
            <ion-img
              v-else
              class="profileImage"
              :src="require('../assets/images/default.png')"
            />
          </ion-avatar>
          <ion-label
            color="light"
            class="header-label"
          >
            {{ getUserName }}
          </ion-label>
          <ion-icon :icon="chevronForwardOutline" />
        </ion-item>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div
      v-if="$store.state.userRole === 'Landlord'"
      class="landlord-name"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="$store.state.companyName"
          class="text-22 text--700 text--white hover-company-name"
          style="height: 59px;display: flex; justify-content: left; align-items: center; margin-left: 15px;"
          tappable
          @click="$router.push('/main-view'); closeMenu();"
        >
          {{ $store.state.companyName }}
        </div>
        <div
          v-else
          class="text--primary"
          style="height: 59px;display: flex; justify-content: left; align-items: center; margin-left: 15px;"
          tappable
          @click="$router.push('/landlord-settings');closeMenu();"
        >
          Missing company name<i class="mdi mdi-open-in-new pl-5" />
        </div>
      </transition>
    </div>
    <div v-if="$store.state.userRole !== 'Landlord'">
      <ion-list style="margin-top: 0px;">
        <ion-item
          lines="none"
          button
          detail="false"
          style="margin-top: 15px;"
          class="add-device"
          @click="addDevices(); closeMenu();"
        >
          <ion-icon
            color="primary"
            :icon="add"
          />
          <ion-label color="primary">
            {{ $t('addDevice') }}
          </ion-label>
        </ion-item>
        <ion-item
          button
          detail="false"
          lines="none"
          @click="openMyDevices(); closeMenu();"
        >
          <ion-icon :icon="grid" />
          <ion-label color="light">
            {{ $t('myDevices') }}
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          detail="false"
          button
          @click="openMyProperties(); closeMenu();"
        >
          <ion-icon :icon="home" />
          <ion-label color="light">
            {{ $t('manageProperties') }}
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          button
          :disabled="$store.state.userRole === 'House Member'"
          detail="false"
          @click="openHomeMembersModal(); closeMenu();"
        >
          <ion-icon :icon="people" />
          <ion-label color="light">
            {{ $t('members') }}
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          button
          :disabled="$store.state.userRole === 'House Member'"
          detail="false"
          @click="openMyOrdersModal(); closeMenu();"
        >
          <ion-icon :icon="cardOutline" />
          <ion-label color="light">
            {{ $t('Membership') }}
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item
          detail="false"
          style="margin-top: 15px;"
          button
          lines="none"
          @click="$router.push('/help-center');closeMenu();"
        >
          <ion-icon :icon="informationCircle" />
          <ion-label color="light">
            {{ $t('helpCenter') }}
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          detail="false"
          button
          @click="openSettingsModal();closeMenu();"
        >
          <ion-icon :icon="options" />
          <ion-label color="light">
            {{ $t('settings') }}
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item
          lines="none"
          style="margin-top: 15px;"
          detail="false"
          button
          @click="logOutAlert();closeMenu();"
        >
          <ion-icon :icon="power" />
          <ion-label color="light">
            {{ $t('logout') }}
          </ion-label>
        </ion-item>
        <!-- <ion-item
          detail="false"
          style="margin-top: 15px;"
          button
          lines="none"
          @click="$router.push('/app-introduction-and-gateway-setup');closeMenu();"
        >
          <ion-icon :icon="informationCircle" />
          <ion-label>Test Setup</ion-label>
        </ion-item> -->
      </ion-list>
    </div>
    <!-- // Landlord ================================================================== -->
    <div v-else-if="$store.state.userRole === 'Landlord'">
      <ion-list>
        <ion-item
          lines="none"
          button
          detail="false"
          class="add-device pt-15"
          :class="$route.name === 'AddProperty' ? 'active' : ''"
          @click="$router.push('/add-property'); closeMenu();"
        >
          <!-- <ion-icon
            color="primary"
            :icon="add"
          /> -->
          <i
            class="mdi mdi-plus-box-multiple-outline"
            style="font-size: 24px;"
            :class="$route.name === 'AddProperty' ? 'text--primary' : 'text--gray'"
          />
          <ion-label>
            Add Property
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          :class="$route.name === 'MainView' || $route.name === 'LandlordStatistics' || $route.name === 'LandlordEvents' ? 'active' : ''"
          button
          detail="false"
          @click="$router.push('/main-view'); closeMenu();"
        >
          <!-- <ion-icon
            :icon="homeOutline"
          /> -->
          <i
            class="mdi mdi-home"
            :class="$route.name === 'MainView' || $route.name === 'LandlordStatistics' || $route.name === 'LandlordEvents' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>
            <!-- {{ $t('overview') }} -->
            Overview
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          :class="$route.name === 'LandlordProperties' ? 'active' : ''"
          button
          detail="false"
          class="add-device"
          @click="$router.push('/landlord-properties'); closeMenu();"
        >
          <!-- <ion-icon
            :icon="businessOutline"
          /> -->
          <i
            class="mdi mdi-domain"
            :class="$route.name === 'LandlordProperties' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>
            Manage Properties
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          :class="$route.name === 'LandlordAlarms' ? 'active' : ''"
          button

          detail="false"
          class="add-device"
          @click="$router.push('/landlord-alarms'); closeMenu();"
        >
          <!-- <ion-icon
            :icon="warningOutline"
          /> -->
          <i
            class="mdi mdi-alert"
            :class="$route.name === 'LandlordAlarms' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>
            Alarms
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item
          v-if="$store.state.mainLandlord === 'true' || $store.state.canBeAdminOfMainLandlord === 'true'"
          lines="none"
          button
          :class="$route.name === 'PropertyManagers' ? 'active' : ''"
          detail="false"
          class="add-device pt-15"
          @click="$router.push('/property-managers'); closeMenu();"
        >
          <i
            class="mdi mdi-account-multiple"
            :class="$route.name === 'PropertyManagers' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>
            Property Managers
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          button
          :class="$route.name === 'GlobalSettings' ? 'active' : ''"
          detail="false"
          class="add-device pt-15"
          @click="$router.push('/global-settings'); closeMenu();"
        >
          <i
            class="mdi mdi-cog"
            :class="$route.name === 'GlobalSettings' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>
            Strategies
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          detail="false"
          button
          :class="$route.name === 'LandlordSettings' ? 'active' : ''"
          @click="$router.push('/landlord-settings');closeMenu();"
        >
          <i
            class="mdi mdi-account-cog"
            :class="$route.name === 'LandlordSettings' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>My Settings</ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item
          detail="false"
          button
          lines="none"
          :class="$route.name === 'HelpCenter' ? 'active' : ''"
          @click="$router.push('/help-center');closeMenu();"
        >
          <i
            class="mdi mdi-help-circle"
            :class="$route.name === 'HelpCenter' ? 'text--primary' : 'text--gray'"
            style="font-size: 24px;"
          />
          <ion-label>
            {{ $t('helpCenter') }}
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          detail="false"
          button
          @click="logOutAlert();closeMenu();"
        >
          <i
            class="mdi mdi-logout text--gray"
            style="font-size: 24px;"
          />
          <ion-label>{{ $t('logout') }}</ion-label>
        </ion-item>
      </ion-list>
    </div>
    <div class="center-absolute">
      <span class="brand-name">Homevision</span><br>
      <span class="version">v.{{ appVersion }}</span>
    </div>
  </ion-content>
</template>

<script>
import myDevicesModal from '../components/MyDevicesModal'
import addDevicesModal from '../components/AddDeviceModal'
import editMyProfileModal from '../components/EditMyProfileModal'
import settingsModal from '../components/SettingsModal.vue'
import homeMembersModal from './HomeMembers/HomeMembersModal.vue'
import feedbackModal from './FeedbackModal.vue'
import myOrdersModal from './Eshop/MyOrdersAndSubsModal.vue'
import myPropertiesModal from './MultiHomes/MyPropertiesModal.vue'

import { modalController, menuController, alertController } from '@ionic/vue'
// import { Geofence } from '@ionic-native/geofence/'
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'
// import { Plugins } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'

import { getAppVersion } from '../lib/variables'

import {
  chevronForwardOutline,
  notifications,
  add,
  home,
  warningOutline,
  people,
  settingsOutline,
  thumbsUp,
  informationCircle,
  options,
  power,
  personCircleOutline,
  grid,
  homeOutline,
  businessOutline,
  cardOutline
} from 'ionicons/icons'
// const { PushNotifications, Keyboard } = Plugins

export default {
  props: {
    devices: { type: Array, default: () => [] },
    close: { type: Function, default: null }
  },
  data () {
    return {
      chevronForwardOutline,
      notifications,
      add,
      home,
      people,
      warningOutline,
      personCircleOutline,
      thumbsUp,
      informationCircle,
      settingsOutline,
      options,
      power,
      grid,
      homeOutline,
      businessOutline,
      cardOutline,
      appVersion: getAppVersion(),
      userId: this.$store.state.userId
    }
  },
  computed: {
    profileImage () {
      return this.$store.state.profileImage
    },
    getUserName () {
      return this.$store.state.userName
    },
    platform () {
      return this.$store.state.platform
    }
    // pullingLandlordsData () {
    //   return this.$store.state.pullingLandlordsData
    // }
  },
  methods: {
    async logOutAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('logout'),
          message: this.$t('Are you sure?'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            },
            {
              text: this.$t('logout'),
              role: 'destructive',
              handler: () => {
                this.LogOut()
              }
            }
          ]
        })
      return alert.present()
    },
    LogOut () {
      // console.log('logout')
      if (this.platform.includes('capacitor')) {
        PushNotifications.removeAllListeners()
        BackgroundGeolocation.removeGeofences().then(success => {
          BackgroundGeolocation.stop()
          this.$store.dispatch('removeGeofences', { userId: this.userId })
        })
      }
      this.$store.dispatch('LogOut', { userId: this.userId }).then(() => {
        this.$router.replace({ path: 'signup' })
      })
    },
    editMyProfile (whichModal) {
      // let modal
      // if (whichModal === 'EditMyProfileModal') {
      //   modal = editMyProfileModal
      // }
      return modalController
        .create({
          component: editMyProfileModal,
          cssClass: 'my-profile-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openMyDevices () {
      return modalController
        .create({
          component: myDevicesModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    addDevices () {
      return modalController
        .create({
          component: addDevicesModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            roomId: null,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openFeedbackModal () {
      return modalController
        .create({
          component: feedbackModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openSettingsModal () {
      return modalController
        .create({
          component: settingsModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openHomeMembersModal () {
      return modalController
        .create({
          component: homeMembersModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openMyOrdersModal () {
      return modalController
        .create({
          component: myOrdersModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openMyProperties () {
      return modalController
        .create({
          component: myPropertiesModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    closeMenu () {
      menuController.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.sidemenu {
  height: 100%;
  width: 100%;
  --max-width: 70%;
  --background: #262C39;
  ion-menu {
    --background: #262C39 !important;
  }
  ion-header {
    ion-toolbar {
      padding: 10px 0;
      --border-style: transparent !important;
      ion-title {
        font-weight: 500;
        letter-spacing: 0.6px;
      }
    }
  }
  ion-header,
  ion-toolbar,
  ion-content,
  ion-list,
  ion-item,
  ion-icon,
  ion-title {
    --background: #262C39;
    color: #fff;
    margin: 0;
    padding: 0;
    border: none;
  }
  ion-item {
    --border-color: #e5e5e5;
    padding-bottom: 10px;
    --color-hover: #FFD4A4;
  }
  ion-label {
    margin-left: 20px;
    font-size: 16px;
    letter-spacing: 0.6px;
    font-weight: 400;
  }

  ion-icon {
    font-size: 24px;
    color: #d9e1d981;
  }
  ion-list {
    border-top: 1px solid rgba(194, 194, 194, 0.1);
    padding-top: 15px;
    margin: 0px auto;
  }
  ion-avatar {
    width: 42px;
    height: 42px;
    border: 2px solid #FFD4A4;
    border-radius: 50%;
  }
  .header-item {
    margin-top: 10px;
  }
  .header-label {
    margin-left: 10px;
  }

  .side-menu-image {
    width: 15%;
    display: inline-block;
  }

  .username {
    margin-left: 25px;
  }
  .center-absolute {
    position: absolute;
    bottom: 35px;
    left: 50%;
  }

  .brand-name {
    position: relative;
    left: -50%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.2);
  }
  .version {
    position: relative;
    left: -50%;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.2);
  }
  .active {
    color: #FFD4A4;
    --background: #ffd5a40f;
  }
}

@media (min-width: 993px) {
  .sidemenu {
  height: 100%;
  width: 100%;
  --max-width: 70%;
  --background: #181F30;
  ion-menu {
    --background: #181F30 !important;
  }
  ion-header {
    ion-toolbar {
      padding: 10px 0;
      --border-style: transparent !important;
      ion-title {
        font-weight: 500;
        letter-spacing: 0.6px;
      }
    }
  }
  ion-header,
  ion-toolbar,
  ion-content,
  ion-list,
  ion-item,
  ion-icon,
  ion-title {
    --background: #181F30;
    color: #fff;
    margin: 0;
    padding: 0;
    border: none;
  }
  ion-item {
    --border-color: #e5e5e5;
    padding-bottom: 10px;
    --color-hover: #FFD4A4;
  }
  ion-label {
    margin-left: 20px;
    font-size: 16px;
    letter-spacing: 0.6px;
    font-weight: 400;
  }

  ion-icon {
    font-size: 24px;
    color: #d9e1d981;
  }
  ion-list {
    border-top: 1px solid rgba(194, 194, 194, 0.1);
    padding-top: 15px;
    margin: 0px auto;
  }
  ion-avatar {
    width: 42px;
    height: 42px;
    border: 2px solid #FFD4A4;
    border-radius: 50%;
  }
  .header-item {
    margin-top: 10px;
  }
  .header-label {
    margin-left: 10px;
  }

  .side-menu-image {
    width: 15%;
    display: inline-block;
  }

  .username {
    margin-left: 25px;
  }
  .center-absolute {
    position: absolute;
    bottom: 35px;
    left: 50%;
  }

  .brand-name {
    position: relative;
    left: -50%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.2);
  }
  .version {
    position: relative;
    left: -50%;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.2);
  }
  .active {
    color: #FFD4A4;
    --background: #ffd5a40f;
  }
  }
}
.landlord-name {
  margin-top: 0px;
}
 @media (max-width: 500px) {
  .landlord-name {
    margin-top: 40px;
  }
}
</style>
