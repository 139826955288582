<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border edit-profile-modal">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        :disabled="loading"
        color="transparent"
        size="large"
        @click="dismissModal(); submitReason('')"
      >
        <!-- <ion-icon
          color="light"
          size="large"
          :icon="closeOutline"
        /> -->
        <img src="../../assets/images/close-outline-white.svg">
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div>
      <div>
        <transition-group
          name="list"
        >
          <div
            class="notification-outer"
          >
            <div>
              <ion-list
                class="text-left mb-10"
              >
                <ion-item
                  class="text--white ion-no-padding rounded-corners h-65"
                  lines="none"
                >
                  <ion-label>
                    <div class="text-18 text--gray">
                      <span
                        v-if="impNotification.type === 'deviceWater'"
                        class="mdi mdi-water text--primary"
                      />
                      <span
                        v-else-if="impNotification.type === 'deviceSmoke'"
                        class="mdi mdi-fire text--primary"
                      />
                      <span
                        v-else
                        class="mdi mdi-chat text--primary"
                      />

                      <span class="text--gray text-14 pl-5">{{ displayTime(impNotification.time) }}</span>
                      <span class="text--gray text-14 pl-5"> <span>-</span> {{ impNotification.propertyName }}</span>
                    </div>
                    <div
                      class="text-14 nowraptext"
                      style="margin-top: 6px;"
                    >
                      <span
                        v-if="impNotification.type !== 'alarmStatus'"
                        :class="impNotification.read ? '' : 'text--bold text--primary'"
                        style="transition: 0.1s"
                      >
                        {{ impNotification.warningMessage }} <span v-if="impNotification.type !== 'customimpNotification' && impNotification.type !== 'broadCast'">{{ $t('in') }} {{ propertyRoom(impNotification.propertyRoomId) }}</span>
                      </span>
                      <span v-else>
                        {{ impNotification.warningMessage }}
                      </span>
                    </div>
                  </ion-label>
                </ion-item>
              </ion-list>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="mt-10 text-left">
      <ion-row class="item">
        <ion-col
          size="6"
          style="padding-left: 0;"
        >
          <ion-button
            class="savebtn"
            color="primary"
            @click="submitReason(false)"
          >
            {{ $t('False alarm') }}
          </ion-button>
        </ion-col>
        <ion-col
          size="6"
        >
          <ion-button
            class="savebtn"
            color="primary"
            @click="submitReason(true)"
          >
            {{ $t('Real alarm') }}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div class="mt-30 text-left">
      <ion-button
        class="savebtn"
        color="light"
        :href="`tel:${landlordphone}`"
        @click="$event.stopPropagation()"
      >
        <span
          class="mdi mdi-phone-in-talk text-22"
        />
        &nbsp; {{ $t('Need Help') }}
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'
import { Keyboard } from '@capacitor/keyboard'
import * as moment from 'moment'

export default {
  props: {
    lock: { type: Object, default: () => {} },
    close: { type: Function, default: null },
    impNotification: { type: Object, default: () => {} }
  },
  data () {
    return {
      closeOutline,
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      moment: moment,
      selectedViewCondition: '1',
      errorMessage: '',
      reason: '',
      propertiesRooms: [],
      landlordphone: ''
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  watch: {
  },
  created () {
    // console.log(this.impNotification)
  },
  mounted () {
    this.getUserPropertiesRooms()
    this.getLandlordPhone(this.impNotification.propertyId)
  },
  methods: {
    closeKeyboard () {
      Keyboard.hide()
    },
    dismissModal () {
      this.close()
    },
    displayTime (date) {
      const momentObj = moment(date, 'DD/MM/YYYY HH:mm:ss')
      if (momentObj.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        return momentObj.fromNow()
      } else {
        return momentObj.format('DD/MM/YYYY hh:mm A')
      }
    },
    propertyRoom (roomId) {
      const rooms = this.propertiesRooms
      const room = rooms.find(element => element.roomId === roomId)

      return room ? room.roomName : 'N/A'
    },
    getUserPropertiesRooms () {
      this.$store.dispatch('getUserPropertiesRooms')
        .then(resp => {
          // console.log(resp)
          this.propertiesRooms = resp.data
        })
    },
    getLandlordPhone (propertyId) {
      this.$store.dispatch('getLandlordPhone', propertyId)
        .then(resp => {
          // console.log(resp.data.phone)
          this.landlordphone = resp.data.phone
        })
    },
    submitReason (status) {
      this.$store.dispatch('submitWaterleakFireReason', { id: this.impNotification.id, realAlarm: status })
        .then(resp => {
          // console.log(resp)
          this.close()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

ion-list {
  background-color: transparent;
}
ion-item {
  padding: 0 !important;
  margin-bottom: 5px;
  p{
    color:#D9E1D980 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
      span.mdi {
        color: #FFD4A4;
        font-size: 18px;
        margin-left: 0;
        margin-right: 5px;
        vertical-align: bottom;
      }
      span {
        font-size: 13px;
        margin-left: 4px;
      }
  }
  h3{
      color:#fff;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      margin-top:6px;
  }
}
ion-label {
  padding-left:15px;
}

ion-item-options{
    border-bottom-style:none;
    border-bottom-color:transparent;
}
ion-item-option {
    width: 25px !important;
}
ion-icon{
    --ion-color-base: #000000 !important;
    font-size:12px;
    width: 22px;
}

ion-header {
  color: white;
}
ion-segment {
  --background: transparent;
  min-height: 44px;

ion-segment-button {
  --color-checked: #{colors(primary, base)};
  --indicator-color: transparent !important;
  --indicator-color-checked: transparent !important;
  --indicator-box-shadow: transparent!important;
  --border-width:0px;
}
.segment-button-checked {
  font-weight: medium;
  position: relative;
  font-weight: medium;
}
.segment-button-checked::after{
  content : "";
  position: absolute;
  left: 25%;
  bottom: 0px;
  width: 50%;
  border-bottom : solid 3px colors(primary, base);
}
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 0.4s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateX(-400px);
}
.nowraptext span {
  white-space: break-spaces;
}
.h-65 {
   --min-height: 65px;
    border: 1px solid rgba(189, 189, 189, 0.2);
    --background: rgba(63, 66, 74, 0.8) !important;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 15%) !important;
}

.feedback-form {
  @include bg-box-room-device;
}
.form-inner {
    padding-left: 10px;
    padding-right: 10px;
}
.alert-checkbox-label.sc-ion-alert-ios {
  color: white!important;
}

.savebtn{
  --ion-color-contrast:#23262F !important;
  height:60px;
}
.helpbtn{
  --ion-color-contrast:#fff !important;
  height:60px;
}
</style>
