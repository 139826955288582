<template>
  <div :class="userRole === 'Landlord' ? 'background--modal-landlord' : 'background--modal'" />
  <ion-header class="ion-no-border edit-profile-modal">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        :disabled="loading"
        color="transparent"
        size="large"
        @click="dismissModal()"
      >
        <!-- <ion-icon
          color="light"
          size="large"
          :icon="closeOutline"
        /> -->
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title
        class="text--white text-18"
      >
        {{ $t('myProfileTitle') }}
      </ion-title>
      <ion-button
        v-if="editing"
        slot="end"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        :disabled="!validemail"
        @click="submit()"
      >
        <ion-spinner
          v-if="loading"
          color="light"
          name="crescent"
        />
        <ion-label
          v-else
          class="text--primary"
        >
          {{ $t('save') }}
        </ion-label>
      </ion-button>
      <ion-button
        v-else
        slot="end"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        @click="editInfo"
      >
        <ion-spinner
          v-if="loading"
          color="light"
          name="crescent"
        />
        <ion-label
          v-else
          class="text--primary"
        >
          {{ $t('edit') }}
        </ion-label>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-segment
      swipe-gesture="true"
      style="max-width: 350px;"
      :value="selectedView"
      @ionChange="selectedView = $event.target.value"
    >
      <ion-segment-button value="1">
        <ion-label
          class="text-16"
        >
          {{ $t('profile') }}
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="2">
        <ion-label
          class="text-16"
        >
          {{ $t('address') }}
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="3">
        <ion-label
          class="text-16"
        >
          {{ $t('password') }}
        </ion-label>
      </ion-segment-button>
    </ion-segment>
    <div class="ion-padding">
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="selectedView === '1'">
          <!--<ion-icon
            class="person-icon text--gray"
            :icon="personCircleOutline"
          />-->
          <div :class="keyboardStatus === 'closed' ? '' : 'keyboard-open'">
            <ion-avatar
              tappable
              class="person"
              @click="getPicture"
            >
              <div
                v-if="platform.includes('mobile')"
                class="upload-image text--primary"
              >
                <h6 class="material-icons camera">
                  photo_camera
                </h6>
              </div>

              <span
                v-if="!profileImage"
                class="material-icons"
              >
                person
              </span>
              <ion-img
                v-else
                :src="profileImage"
              />
            </ion-avatar>
          </div>
          <!-- <img src="../assets/images/account_circle.png"> -->
          <div
            class="form--dark ion-margin-top"
            :class="[keyboardStatus === 'closed' ? '' : 'form--dark-open-keyboard', platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md']"
          >
            <ion-item
              lines="none"
              class="rounded-corners-5"
            >
              <ion-label
                class="ion-no-padding"
                position="floating"
              >
                {{ $t('fullName') }}
              </ion-label>
              <ion-input
                v-model="fullName"
                enterkeyhint="done"
                :disabled="!editing"
                autocomplete="name"
                type="name"
                required
                @keydown.enter="closeKeyboard"
              />
            </ion-item>
            <ion-item
              lines="none"
              class="ion-margin-top rounded-corners-5"
            >
              <ion-label position="floating">
                {{ $t('emailAddress') }}
              </ion-label>
              <ion-input
                v-model="userEmail"
                :disabled="!editing"
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
                @ionChange="validateEmail($event.target.value)"
              />
            </ion-item>
            <ion-item
              lines="none"
              class="ion-margin-top rounded-corners-5"
            >
              <ion-label position="floating">
                {{ $t('phoneNumber') }}
              </ion-label>
              <ion-input
                v-model="userPhone"
                :disabled="!editing"
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
              />
            </ion-item>
          </div>
          <transition
            name="fade1"
            mode="out-in"
          >
            <ion-label v-if="message.message">
              <p :class="message.class">
                {{ message.message }}
              </p>
            </ion-label>
          </transition>
        </div>
        <div v-else-if="selectedView === '2'">
          <div
            class="form--dark"
            :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
          >
            <ion-item
              class="ion-margin-top rounded-corners-5"
              lines="none"
            >
              <ion-label position="floating">
                {{ $t('Address') }}
              </ion-label>
              <ion-input
                v-model="userStreet"
                :disabled="!editing"
                enterkeyhint="done"
              />
            </ion-item>
            <ion-row>
              <ion-col class="col-left">
                <ion-item
                  class="ion-margin-top rounded-corners-5"
                  lines="none"
                  style="margin-top:0;"
                >
                  <ion-label position="floating">
                    {{ $t('city') }}
                  </ion-label>
                  <ion-input
                    v-model="userCity"
                    :disabled="!editing"
                    enterkeyhint="done"
                  />
                </ion-item>
              </ion-col>
              <ion-col class="col-right">
                <ion-item
                  class="ion-margin-top rounded-corners-5"
                  lines="none"
                  style="margin-top:0;"
                >
                  <ion-label position="floating">
                    {{ $t('PostCode') }}
                  </ion-label>
                  <ion-input
                    v-model="userPostCode"
                    :disabled="!editing"
                    enterkeyhint="done"
                  />
                </ion-item>
              </ion-col>
              <!-- <ion-col class="col-right">
                <ion-item
                  class="ion-margin-top rounded-corners-5"
                  lines="none"
                  @click="changeAddress"
                >
                  <ion-label position="floating">
                    Apartment Nr.
                  </ion-label>
                  <ion-input
                    v-model="userPostCode"
                    disabled
                  />
                </ion-item>
              </ion-col> -->
            </ion-row>
            <ion-item
              class="ion-margin-top rounded-corners-5"
              lines="none"
              style="margin-top:0;"
            >
              <ion-label position="floating">
                {{ $t('Country') }}
              </ion-label>
              <ion-input
                v-model="userCountry"
                :disabled="!editing"
                enterkeyhint="done"
              />
            </ion-item>
          </div>
          <!-- <div class="note-text">
            <p>Note: Please contact tech support to change address.</p>
          </div> -->
          <transition
            name="fade1"
            mode="out-in"
          >
            <ion-label v-if="message.message">
              <p :class="message.class">
                {{ message.message }}
              </p>
            </ion-label>
          </transition>
        </div>
        <div v-else>
          <div
            class="form--dark"
            :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
          >
            <ion-item
              class="ion-margin-top rounded-corners-5"
              lines="none"
            >
              <ion-label position="floating">
                {{ $t('Current password') }}
              </ion-label>
              <ion-input
                v-model="currentPassword"
                :disabled="!editing"
                :type="passwordInputModeVisible1 ? 'text' : 'password'"
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
              />
              <div
                slot="end"
                @click="passwordInputModeVisible1 = !passwordInputModeVisible1"
              >
                <i
                  class="mdi pwd-icon"
                  :class="[passwordInputModeVisible1 ? 'mdi-eye' : 'mdi-eye-off', !editing ? 'gray': '']"
                />
              </div>
            </ion-item>
            <ion-item
              class="ion-margin-top rounded-corners-5"
              lines="none"
            >
              <ion-label position="floating">
                {{ $t('New Password') }}
              </ion-label>
              <ion-input
                v-model="newPassword"
                :disabled="!editing"
                :type="passwordInputModeVisible2 ? 'text' : 'password'"
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
              />
              <div
                slot="end"
                @click="passwordInputModeVisible2 = !passwordInputModeVisible2"
              >
                <i
                  class="mdi pwd-icon"
                  :class="[passwordInputModeVisible2 ? 'mdi-eye' : 'mdi-eye-off', !editing ? 'gray': '']"
                />
              </div>
            </ion-item>
            <ion-item
              class="ion-margin-top rounded-corners-5"
              lines="none"
            >
              <ion-label position="floating">
                {{ $t('Confirm New Password') }}
              </ion-label>
              <ion-input
                v-model="repeateNewPassword"
                :disabled="!editing"
                :type="passwordInputModeVisible3 ? 'text' : 'password'"
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
              />
              <div
                slot="end"
                @click="passwordInputModeVisible3 = !passwordInputModeVisible3"
              >
                <i
                  class="mdi pwd-icon"
                  :class="[passwordInputModeVisible3 ? 'mdi-eye' : 'mdi-eye-off', !editing ? 'gray': '']"
                />
              </div>
            </ion-item>
            <password-meter
              :password="newPassword"
              @score="passwordScore = $event.score"
            />
          </div>
          <div
            style="min-height: 20px;"
            class="mt-10"
          >
            <transition
              name="fade1"
              mode="out-in"
            >
              <ion-label v-if="message.message">
                <p :class="message.class">
                  {{ message.message }}
                </p>
              </ion-label>
            </transition>
          </div>
          <ion-button
            color="transparent"
            fill="clear"
            class="forgotpassword"
            @click="forgotMyPassword"
          >
            <span class="text--primary">{{ $t('Forgot Password?') }}</span>
          </ion-button>
        </div>
      </transition>
    </div>
  </ion-content>
</template>

<script>
import { toastController } from '@ionic/vue'
import { closeOutline, checkmarkDoneOutline, personCircleOutline, alertOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Camera } from '@ionic-native/camera'
import { Keyboard } from '@capacitor/keyboard'
import PasswordMeter from 'vue-simple-password-meter'

import $socket from '../lib/socketIo'

export default defineComponent({
  name: 'EditMyProfile',
  components: {
    PasswordMeter
  },
  props: {
    modalTitle: { type: String, default: 'Modal' },
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      checkmarkDoneOutline,
      personCircleOutline,
      passwordInputModeVisible1: false,
      passwordInputModeVisible2: false,
      passwordInputModeVisible3: false,
      selectedView: '1',
      loading: false,
      editing: false,
      name: '',
      email: '',
      validemail: true,
      phone: '',
      country: '',
      city: '',
      street: '',
      propertyNumber: '',
      flatNumber: '',
      postCode: '',
      currentPassword: '',
      newPassword: '',
      repeateNewPassword: '',
      passwordScore: 0,
      message: {},
      userId: this.$store.state.userId
    }
  },
  computed: {
    keyboardStatus () {
      return this.$store.state.keyboardStatus
    },
    platform () {
      // console.log(this.$store.state.platform)
      return this.$store.state.platform
    },
    profileImage () {
      return this.$store.state.profileImage
    },
    fullName: {
      get () {
        return this.$store.state.userData[0].name
      },
      set (val) {
        this.name = val
      }
    },
    userEmail: {
      get () {
        return this.$store.state.userData[0].email
      },
      set (val) {
        this.email = val
      }
    },
    userPhone: {
      get () {
        return this.$store.state.userData[0].phone
      },
      set (val) {
        this.phone = val
      }
    },
    userCountry: {
      get () {
        return this.$store.state.userData[0].myAddress.country
      },
      set (val) {
        this.country = val
      }
    },
    userCity: {
      get () {
        return this.$store.state.userData[0].myAddress.city
      },
      set (val) {
        this.city = val
      }
    },
    userStreet: {
      get () {
        return this.$store.state.userData[0].myAddress.street
      },
      set (val) {
        this.street = val
      }
    },
    userPropertyNumber: {
      get () {
        return this.$store.state.userData[0].myAddress.propertyNumber
      },
      set (val) {
        this.propertyNumber = val
      }
    },
    userFlatNumber: {
      get () {
        // console.log(this.$store.state.userData[0].myAddress.flatNumber)
        return this.$store.state.userData[0].myAddress.flatNumber
      },
      set (val) {
        this.flatNumber = val
      }
    },
    userPostCode: {
      get () {
        return this.$store.state.userData[0].myAddress.postCode
      },
      set (val) {
        this.postCode = val
      }
    },
    userRole () {
      return this.$store.state.userRole
    }
  },
  methods: {
    dismissModal () {
      this.close()
    },
    validateEmail (text) {
      if (this.selectedView === '1') {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
          this.validemail = true
        } else {
          this.validemail = false
        }
      } else {
        this.validemail = true
      }
    },
    editInfo () {
      if (this.selectedView === '1' || this.selectedView === '3') {
        this.editing = true
      } else if (this.selectedView === '2' && this.$store.state.userRole === 'Home Owner') {
        this.editing = true
      } else {
        // this.message = { message: this.$t('Please contact tech support to change address'), class: 'success' }
        // setTimeout(() => { this.message = {} }, 2000)
        this.presentToast(this.$t('Please contact tech support to change address'), 'action-toast-success', alertOutline)
      }
    },
    takePicture () {
      const options = {
        quality: 100,
        destinationType: Camera.DestinationType.FILE_URI,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE
      }
      Camera.getPicture(options).then((imageData) => {
        this.base64Image = 'data:image/jpeg;base64,' + imageData
      }, (err) => {
        console.log(err)
      })
    },
    async getPicture () {
      const options = {
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
        correctOrientation: true,
        quality: 5,
        allowEdit: true
      }
      Camera.getPicture(options).then(async (imageData) => {
        // console.log(imageData)
        this.$store.dispatch('newProfileImage', { profileImage: 'data:image/jpeg;base64,' + imageData })
      }, (err) => {
        console.log(err)
      })
    },
    async submit () {
      this.loading = true
      if (this.selectedView === '1') {
        const profileUpdate = {
          name: this.name ? this.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : this.$store.state.userData[0].name,
          email: this.email ? this.email : this.$store.state.userData[0].email,
          phone: this.phone ? this.phone : this.$store.state.userData[0].phone
        }
        profileUpdate.name = profileUpdate.name.trim()
        profileUpdate.email = profileUpdate.email.trim()
        profileUpdate.phone = profileUpdate.phone.trim()
        await this.$store.dispatch('updateUserProfile', profileUpdate).then(response => {
          if (response) {
            this.presentToast(this.$t('Profile updated successfully'), 'action-toast-success', checkmarkDoneOutline)
            this.loading = false
            this.editing = false
            this.$store.dispatch('getUserData')
              .then(reps => console.log('Getting data OK'))
              .catch(err => {
                this.message = { message: String(err), class: 'error' }
                setTimeout(() => { this.message = {} }, 2000)
              })
          }
        }).catch(error => {
          console.log(error)
          this.presentToast(this.$t('Something has failed please try that again'), 'action-toast-error', alertOutline)
          this.loading = false
          this.editing = false
        })
      }
      if (this.selectedView === '2') {
        if (this.$store.state.userRole !== 'Home Owner') {
          this.loading = false
          this.editing = false
          this.presentToast(this.$t('Please contact tech support to change address'), 'action-toast-success', alertOutline)
        } else {
          const addressUpdate = {
            propertyId: this.$store.state.currentPropertySelected,
            street: this.street.trim() ? this.street.trim() : null,
            country: this.country.trim() ? this.country.trim() : null,
            city: this.city.trim() ? this.city.trim() : null,
            postCode: this.postCode.trim() ? this.postCode.trim() : null
          }
          await this.$store.dispatch('updateUserAddress', addressUpdate).then(response => {
            if (response) {
              this.presentToast(this.$t('Profile updated successfully'), 'action-toast-success', checkmarkDoneOutline)
              this.loading = false
              this.editing = false
              this.$store.dispatch('getUserData')
                .then(reps => console.log('Getting data OK'))
                .catch(err => {
                  this.message = { message: String(err), class: 'error' }
                  setTimeout(() => { this.message = {} }, 2000)
                })
            }
          }).catch(error => {
            console.log(error)
            this.presentToast(this.$t('Something has failed please try that again'), 'action-toast-error', alertOutline)
            this.loading = false
            this.editing = false
          })
        }
      }
      if (this.selectedView === '3') {
        if (!this.currentPassword || !this.newPassword || !this.repeateNewPassword) {
          this.presentToast(this.$t('Some fields are empty'), 'action-toast-error', alertOutline)
          this.loading = false
        } else if (this.newPassword !== this.repeateNewPassword) {
          this.presentToast(this.$t('Password does not match'), 'action-toast-error', alertOutline)
          this.loading = false
        } else if (this.passwordScore <= 2) {
          this.presentToast(this.$t('Password is too weak'), 'action-toast-error', alertOutline)
          this.loading = false
        } else if (this.currentPassword === this.newPassword) {
          this.presentToast(this.$t('You have to use different password than your old one!'), 'action-toast-error', alertOutline)
          this.loading = false
        } else {
          const updatePassword = {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword
          }
          await this.$store.dispatch('updateUserPassword', updatePassword)
            .then(response => {
              // console.log(response)
              if (response) {
                this.currentPassword = ''
                this.newPassword = ''
                this.repeateNewPassword = ''
                this.presentToast(this.$t('Password updated successfully'), 'action-toast-success', checkmarkDoneOutline)
                this.loading = false
                this.editing = false
              }
            }).catch(error => {
              console.log(error)
              this.presentToast(this.$t('Something has failed please try that again'), 'action-toast-error', alertOutline)
              this.loading = false
            })
        }
      }
    },
    async presentToast (message, css, icon) {
      const toast = await toastController.create({
        message: message,
        duration: 1000,
        position: 'bottom',
        icon: icon,
        cssClass: css
      })

      await toast.present()
    },
    async forgotMyPassword () {
      this.$store.dispatch('LogOut', { userId: this.userId }).then(() => {
        this.dismissModal()
        this.$router.replace('/resetpassword')
        $socket.disconnect()
      })
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
// @import '~@/theme/_backgrounds';

ion-item {
  // @include bg-box-item;
  height: 50px;
}
.forgotpassword {
  margin-left: auto;
margin-right: auto;
text-align: center;
}
.forgotpassword a{
  text-align: center;
  text-decoration: none;
  color: #ffd4a4;
  letter-spacing: 0.8px;
}
.error {
  color: #eb445a;
}
.success {
  color: #ffd4a4;
}
.gray {
  color: rgb(70, 70, 70);
}
.fade1-enter-active, .fade1-leave-active {
  transition: opacity .2s;
}
.fade1-enter, .fade1-leave-to {
  opacity: 0;
}
.keyboard-open {
  transform: translateY(-150px);
  opacity: 0;
  transition: 0.4s;
  z-index: -1000;
}
.form--dark-open-keyboard {
    transition: 0.4s;
    transform: translateY(-150px);
}
//Form Field Error
.errorfield {
  border: 1px solid #f65862;
}
</style>
