import { App } from '@capacitor/app'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { trackRouter } from 'vue-gtag-next'

import store from '../store'

// import { getPlatforms } from '@ionic/vue'

const routes = [
  {
    path: '/open-e-shop',
    name: 'openEShop',
    component: () => import('../views/EShop.vue')
  },
  {
    path: '/e-shop-success/:id',
    name: 'eShopSuccess',
    component: () => import('../components/Eshop/Success.vue')
  },
  {
    path: '/e-shop-cancel/:id',
    name: 'eShopCancel',
    component: () => import('../components/Eshop/Cancel.vue')
  },
  {
    path: '/control-lock/:token',
    name: 'ControlLock',
    component: () => import('../views/ControlLock.vue')
  },
  {
    path: '/payment-method-update-success/:id',
    name: 'eShopPaymentMethodUpdateSuccess',
    component: () => import('../components/Eshop/PaymentMethodSuccess.vue')
  },
  {
    path: '/payment-method-update-cancel/:id',
    name: 'eShopPaymentMethodUpdateCancel',
    component: () => import('../components/Eshop/PaymentMethodCancel.vue')
  },
  {
    path: '/web',
    name: 'Web',
    redirect: '/main-view'
  },
  {
    path: '/landlord-registration/:token',
    name: 'LandlordRegistration',
    component: () => import('../views/LandlordRegistration.vue')
  },
  {
    path: '/complete-registration',
    name: 'CompleteRegistration',
    component: () => import('../views/CompleteRegistration.vue')
  },
  {
    path: '/complete-registration/:token',
    name: 'CompleteRegistrationWithToken',
    component: () => import('../views/CompleteRegistration.vue')
  },
  {
    path: '/accept-invitation/:token',
    name: 'AcceptInvitation',
    component: () => import('../views/AcceptInvitation.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPasswordSecondStep',
    component: () => import('../views/ResetPasswordSecondStep.vue')
  },
  {
    path: '/help-with-gateway',
    name: 'GetHelpWithMyGateway',
    component: () => import('../views/GetHelpWithMyGateway.vue'),
    meta: {
      requiresUser: true
    }
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    component: () => import('../views/HelpCenter.vue'),
    meta: {
      requiresUser: true
    }
  },
  {
    path: '/add-property',
    name: 'AddProperty',
    component: () => import('../views/LandlordAddProperty.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/property-managers',
    name: 'PropertyManagers',
    component: () => import('../views/LandlordPropertyManagers.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/global-settings',
    name: 'GlobalSettings',
    component: () => import('../views/LandlordGlobalSettings.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/landlord-settings',
    name: 'LandlordSettings',
    component: () => import('../views/LandlordSettings.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/landlord-alarms',
    name: 'LandlordAlarms',
    component: () => import('../views/LandlordAlarms.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/landlord-properties',
    name: 'LandlordProperties',
    component: () => import('../views/LandlordProperties.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/landlord-properties/:id',
    name: 'LandlordPropertyDetail',
    component: () => import('../views/LandlordPropertyDetail.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/statistics',
    name: 'LandlordStatistics',
    component: () => import('../views/LandlordStatistics.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/events',
    name: 'LandlordEvents',
    component: () => import('../views/LandlordEvents.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../views/LandlordNotifications.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/detail/:id',
    name: 'LandlordApartmentDetail',
    component: () => import('../views/LandlordApartmentDetail.vue'),
    meta: {
      requiresLandlord: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      redirectWhenAuth: true
    }
  },
  {
    path: '/login/:type',
    name: 'LoginWithProp',
    component: () => import('../views/Login.vue'),
    meta: {
      redirectWhenAuth: true
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: {
      redirectWhenAuth: true
    }
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      redirectWhenAuth: true
    }
  },
  {
    path: '/app-intro',
    name: 'AppIntro',
    component: () => import('../views/AppIntroductionAndSetup.vue'),
    meta: {
      requiresUser: true
    }
  },
  {
    path: '/',
    redirect: '/main-view'
  },
  {
    path: '/',
    name: 'MainPage',
    meta: {
      requiresUser: true
    },
    component: () => import('../views/Tabs.vue'),
    children: [
      {
        path: 'main-view',
        name: 'MainView',
        meta: {
          requiresUser: true
        },
        component: () => import(/* webpackChunkName: "user" */'../views/MainView.vue')
      },
      {
        path: '/app-introduction-and-gateway-setup',
        name: 'AppIntroductionAndGatewaySetup',
        component: () => import('../views/AppIntroductionAndGatewaySetup.vue'),
        meta: {
          requiresUser: true
        }
      },
      {
        path: 'e-shop',
        name: 'eShop',
        meta: {
          requiresUser: true
        },
        component: () => import('../views/EShop.vue')
      },
      {
        path: 'e-shop/:sku',
        name: 'eShopWithSku',
        meta: {
          requiresUser: true
        },
        component: () => import('../views/EShop.vue')
      },
      {
        path: 'security',
        name: 'Security',
        meta: {
          requiresUser: true
        },
        component: () => import('../views/Security.vue')
      },
      {
        path: 'security/:target',
        name: 'SecurityWithTarget',
        meta: {
          requiresUser: true
        },
        component: () => import('../views/Security.vue')
      },
      {
        path: 'my-account',
        name: 'MyAccount',
        meta: {
          requiresUser: true
        },
        component: () => import('../views/MyAccount.vue')
      },
      {
        path: 'automation',
        name: 'Automation',
        meta: {
          requiresUser: true
        },
        component: () => import('../views/Automation.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// const platform = getPlatforms()
// let currentPlatform = ''
// if (platform.includes('capacitor')) {
//   currentPlatform = 'mobile'
// } else {
//   currentPlatform = 'web'
// }

trackRouter(router)

App.addListener('appUrlOpen', function (data) {
  const slug = data.url.split('.se').pop()

  if (slug) {
    router.push({
      path: slug
    })
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.redirectWhenAuth)) {
    if (store.getters.loggedIn) {
      next({
        path: '/main-view'
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresUser)) {
    if (!store.getters.loggedIn) {
      store.dispatch('LogOut', { userId: store.state.userId })
      next({
        path: '/signup'
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresLandlord)) {
    if (store.state.userRole !== 'Landlord') {
      next({
        path: '/main-view'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
