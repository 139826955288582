import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import tooltip from './directives/tooltip.js'
import './directives/tooltips.css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'

import { IonicVue, createAnimation } from '@ionic/vue'
import * as IonComponents from '@ionic/vue'

import VueSocketIOExt from 'vue-socket.io-extended'
import $socket from './lib/socketIo'

import VueGtag from 'vue-gtag-next'

import { createI18n, useI18n } from 'vue-i18n'

import Toaster from '@meforma/vue-toaster'
import VueTippy from 'vue-tippy'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-toward.css'

import VueLazyLoad from 'vue3-lazyload'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
import '@mdi/font/css/materialdesignicons.css'

/* Theme variables */
import './theme/variables.scss'
import './theme/style.scss'

/* SW */
import './registerServiceWorker'

import VueGoogleMaps from '@fawmi/vue-google-maps'
import Vue3TouchEvents from 'vue3-touch-events'

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: 'SE',
  messages: loadLocaleMessages()
})
const navTransition = (baseEl, opts) => {
  const { enteringEl, leavingEl } = opts

  const enteringPage = createAnimation('entering-page-animation')
    .addElement(enteringEl)
    // .fromTo('opacity', 0, 1)
    .fromTo('transform', 'translateX(-1000px)', 'translateX(0px)')
    .keyframes([
      { offset: 0, opacity: 0 },
      { offset: 0.5, opacity: 0.5 },
      { offset: 1, opacity: 1 }
    ])

  const leavingPage = createAnimation('leaving-page-animation')
    .addElement(leavingEl)
    .fromTo('opacity', 1, 0)
    // .fromTo('transform', 'translateX(0%)', 'translateX(-100%)')
    .keyframes([
      { offset: 0, opacity: 1 },
      { offset: 0.5, opacity: 0.5 },
      { offset: 1, opacity: 0 }
    ])

  return createAnimation('root-transition')
    .duration(250)
    .easing('ease-in-out')
    .addAnimation([enteringPage, leavingPage])
}

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value()
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
    document.addEventListener('touch', el.clickOutsideEvent)
  },
  unmounted: el => {
    document.removeEventListener('click', el.clickOutsideEvent)
    document.removeEventListener('touch', el.clickOutsideEvent)
  }
}
const app = createApp(App, {
  setup () {
    const { t, locale } = useI18n()
    return { t, locale }
  }
})
  .directive('click-outside', clickOutside)
  .use(IonicVue, {
    animated: true,
    navAnimation: navTransition,
    swipeBackEnabled: false
  })
  .use(router)
  .use(store)
  .use(VueSocketIOExt, $socket, { store })
  .use(i18n)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBIOx8K_vZpx-NO7XCo4zCDhCFSwoNXIBQ'
    }
  })
  .use(Vue3TouchEvents)
  .use(Toaster, {
    position: 'top-right',
    duration: false
  })
  .use(
    VueTippy,
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
      theme: 'light',
      defaultProps: { placement: 'auto-start', theme: 'light', animation: 'shift-toward', arrow: true, maxWidth: 450 }
    }
  )
  .use(VueGtag, {
    property: {
      id: 'UA-216509643-1'
    }
  })
  .use(VueLazyLoad, {})
router.isReady().then(() => {
  app.directive('tooltip', tooltip)
  app.mount('#app')
})

/* Global Components Registration --- ** TO-DO ** THIS HAS TO BE REMOVED AND EACH COMPONENT SHOULD HAVE EACH COMPONENT IMPORTED SEPARATELY */
Object.keys(IonComponents).forEach(key => {
  if (/^Ion[A-Z]\w+$/.test(key)) {
    app.component(key, IonComponents[key])
  }
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401 && router.currentRoute.value.name !== 'Login' && router.currentRoute.value.name !== 'LoginWithProp' && router.currentRoute.value.name !== 'SignUp') {
    console.log('logging out')
    store.dispatch('LogOut', { userId: store.state.userId }).then(() => {
      router.push('/login/sessionExpired')
    })
  } else {
    return Promise.reject(error)
  }
})