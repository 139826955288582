<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === 1"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView--"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title class="text--white text-18 text--400 text-center">
        {{ $t('Smart Lock Access') }}
      </ion-title>
      <ion-button
        v-if="selectedView === 1"
        slot="end"
        debounce="500"
        :disabled="saving || !newKey.keyName.length"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="createKey"
      >
        <ion-text
          class="text-16"
          color="primary"
        />
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="text-left create-scene">
    <div
      v-if="selectedView === 1"
      class="ion-margin-top"
    >
      <div class="ion-padding-start ion-padding-end">
        <div
          class="mt-30"
          align="center"
        >
          <div @click="editKeyModal({userId: null, userName: '', ieee: lock.ieee, keyName: newKey.keyName, accessType: 'always', id: 0, appliedDays: []})">
            <ion-icon
              class="add-icon rounded-corners-20"
              :icon="addOutline"
            />
          </div>
          <ion-label
            class="text-center addbtn"
            style="color: rgba(217, 225, 217, 0.4);"
          >
            {{ $t('Invite guest') }}
          </ion-label>
        </div>
        <div class="text-left mt-30">
          <ion-text class="text--white text-16 text-bold">
            {{ $t('Users') }}
          </ion-text>
        </div>
        <ion-list>
          <div
            class="security-devices"
          >
            <ion-item
              v-for="key in stateSmartLockKeys"
              :key="key.ieee"
              :value="key.ieee"
              class="h-100"
              lines="none"
              button
              detail="false"
              @click="presentActionSheet(key)"
            >
              <ion-avatar class="usrpic ml-10">
                <img src="../../assets/images/default.png">
              </ion-avatar>
              <ion-label class="text--white ml-20">
                <p style="color: white;">
                  {{ key.userName }}
                </p>
                <p style="color: #D9E1D980;">
                  {{ key.userId === null ? $t('guest') : $t('member') }}, {{ $t(key.accessType) }}
                </p>
              </ion-label>
              <i
                class="mdi mdi-chevron-right text--white text-16 mr-10"
              />
            </ion-item>
          </div>
        </ion-list>
      </div>
    </div>
    <div
      v-if="selectedView === 2"
      class="ion-margin-top"
    >
      <form
        class="form--dark"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <div class="ion-padding-start ion-padding-end">
          <ion-item
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Name of the door') }}
            </ion-label>
            <ion-input
              v-model="newKey.keyName"
              type="text"
              class="text--capitalize"
              required
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-item
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Start date and time') }}
            </ion-label>
            <ion-datetime
              v-model="newKey.fromDate"
              display-format="D MMM YYYY, H:mm"
              :min="todayDateTime"
              max="2030"
              class=""
            />
          </ion-item>
          <ion-item
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('End date and time') }}
            </ion-label>
            <ion-datetime
              v-model="newKey.toDate"
              display-format="D MMM YYYY, H:mm"
              :min="todayDateTime"
              max="2030"
              class=""
            />
          </ion-item>
        </div>
        <div
          class="ion-padding-start ion-padding-end"
        >
          <ion-item
            id="limittoggle"
            class="ion-no-padding"
            style="margin-top: -10px;"
            lines="none"
            color="transparent"
          >
            <ion-text
              slot="start"
              class="text--white text-14"
            >
              {{ $t('LIMIT USES') }}
            </ion-text>
            <ion-toggle
              slot="end"
              v-model="newKey.limitUses"
            />
          </ion-item>
          <div v-if="newKey.limitUses">
            <ion-row>
              <ion-col size="4">
                <ion-item
                  lines="none"
                >
                  <ion-label position="floating">
                    {{ $t('No of uses') }}
                  </ion-label>
                  <ion-input
                    v-model="newKey.numOfUses"
                    type="number"
                    class="text--capitalize"
                    enterkeyhint="done"
                    @keydown.enter="closeKeyboard"
                  />
                </ion-item>
              </ion-col>
              <ion-col size="8">
                <ion-item
                  lines="none"
                >
                  <ion-label position="floating">
                    {{ $t('Limitation') }}
                  </ion-label>
                  <ion-select
                    v-model="newKey.limitTerm"
                  >
                    <ion-select-option
                      v-for="limit in limitTerms"
                      :key="limit.id"
                      :value="limit.value"
                    >
                      {{ limit.name }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </form>
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline,
  chevronBackOutline,
  addOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import {
  IonDatetime,
  alertController,
  actionSheetController,
  modalController
} from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'

import { v4 as uuid } from 'uuid'
import { Share } from '@capacitor/share'

import _ from 'lodash'
import dayjs from 'dayjs'

import editKeyModal from './EditKey'
import doneSharing from './DoneSharing'

export default defineComponent({
  components: { IonDatetime },
  props: {
    lock: { type: Object, default: () => {} },
    close: { type: Function, default: null }
  },
  data () {
    return {
      lodash: _,
      closeOutline,
      addCircleOutline,
      chevronBackOutline,
      addOutline,
      selectedView: 1,
      selectedViewDetail: 1,
      selectedViewSub: 1,
      saving: false,
      todayDateTime: 0,
      tomorrowDateTime: 0,
      limitTerms: [
        { id: 1, value: 'daily', name: 'Per Day' },
        { id: 2, value: 'weekly', name: 'Per Week' },
        { id: 3, value: 'monthly', name: 'Per Month' }
      ],
      newKey: {
        id: '',
        ieee: '',
        userId: null,
        userName: '',
        keyName: '',
        keyToken: '',
        fromDate: '',
        toDate: '',
        accessType: 'no_access',
        startTime: '15:00',
        startDate: '1/1/2000',
        endTime: '15:45',
        endDate: '1/1/2000',
        appliedDays: []
      },
      dismisstxt: '',
      accessKeys: [],
      mykey: { accessType: 'no_access', userName: 'Me Here', userId: '123' }
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    propertyId () {
      return this.$store.state.currentPropertySelected
    },
    stateSmartLockKeys () {
      return this.$store.state.smartLockKeys
    }
  },
  watch: {
  },
  created () {
    this.newKey.ieee = this.lock.ieee
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    this.todayDateTime = today.toISOString()
    this.tomorrowDateTime = tomorrow.toISOString()
    this.newKey.keyName = this.lock.deviceName
    this.newKey.fromDate = this.todayDateTime
    this.newKey.toDate = this.tomorrowDateTime
    // console.log(this.lock)

    this.dismisstxt = this.$t('Dismiss')

    this.listSmarLockKeys()
  },
  methods: {
    createKey () {
      if (this.saving) return

      this.saving = true
      this.newKey.keyName = this.newKey.keyName.trim()
      this.newKey.keyToken = uuid()
      this.$store.dispatch('createSmartLockKey', { newKey: this.newKey }).then(resp => {
        console.log(resp.data.keyToken)
        this.showWarning(resp.data)
        this.dismissModal()
        this.saving = false
      })
      // this.showShare()
    },
    listSmarLockKeys () {
      // console.log(this.propertyId, this.newKey.ieee)
      this.$store.dispatch('listSmartLockKeys', { propertyId: this.propertyId, ieee: this.newKey.ieee }).then(resp => {
      })
    },
    dismissModal () {
      this.close()
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    async showWarning (key) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Varning'),
          message: this.$t('You are about the share your door lock key. Are you sure you want to proceed?'),
          buttons: [
            {
              text: this.$t('Ja'),
              cssClass: 'secondary',
              handler: () => {
                this.canShare(key)
              }
            },
            {
              text: this.$t('Avbryt'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('')
              }
            }
          ]
        })
      await alert.present()
    },
    async canShare (key) {
      // console.log(this.getKeyAccess(key))
      await Share.canShare().then(resp => {
        if (resp.value) {
          this.showShare(key)
        } else {
          console.log('cannot share')
          this.errorAlert(this.$t('Share API not available in this browser'))
        }
      })
    },
    async showShare (key) {
      const sharingTxt = this.getKeyAccess(key)
      await Share.share({
        title: this.$t('Homevision Key'),
        text: sharingTxt,
        url: 'https://app.homevision.se/control-lock/' + key.keyToken,
        dialogTitle: this.$t('Homevision Key')
      })
        .then(resp => {
          this.dismissModal()
          this.doneSharing()
          // alert(JSON.stringify(resp))
        })
        .catch((error) => console.log('Error sharing ::: ', error))
    },
    async errorAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: [this.dismisstxt]
        })
      await alert.present()
    },
    async presentActionSheet (key) {
      const arrbtns = [
        {
          text: this.$t('Remove access'),
          role: 'destructive',
          handler: () => {
            this.$store.dispatch('deleteSmartLockKey', key).then(response => {
              this.listSmarLockKeys()
            })
          }
        },
        {
          text: this.$t('Edit access'),
          handler: () => {
            this.editKeyModal(key)
          }
        },
        {
          text: this.$t('Re-invite'),
          handler: () => {
            this.showWarning(key)
          }
        },
        {
          text: this.$t('Cancel'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          }
        }
      ]
      if (key.userId) {
        arrbtns.splice(0, 1)
        arrbtns.splice(1, 1)
      }
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: arrbtns
        })
      await actionSheet.present()

      // const { role } = await actionSheet.onDidDismiss()
      // console.log('onDidDismiss resolved with role', role)
    },
    editKeyModal (key) {
      // console.log('in share: key')
      // console.log(key)
      return modalController
        .create({
          component: editKeyModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            lock: key,
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    doneSharing () {
      return modalController
        .create({
          component: doneSharing,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    getKeyAccess (key) {
      let accessStr = this.$t('Hi') + ' ' + key.userName + ', \r\n\r\n'
      accessStr += this.$t('Here is your digital key!') + ' ' + this.$t('Use the link for access.')
      accessStr += '\r\n\r\n'
      accessStr += this.$t('Access Level') + ': ' + this.$t(key.accessType) + '\r\n'
      if (key.accessType === 'temporary') {
        const dayfrom = dayjs(key.startDate)
        const dayto = dayjs(key.endDate)
        accessStr += this.$t('App Access') + ': ' + dayfrom.format('MMM D, YYYY HH:mm') + ' - ' + dayto.format('MMM D, YYYY HH:mm')
      }
      if (key.accessType === 'recurring') {
        const dayfrom = dayjs(key.startDate)
        const dayto = dayjs(key.endDate)
        let days = ''
        key.appliedDays.forEach(item => {
          days += ', ' + this.$t(item)
        })
        accessStr += this.$t('App Access') + ': ' + dayfrom.format('HH:mm') + '-' + dayto.format('HH:mm') + days
      }
      return accessStr
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.remove-device {
  .alert-wrapper {
    .alert-title {
      font-size: 16px !important;
      letter-spacing: 0.5px !important;
    }
  }
}
.no-ripple {
     --ripple-color: transparent;
    --background-activated: transparent;
}

.device{
    height: 180px;
    @include bg-box-room-device;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
  h3{
    margin: 0;
    margin-top:10px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
}

.link-disabled {
  filter: opacity(40%);
}
.saveicon{
  font-size:22px;
}
ion-datetime{
  --padding-top: 2px;
}
.form--dark ion-item#limittoggle{
  margin:20px 0 0 0 !important;
  --min-height: 20px;
}
.form--dark ion-item#limittoggle ion-toggle{
  padding:0px;
}

.add-icon {
  font-size: 24px;
  color: colors(primary, base);
  padding: 7px 17px 7px 17px;
  background: rgba(120, 120, 128, 0.32);
}
.addbtn{
  display:block;
}

.security-devices ion-item{
  --background: #3F424A;
  background: #3F424A;
  padding:5px 0;
  border-radius: 10px;
  height:65px;
  margin-top:10px;
  color:#fff;
  border: 1px solid #636363;
}
.security-devices{
  margin-top:10px;
}
.security-devices ion-text{
  letter-spacing: 0.8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.security-devices ion-item  span{
  color: #fff;
  padding: 6px;
  border-radius: 15px;
  font-size:12px;
  width: 43px;
  height: 26px;
  text-align: center;
  background: rgba(120, 120, 128, 0.32);
}

.security-devices ion-label{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.security-devices ion-label.leftmarg{
  margin-left:12px;
}
.usrpic{
  width:30px;
  height:auto;
}
</style>
