<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border edit-profile-modal">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === '1'"
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        @click="dismissModal()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        @click="selectedView = '1'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title
        v-if="selectedView === '1'"
        class="text--white text-18"
      >
        {{ $t('properties') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end">
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="selectedView === '1' && !loading">
        <ion-item
          v-for="additionalProperty in lodash.orderBy(myProperties, 'id')"
          :key="additionalProperty.id"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
          :style="parseInt(additionalProperty.id) === parseInt($store.state.currentPropertySelected) || findMyRole(parseInt(additionalProperty.id, false)) === 'Tenant' ? 'opacity: 0.5; border: 1px solid #ffd4a4' : ''"
        >
          <ion-label>
            <span class="text--white">{{ additionalProperty.propertyName }}</span><span class="ml-5 text--primary text-12">{{ findMyRole(parseInt(additionalProperty.id), true) }}</span><br><span class="text--primary text-12">{{ additionalProperty.street }}, {{ additionalProperty.city }}</span>
          </ion-label>
          <i
            v-if="parseInt(additionalProperty.id) !== parseInt($store.state.currentPropertySelected) && !isItMyHouse(parseInt(additionalProperty.id)) && findMyRole(parseInt(additionalProperty.id)) !== 'Tenant'"
            slot="end"
            tappable
            class="mdi mdi-home-export-outline text--primary"
            style="font-size: 18px;"
            @click="leaveAlertConfirm(additionalProperty)"
          />
          <i
            v-if="parseInt(additionalProperty.id) !== parseInt($store.state.currentPropertySelected) && isItMyHouse(parseInt(additionalProperty.id)) && findMyRole(parseInt(additionalProperty.id), false) !== 'Tenant'"
            slot="end"
            tappable
            class="mdi mdi-pencil text--primary"
            style="font-size: 18px;"
            @click="editProperty(additionalProperty)"
          />
          <i
            v-if="parseInt(additionalProperty.id) !== parseInt($store.state.currentPropertySelected) && isItMyHouse(parseInt(additionalProperty.id)) && findMyRole(parseInt(additionalProperty.id), false) !== 'Tenant'"
            slot="end"
            tappable
            class="mdi mdi-trash-can-outline text--danger"
            style="font-size: 18px;"
            @click="removeAlertConfirm(additionalProperty)"
          />
          <i
            v-if="parseInt(additionalProperty.id) === parseInt($store.state.currentPropertySelected) || findMyRole(parseInt(additionalProperty.id), false) === 'Tenant'"
            slot="end"
            class="mdi mdi-account-star text--gray"
            style="font-size: 25px;"
          />
        </ion-item>
        <div v-if="!myProperties.length">
          <span class="text--gray">You don't have any other homes created yet</span>
        </div>
        <ion-fab
          slot="fixed"
          vertical="bottom"
          horizontal="end"
        >
          <ion-fab-button @click="selectedView = '2'">
            <ion-icon
              :icon="addOutline"
              class="text--black"
            />
          </ion-fab-button>
        </ion-fab>
      </div>
      <div v-else-if="selectedView === '2' && !loading">
        <div
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <div class="text--white mb-5">
            {{ $t('Create Property') }}
          </div>
          <ion-text class="text--gray">
            {{ $t('Please fill your address') }}
          </ion-text>

          <ion-item lines="none">
            <ion-label position="floating">
              {{ $t('Property Name') }}
            </ion-label>
            <ion-input
              id="propertyName"
              v-model="propertyName"
              class="capitalize"
              type="text"
              enterkeyhint="done"
              required
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-row
            class="ion-no-padding"
            style="margin-top: -15px;"
          >
            <ion-col
              class="ion-no-padding"
              style="margin-right: 5px;"
            >
              <ion-list
                class="result-section"
                :class="[locationslistshow ? '' : 'hidden']"
              >
                <ion-card
                  v-for="item in locationsItems"
                  :key="item.id"
                  class="suggestions-card"
                  @click="locationItemClicked(item)"
                >
                  {{ item.place_name }}
                </ion-card>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-item lines="none">
            <ion-label position="floating">
              {{ $t('Address') }}
            </ion-label>
            <ion-input
              id="street"
              v-model="street"
              class="capitalize"
              type="text"
              enterkeyhint="done"
              required
              @keydown.enter="closeKeyboard"
              @ionInput="getItems($event)"
              @ionBlur="closeList()"
            />
          </ion-item>

          <div class="d-flex">
            <ion-item
              lines="none"
              style="margin: 0 15px 0 0;"
            >
              <ion-label position="floating">
                {{ $t('City') }}
              </ion-label>
              <ion-input
                id="city"
                v-model="city"
                class="capitalize"
                type="text"
                required
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
              />
            </ion-item>
            <ion-item
              lines="none"
              style="margin: 0;"
            >
              <ion-label position="floating">
                {{ $t('Post Code') }}
              </ion-label>
              <ion-input
                id="post-code"
                v-model="postCode"
                type="text"
                required
                enterkeyhint="done"
                @keydown.enter="closeKeyboard"
              />
            </ion-item>
          </div>
          <ion-item
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Country') }}
            </ion-label>
            <ion-input
              id="country"
              v-model="country"
              class="capitalize"
              type="text"
              required
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-button
            fill="clear"
            class="next-button mt-15"
            expand="block"
            :disabled="!street || !country || !city || !postCode"
            @click="createAdditionalProperty"
          >
            {{ $t('Add new property') }}
          </ion-button>
        </div>
        <div
          class="text-left text-14 text--gray mt-10 mb-5"
          tappable
          @click="selectedView = '3'"
        >
          {{ $t('Add a home') }} <span class="text--primary">{{ $t('with property code') }}</span>
        </div>
      </div>
      <div v-else-if="selectedView === '3' && !loading">
        <div
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <h1>
            {{ $t('Create Property') }}
          </h1>
          <ion-text class="text--gray">
            {{ $t('Please fill your property code') }}
          </ion-text>

          <ion-item lines="none">
            <ion-label position="floating">
              {{ $t('Property Code') }}
            </ion-label>
            <ion-input
              id="propertyCode"
              v-model="propertyCode"
              class="capitalize"
              type="text"
              enterkeyhint="done"
              required
              @keydown.enter="closeKeyboard"
            />
          </ion-item>

          <ion-button
            fill="clear"
            class="next-button mt-15"
            expand="block"
            :disabled="!propertyCode"
            @click="createPropertyByCode"
          >
            {{ $t('Add new property') }}
          </ion-button>
          <div
            v-if="propertyCodeSuccess"
            class="text--white mt-20"
          >
            <div>
              <span class="text-14 text--white"><i
                class="mdi mdi-check-circle"
                style="color: lightgreen; font-size: 32px;"
              /></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <ion-spinner
          v-if="loading"
          color="light"
          name="crescent"
        />
      </div>
    </transition>
  </ion-content>
</template>

<script>
import { alertController, modalController } from '@ionic/vue'
import { closeOutline, addOutline, checkmarkDoneOutline, personCircleOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Keyboard } from '@capacitor/keyboard'

import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'

import EditMyPropertiesModal from './EditMyProperties.vue'

import _ from 'lodash'

export default defineComponent({
  name: 'Members',
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      lodash: _,
      closeOutline,
      checkmarkDoneOutline,
      personCircleOutline,
      addOutline,
      selectedView: '1',
      myProperties: [],
      propertyName: '',
      street: '',
      city: '',
      postCode: '',
      country: '',
      locationsItems: [],
      locationslistshow: false,
      loading: false,
      propertyCode: '',
      propertyCodeSuccess: false
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.getMyProperties()
  },
  methods: {
    isItMyHouse (id) {
      let answer = false
      const myPropertiesLevelPermissions = this.$store.state.userData[0].propertyLevelPermissions
      const find = myPropertiesLevelPermissions.find(item => item.propertyId === id)
      if (find) {
        answer = find.role === 'Home Owner' || find.role === 'Tenant'
      }
      return answer
    },
    findMyRole (id, translate) {
      // console.log('id, translate')
      // console.log(id, translate)
      let response = 'Fel'
      const myPropertiesLevelPermissions = this.$store.state.userData[0].propertyLevelPermissions
      const find = myPropertiesLevelPermissions.find(item => item.propertyId === id)
      if (find && translate) {
        const role = find.role
        if (role === 'Home Owner') {
          response = this.$t('Home Owner')
        } else if (role === 'Tenant') {
          response = this.$t('Tenant')
        } else {
          response = this.$t('Home Member')
        }
      } else {
        response = find.role
      }
      return response
    },
    dismissModal () {
      this.close()
      this.$store.commit('invokeGetMyProperties', true)
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    getMyProperties () {
      this.loading = true
      this.$store.dispatch('getMyProperties')
        .then(response => {
          console.log(response.data)
          this.myProperties = response.data
          this.loading = false
        })
    },
    createAdditionalProperty () {
      this.$store.dispatch('createAdditionalProperty', {
        propertyName: this.propertyName.charAt(0).toUpperCase() + this.propertyName.slice(1),
        street: this.street,
        city: this.city,
        postCode: this.postCode,
        country: this.country
      })
        .then(resp => {
          if (resp) {
            this.$store.dispatch('getUserData')
              .then(() => {
                this.getMyProperties()
                this.clearForm()
                this.selectedView = '1'
              })
          }
        })
        .catch(err => {
          if (err) {
            this.errorAlert('Something has failed, try again later!')
          }
        })
    },
    createPropertyByCode () {
      this.loading = true
      this.loading = true
      this.$store.dispatch('addPropertyByPropertyCode', { propertyCode: this.propertyCode })
        .then(response => {
          // console.log(response.data)
          this.loading = false
          if (response.data.success) {
            this.propertyCodeSuccess = true
            this.$store.dispatch('getUserData')
              .then(() => {
                this.getMyProperties()
                this.propertyCode = ''
                setTimeout(() => {
                  this.selectedView = '1'
                  this.propertyCodeSuccess = false
                }, 1000)
              })
          } else {
            // console.log(this.$t(response.data.message))
            this.errorAlert(this.$t(response.data.message))
          }
        }).catch(error => {
          this.errorAlert(error.response.data)
        })
    },
    async removeAlertConfirm (property) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: `${this.$t('Remove this property')} ${property.propertyName}?`,
          message: this.$t('This will remove all the data for selected property!'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            },
            {
              text: this.$t('Remove'),
              role: 'destructive',
              handler: () => {
                this.removeProperty(property.id)
              }
            }
          ]
        })
      return alert.present()
    },
    async leaveAlertConfirm (property) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: `${this.$t('Do you want to leave this home')} ${property.propertyName}?`,
          message: this.$t('This means that you no longer have access to this home'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            },
            {
              text: this.$t('Leave'),
              role: 'destructive',
              handler: () => {
                this.leaveProperty(property.id)
              }
            }
          ]
        })
      return alert.present()
    },
    removeProperty (propertyId) {
      const permissions = this.$store.state.userData[0].propertyLevelPermissions
      const fencesIndex = this.$store.state.userData[0].myGeoFences.findIndex(item => item.propertyId === Number(propertyId))

      const find = permissions.find(item => item.propertyId === parseInt(propertyId))
      const findProperty = this.myProperties.find(item => item.id === parseInt(propertyId))

      if (find && findProperty) {
        console.log(findProperty)
        const usersInProperty = findProperty.appUserIds.split(',')
        usersInProperty.pop()
        if (findProperty.propertyGateway === null) {
          if (usersInProperty.length <= 1) {
            const level = find.role
            if (level === 'Home Owner') {
              if (fencesIndex !== -1) {
                BackgroundGeolocation.removeGeofence(String(propertyId))
              }
              this.$store.dispatch('removeAdditionalProperty', { propertyId })
                .then(resp => {
                  if (resp) {
                    this.getMyProperties()
                  }
                })
                .catch(error => {
                  this.errorAlert(error.data)
                })
            } else {
              this.errorAlert('Fel')
            }
          } else {
            this.errorAlert(this.$t('Cant remove, please remove users first'))
          }
        } else {
          this.errorAlert(this.$t('Cant remove, please remove gateway first'))
        }
      } else {
        this.errorAlert('Fel')
      }
    },
    leaveProperty (id) {
      this.$store.dispatch('leaveAdditionalProperty', { id })
        .then(resp => {
          this.getMyProperties()
        })
        .catch(error => {
          this.errorAlert(error.data)
        })
    },
    async errorAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: [this.$t('Dismiss')]
        })
      await alert.present()
    },
    getItems (ev) {
      const val = ev.target.value
      this.$store.dispatch('getAddressGeocode', val).then(response => {
        if (response) {
          this.locationsItems = response.data.features
          this.locationslistshow = true
        } else {
          this.locationslistshow = false
          console.log('err')
        }
      })
    },
    locationItemClicked (item) {
      item.context.forEach(location => {
        if (location.id.indexOf('postcode') > -1) {
          this.postCode = location.text
        } else if (location.id.indexOf('place') > -1) {
          this.city = location.text
        } else if (location.id.indexOf('country') > -1) {
          this.country = location.text
        }
      })
      // remove country
      let address = item.place_name.replace(/,[^,]+$/, '')
      // remove city
      address = address.replace(/,[^,]+$/, '')
      this.street = address
      this.locationslistshow = false
    },
    closeList () {
      setTimeout(() => { this.locationslistshow = false }, 500)
    },
    clearForm () {
      this.propertyName = ''
      this.street = ''
      this.country = ''
      this.postCode = ''
      this.city = ''
    },
    editProperty (propertyToEdit) {
      return modalController
        .create({
          component: EditMyPropertiesModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            propertyToEdit,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
      this.getMyProperties()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_backgrounds';
.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
img {
  max-width: 40px;
}
.text--right {
  text-align: end;
}
</style>
