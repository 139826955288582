<template>
  <ion-app class="scanner-hide">
    <ion-split-pane
      v-if="isUserLoggedIn"
      when="lg"
      content-id="main-content"
    >
      <ion-menu
        side="start"
        content-id="main-content"
        class="sidemenu"
      >
        <Menu />
      </ion-menu>
      <ion-router-outlet
        id="main-content"
        :swipe-gesture="false"
        main
        :class="userRole === 'Landlord' ? 'background--landlord' : 'background--default'"
      />
    </ion-split-pane>
    <ion-router-outlet
      v-else
      id="main-content"
      :swipe-gesture="false"
      main
      class="background--default"
    />
  </ion-app>
  <div class="scanner-ui">
    <div class="scan-square" />
    <ion-button
      class="button-to-close-camera"
      @click="stopScan"
    >
      {{ $t('Cancel') }}
    </ion-button>
  </div>
</template>

<script>
import { getPlatforms, IonApp, IonRouterOutlet, IonSplitPane, loadingController, useBackButton, alertController, modalController } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'
import { App } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import { defineComponent } from 'vue'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'

import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import Menu from './components/SideMenu.vue'

import { getAppVersion } from './lib/variables'
import { InAppBrowser } from '@ionic-native/in-app-browser/'

import { MobileAccessibility } from '@ionic-native/mobile-accessibility'

import actionRequiredModal from './components/Notifications/ActionRequiredModal'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    Menu
  },
  data () {
    return {
      platform: [],
      loading: false,
      appVersion: getAppVersion(),
      impNotification: {}
    }
  },
  computed: {
    internetStatus () {
      return window.navigator.onLine
    },
    isUserLoggedIn () {
      return this.$store.getters.loggedIn
    },
    isGatewayActive () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].gatewayActive : undefined
    },
    isGatewayPresent () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].propertyGateway : false
    },
    isUserActive () {
      return this.$store.state.userData.length ? this.$store.state.userData.active : undefined
    },
    latestLandlordsNotification () {
      return this.$store.state.latestLandlordsNotification
    },
    landlordNotifications () {
      return this.$store.state.landlordNotifications
    },
    showLandlordsNotification () {
      return this.$store.state.showLandlordsNotification
    },
    landlordBuildings () {
      return this.$store.state.landlordBuildings
    },
    landlordProperties () {
      return this.$store.state.landlordProperties
    },
    userRole () {
      return this.$store.state.userRole
    },
    deviceFailedToAddReason () {
      return this.$store.state.deviceFailedToAddReason
    },
    doorWindowDevices () {
      const devices = this.$store.state.propertyDevices
      const doorWindow = devices.filter(item => item.zigbeeModel === 'SensorDoorWindow')
      return doorWindow
    }
  },
  watch: {
    // isGatewayActive (value) {
    //   if (value === false && this.isGatewayPresent) {
    //     this.presentLoading()
    //     this.loading = true
    //   } else if (value === true && this.loading === true) {
    //     this.dismissLoading()
    //     this.loading = false
    //   }
    // },
    '$route' (to, from) {
      if (this.isUserLoggedIn && this.userRole !== 'Landlord') {
        this.$store.dispatch('appActiveOrInactive', { type: 'AppStateChangeRouteUpdate', state: 'Active', route: to.fullPath, user: parseInt(this.$store.state.userId) })
      }
    },
    internetStatus (value) {
      this.checkInternetStatus(value)
    },
    isUserActive (value) {
      if (!value) {
        this.presentWarning()
      } else {
        this.dismissLoading()
      }
    },
    latestLandlordsNotification: function (newValue, oldValue) {
      if (newValue) {
        if (this.platform.includes('desktop')) { this.openToast() }
      }
    }
    // deviceFailedToAddReason: function (val) {
    //   if (val === true) {
    //     this.openCannotAddDevice()
    //   }
    // }
  },
  created () {
    this.checkInternetStatus(window.navigator.onLine)
    const platform = getPlatforms()
    this.platform = platform
    // console.log(platform)
    this.$store.commit('platform', platform)

    this.checkAppVersion()

    if (this.platform.includes('capacitor')) {
      // StatusBar.show()
      StatusBar.setStyle({ style: Style.Dark })

      Keyboard.addListener('keyboardDidShow', info => {
        // console.log(info)
        this.$store.commit('keyboardEvent', 'open')
      })
      Keyboard.addListener('keyboardDidHide', () => {
        this.$store.commit('keyboardEvent', 'closed')
      })
    }
    if (this.platform.includes('android')) {
      MobileAccessibility.setTextZoom(100)
      MobileAccessibility.updateTextZoom()
      MobileAccessibility.usePreferredTextZoom(false)
    }
    const userRole = this.$store.state.userRole
    if (userRole === 'Landlord') {
      this.$store.dispatch('getLandlordBuildingsAndProperties')
    }
    App.addListener('appStateChange', ({ isActive }) => {
      // console.log('App state changed. Is active?-----------------', isActive)
      if (isActive) {
        this.checkAppVersion()
        this.checkInternetStatus(window.navigator.onLine)
        const userRole = this.$store.state.userRole
        console.log(userRole)
        if (userRole !== 'Landlord' && this.$store.getters.loggedIn) {
          console.log('getting user data')
          this.$store.dispatch('getUserData')
            .then(() => console.log('Getting data OK'))
            .catch(err => this.errorMessage(err, 'isActive'))
          this.getActionRequiredWaterleakFire()
        } else if (userRole === 'Landlord' && this.$store.getters.loggedIn) {
          console.log('gettin landlords data')
          this.$store.dispatch('getLandlordBuildingsAndProperties')
        }
        if (this.$store.getters.loggedIn) {
          this.$store.dispatch('connectSocketIo')
        }
        // /* const openSensorsFoundByAutomation = this.$store.state.openSensorsFoundByAutomation
        // if (openSensorsFoundByAutomation) {
        //   const devices = this.doorWindowDevices
        //   const opendevices = []
        //   devices.forEach(item => {
        //     if (item.status === 'Open') {
        //       opendevices.push(item.deviceName)
        //     }
        //   })
        //   if (opendevices.length > 0) {
        //     this.openSensorsAlert(opendevices)
        //   }
        // } */
        if (this.isUserLoggedIn && this.userRole !== 'Landlord') {
          this.$store.dispatch('appActiveOrInactive', { type: 'AppStateChange', state: 'Active', route: this.$route.fullPath, user: parseInt(this.$store.state.userId) })
        }
      } else {
        if (this.isUserLoggedIn && this.userRole !== 'Landlord') {
          this.$store.dispatch('appActiveOrInactive', { type: 'AppStateChange', state: 'Inactive', route: null, user: parseInt(this.$store.state.userId) })
        }
      }
    })
    if (this.$store.getters.loggedIn) {
      this.$store.dispatch('connectSocketIo')
    }
    setTimeout(() => {
      if (this.landlordNotifications.some(item => item.resolved === false) && this.platform.includes('desktop')) {
        // console.log(this.landlordNotifications)
        const count = this.landlordNotifications.filter(i => i.resolved === false).length
        this.openUnreadToast(count)
      }
    }, 2000)
  },
  mounted () {
    useBackButton(-1, () => {
      if (this.$route.path === '/main-view') {
        App.exitApp()
      }
    })
    // console.log('init google auth')
    // if (!this.platform.includes('capacitor')) {
    //   console.log('init desktop')
    //   GoogleAuth.initialize()
    // } else {
    //   console.log('init mobile')
    GoogleAuth.initialize({
      client_id: '982505048949-va95qvkauapkcme3cf4hfph8gk7ipu5s.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: false
    })
    // }
  },
  methods: {
    stopScan () {
      document.body.classList.remove('qrscanner')
      BarcodeScanner.showBackground()
      BarcodeScanner.stopScan()
    },
    // /* async openSensorsAlert (opendevices) {
    //   const alert = await alertController
    //     .create({
    //       cssClass: 'remove-device',
    //       header: this.$t('Varning'),
    //       message: 'Din dörr-/fönstersensor: "' + opendevices.join('", "') + '", vill du avaktivera larmet?',
    //       buttons: [
    //         {
    //           text: this.$t('Ja'),
    //           cssClass: 'secondary',
    //           handler: () => {
    //             const alarmSetting = false
    //             this.$store.dispatch('setAlarmStatus', alarmSetting)
    //             this.$store.dispatch('resetOpenSensorsFoundByAutomation', false)
    //           }
    //         },
    //         {
    //           text: this.$t('Avbryt'),
    //           role: 'cancel',
    //           cssClass: 'secondary',
    //           handler: () => {
    //             // console.log('')
    //           }
    //         }
    //       ]
    //     })
    //   await alert.present()
    // }, */
    async presentWarning () {
      const loader = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: 'Your account has been locked...'
        })

      await loader.present()
    },
    openToast () {
      const nots = this.latestLandlordsNotification
      const property = this.landlordProperties.filter(propertya => propertya.id === nots.propertyId)
      // console.log(this.landlordBuildings)
      const buildingName = this.landlordBuildings.filter(item => item.id === property[0].buildingId)
      this.$toast.show(`<i class="mdi mdi-close-circle" style="margin-left: -27px; margin-top: -8px;"></i><a class="toast-notification" style="color: white; margin-top: -18px;" href="/notifications">${buildingName[0].buildingName} / ${property[0].propertyName} >>>> ${nots.warningMessage} detected!</a>`, {
        type: 'error'
        // onClick: () => this.redirect(nots.propertyId)
      })
    },
    openUnreadToast (count) {
      this.$toast.show(`<i class="mdi mdi-close-circle" style="margin-left: -27px; margin-top: -8px;"></i><a class="toast-notification" style="color: #2c3036; margin-top: -18px;" href="/notifications"><span style="font-weight: bold;">${count}</span> unread ${count === 1 ? 'notification' : 'notifications'}!</a>`, {
        type: 'info'
      })
    },
    async openCannotAddDevice () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Sorry'),
          message: 'Sorry this has failed, please try it again.',
          buttons: [
            {
              text: this.$t('Ok'),
              cssClass: 'secondary',
              handler: () => {
                this.$store.commit('deviceFailedToAddReasonToFalse')
              }
            }
          ]
        })
      await alert.present()

      // const { role } = await alert.onDidDismiss()
      // console.log('onDidDismiss resolved with role', role)
    },
    async checkInternetStatus (value) {
      if (!value && this.loading === false) {
        this.presentLoading()
      } else {
        if (this.loading === true) {
          this.dismissLoading()
          this.loading = false
        }
      }
    },
    async presentLoading () {
      this.loading = true
      const loader = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: this.$t('no internet message'),
          spinner: 'crescent'
        })

      await loader.present()
    },
    dismissLoading () {
      loadingController.dismiss()
    },
    async checkAppVersion () {
      // console.log('appVersion')
      const appVer = parseFloat(this.appVersion.split('-')[0])
      // console.log(appVer)
      this.$store.dispatch('getAppVersion').then(resp => {
        const apiAppVer = parseFloat(resp.data.value.split('-')[0])
        if (appVer < apiAppVer && !this.platform.includes('desktop')) {
          console.log('open alert to update')
          // this.showAppStoreAlert()
        }
      })
    },
    async getActionRequiredWaterleakFire () {
      this.$store.dispatch('getActionRequiredWaterleakFire').then(resp => {
        // console.log(resp.data)
        this.impNotification = resp.data
        if (resp.data) {
          this.actionRequiredModal()
        }
      })
    },
    async showAppStoreAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('updateFound'),
          message: this.$t('updateAppMessage'),
          buttons: [
            {
              text: this.$t('UpdateNow'),
              cssClass: 'secondary',
              handler: () => {
                let linkToOpen = ''
                if (this.platform.includes('android')) {
                  linkToOpen = 'https://play.google.com/store/apps/details?id=se.homevision.techpreview'
                } else {
                  linkToOpen = 'https://apps.apple.com/us/app/homevision-systems/id1567787523'
                }
                InAppBrowser.create(linkToOpen).show()
              }
            }
          ]
        })
      await alert.present()
    },
    actionRequiredModal () {
      return modalController
        .create({
          component: actionRequiredModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal(),
            impNotification: this.impNotification
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    async errorMessage (message, where) {
      // const alert = await alertController
      //   .create({
      //     cssClass: 'remove-device',
      //     header: this.$t('Error'),
      //     message: String(message) + ' ' + where,
      //     buttons: [
      //       {
      //         text: this.$t('Ok'),
      //         cssClass: 'secondary'
      //       }
      //     ]
      //   })
      // await alert.present()
      console.log(message, where)
    }
  }
})
</script>

<style lang="scss" scoped>
ion-split-pane {
  --side-max-width: 150px;
  --border: none;
}
a:hover {
  color: black;
}
.scan-square {
  position: absolute;
  width: 40vw;
  height: 40vw;
  border: 2px solid black;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.button-to-close-camera {
  position: absolute;
  max-width: 30vw;
  bottom: 50px;
  left: 50%;
  transform: translate(50px, -50%)
}
</style>