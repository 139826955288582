<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-item
        v-if="selectedView === 1"
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-item
        v-else
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = 1"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title class="text-18 text--white">
        {{ $t('Membership') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end">
    <div v-if="selectedView === 1">
      <div class="box rounded-corners">
        <div class="text--gray">
          <img
            :src="require('@/assets/images/homevision.svg')"
          >
        </div>
        <div class="mt-30 text--white text-left">
          <i class="mdi mdi-account-star-outline text--primary pr-5" />{{ $t('Membership') }}:
          <span class="text--primary">
            <i
              v-if="isMember"
              class="mdi mdi-check text--primary"
            />
            {{ isMember ? $t('Active') : $t('Inactive') }}
          </span>
        </div>
        <div
          v-if="!isMember"
          class="text--white mt-5 text-12 text-left"
        >
          {{ $t('Sign up for a Homevision Kit subscription to benefit from our member prices') }}
        </div>
        <div
          v-else
          class="text--white mt-5 text-12 text-left"
        >
          {{ $t('As a Homevision Kit subscriber, you always get our best price!') }}
        </div>
        <div class="mt-20 text--white text-left">
          <i class="mdi mdi-plus text--primary pr-5" />{{ $t('Membership Ikea') }}:
          <span class="text--primary">
            <i
              v-if="isIkeaMember"
              class="mdi mdi-check text--primary"
            />
            {{ isIkeaMember ? $t('Active') : $t('Inactive') }}
          </span>
        </div>
      </div>

      <!-- //subs -->
      <div>
        <div class="text-left text--white mt-30">
          {{ $t('Active subscriptions') }}
        </div>
        <div
          v-if="subscriptions.length"
          class="mb-10"
        >
          <div
            v-for="item in subscriptions"
            :key="item.id"
          >
            <ion-item
              lines="none"
              color="transparent"
              class="device-item rounded-corners mt-8 text--white"
              @click="subscriptionDetail(item)"
            >
              <div slot="start">
                <span class="text-14 text--primary mr-10">
                  ID: {{ item.orderId }}
                </span>
                <span class="text-12 text--white mt-10">
                  {{ item.totalSum }} kr {{ $t('monthly') }}
                </span>
              </div>
              <div
                slot="end"
                class="text-14"
                :class="item.status === 'Active' ? 'text--primary' : 'text--gray'"
              >
                {{ mapStatus(item.status) }}
              </div>
              <i
                slot="end"
                class="mdi mdi-chevron-right text--gray"
              />
            </ion-item>
          </div>
          <div class="mt-10">
            <ion-button
              @click="updatePaymentMethodAlert"
            >
              <ion-label>{{ $t('Update payment method') }}</ion-label>
            </ion-button>
          </div>
          <div v-if="sessionCreated">
            <stripe-checkout
              ref="checkoutRef1"
              :pk="publishableKey"
              :session-id="session"
            />
          </div>
        </div>
        <div
          v-else
          class="mb-20 mt-8"
        >
          <i
            class="mdi mdi-cart-remove text--primary"
            style="font-size: 20px;"
          /><br>
          <span class="text--gray">{{ $t('No active subscriptions') }}</span>
        </div>
      </div>
      <!-- <div class="text-left text--white mt-20">
        {{ $t('My orders') }}
      </div> -->
      <div v-if="orders.length">
        <!-- <ion-item
          v-if="complete"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
          @click="checkMyCurrentOrder(orders[0])"
        >
          <div
            slot="start"
            class="text-14 text--primary mr-5"
          >
            <div>ID: {{ orders[0].orderId }}</div>
          </div>
          <div
            class="text-14 text--white ml-10"
          >
            {{ orders[0].totalPaid }} kr
          </div>
          <div
            slot="end"
            style="min-width: 100px;"
          >
            <span class="text-12">Order: {{ mapStatus(orders[0].paymentStatus) }}</span><br>
            <span class="text-12">Status: {{ mapStatus(orders[0].orderStatus) }}</span>
          </div>
          <i
            slot="end"
            class="mdi mdi-chevron-right text--gray"
          />
        </ion-item> -->
        <div
          class="text-left text-14 text--gray mt-5"
          tappable
          @click="openPreviousOrdersModal"
        >
          {{ $t('My previous') }} <span class="text--primary">{{ $t('orders') }}</span>
        </div>
      </div>
      <!-- <div
        v-else
        class="mb-10 mt-30"
      >
        <i
          class="mdi mdi-cart-outline text--primary"
          style="font-size: 20px;"
        /><br>
        <span class="text--gray">{{ $t('No orders') }}</span>
      </div> -->
    </div>
    <div v-else-if="selectedView === 2">
      <div class="text-left text-14">
        <span class="text--gray">Order ID: {{ currentOrder.orderId }}</span>
      </div>
      <div class="text-left text-14">
        <span class="text--gray">{{ $t('Ordered') }}: {{ new Date(currentOrder.createdAt).toLocaleString() }}</span>
      </div>
      <div class="text-left mt-10">
        <span class="text--white">{{ $t('Items') }}:</span>
      </div>
      <div>
        <ion-item
          v-for="(item, i) in currentOrder.orderItems"
          :key="item.id"
          detail="false"
          lines="none"
          color="transparent"
          class="item-order rounded-corners mt-8 text--white"
        >
          <span
            slot="start"
            class="text-14"
          >{{ i + 1 }}</span>
          <ion-img
            class="item-picture"
            :src="item.picture"
          />
          <div>
            <div class="text-14 ml-10">
              {{ item.name }}
            </div>
            <div
              class="text-left text-12 mt-5 ml-10"
              style="min-width: 100px;"
            >
              <span
                v-if="item.paymentStyle === 'subscription'"
              >
                {{ item.subPrice }} kr <span class="text--primary">{{ $t('monthly') }} <span
                  v-if="item.qty > 1"
                  class="text--primary"
                >x {{ item.qty }}</span></span><br>
                <span
                  v-if="item.subscriptionUpfrontPrice"
                  class="text-10 text--gray"
                >{{ item.subscriptionUpfrontPrice }} kr {{ $t('Upfrontcost') }} <span
                  v-if="item.qty > 1"
                  class="text--primary"
                >x {{ item.qty }}</span></span>
              </span>
              <span
                v-else-if="item.paymentStyle === 'onetime'"
              >
                <!-- <span v-if="item.discountedPrice"><span style="text-decoration: line-through;">{{ item.price }}</span> <span class="text--primary">{{ item.price - item.discountedPrice }} SEK</span></span> -->
                <span>{{ item.price }} kr <span
                  v-if="item.qty > 1"
                  class="text--primary"
                >x {{ item.qty }}</span></span>
              </span>
            </div>
          </div>
          <!-- <div
                slot="end"
                class="item-qty"
              >
                <ion-input :value="item.qty" />
              </div> -->
          <div
            slot="end"
          >
            <!-- <i
              class="mdi mdi-minus-circle-outline text--primary mr-10"
              style="font-size: 25px; display: inline-block; margin-top: 10px;"
              tappable
              @click="removeItem(item)"
            /> -->
            <div
              class="text-14"
              style="min-width: 20px; display: inline-block; text-align: center; vertical-align: middle; margin-bottom: 0px;"
            >
              <span class="text--gray text-14">{{ $t('Qty') }}: </span> {{ item.qty }}
            </div>
            <!-- <i
              class="mdi mdi-plus-circle-outline text--primary ml-10"
              style="font-size: 25px; display: inline-block; margin-top: 10px;"
              tappable
              @click="addItem(item)"
            /> -->
          </div>
        </ion-item>
      </div>
      <!-- <hr> -->
      <div
        v-if="currentOrder.paymentStatus === 'Paid'"
        class="text-right text--white mt-10"
      >
        <span>
          <span class="mr-20">{{ $t('Total paid') }}:</span>
          <span class="text--primary">{{ currentOrder.totalPaid }}kr</span>
        </span>
      </div>
      <div v-else>
        <span class="text--primary">{{ $t('Cancelled') }}</span>
      </div>
      <div class="text-left text--white mt-30">
        <span class="text-14">
          {{ $t('Shipping Details') }}
        </span>
      </div>
      <div class="text--gray text-14 text-left">
        <ion-row class="ion-no-padding">
          <ion-col
            class="ion-no-padding"
            size="4"
          >
            {{ $t('Name') }}:
          </ion-col>
          <ion-col class="ion-no-padding">
            <span>{{ currentOrder.customerName }}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col
            class="ion-no-padding"
            size="4"
          >
            {{ $t('Your email') }}:
          </ion-col>
          <ion-col class="ion-no-padding">
            <span>{{ currentOrder.customerEmail }}</span>
          </ion-col>
        </ion-row>
        <ion-row class="mt-5">
          <ion-col
            class="ion-no-padding"
            size="4"
          >
            {{ $t('Address') }}:
          </ion-col>
          <ion-col class="ion-no-padding">
            <span>{{ currentOrder.shipToAddress.street }}</span><br>
            <span>{{ currentOrder.shipToAddress.city }}</span><br>
            <span>{{ currentOrder.shipToAddress.postCode }}</span><br>
          </ion-col>
        </ion-row>
        <!-- {{ $t('Name') }}: <span style="padding-left: 46px;">{{ currentOrder.customerName }}</span><br> -->
        <!-- {{ $t('Your email') }}: <span style="padding-left: 29px;">{{ currentOrder.customerEmail }}</span><br> -->
        <!-- <div class="mt-5" /> -->
        <!-- {{ $t('Address') }}: <span style="padding-left: 10px;">{{ currentOrder.shipToAddress.street }}</span><br>
        <span style="padding-left: 70px;">{{ currentOrder.shipToAddress.city }}</span><br>
        <span style="padding-left: 70px;">{{ currentOrder.shipToAddress.postCode }}</span><br> -->
      </div>
    </div>
    <div v-else-if="selectedView === 3">
      <div>
        <div class="text--gray text-left">
          <!-- <div>{{ $t('Subscription ID') }}: {{ subscriptionDetailViewData.id }}</div> -->
          <div>Order ID: {{ subscriptionDetailViewData.orderId }}</div>
        </div>
        <div class="text-left mt-10">
          <span class="text--white">{{ $t('Items') }}:</span>
        </div>
        <div class="mt-20">
          <ion-item
            v-for="(item, i) in subscriptionDetailViewData.items"
            :key="item.id"
            detail="false"
            lines="none"
            color="transparent"
            class="item-order rounded-corners mt-8 text--white"
          >
            <span
              slot="start"
              class="text-14"
            >{{ i + 1 }}</span>
            <ion-img
              class="item-picture"
              :src="item.itemPicturesAWSLinks[0]"
            />
            <div>
              <div class="text-14 ml-10">
                {{ item.itemName }}
              </div>
              <div
                class="text-left text-12 mt-5 ml-10"
                style="min-width: 100px;"
              >
                <span v-if="!isMember || item.itemCategory === 'Homevision Kit' || item.itemSKU === 'IKEA'">
                  {{ item.subscriptionPriceMonth }} kr <span class="text--primary">/{{ $t('perMonth') }}</span>
                </span>
                <span v-else>
                  {{ item.memberSubscriptionPriceMonth }} kr <span class="text--primary">/{{ $t('perMonth') }}</span>
                </span>
              </div>
            </div>
            <div
              slot="end"
            >
              <div
                class="text-14"
                style="min-width: 20px; display: inline-block; text-align: center; vertical-align: middle; margin-bottom: 0px;"
              >
                <span class="text--gray text-14">{{ $t('Qty') }}: </span> {{ findQuantity(item.productId) }}
              </div>
            </div>
          </ion-item>
        </div>
      </div>
      <div class="text-right text--white mt-30">
        <span>
          <span class="mr-10">{{ $t('Subscription') }}:</span>
          <span class="text--primary">{{ subscriptionDetailViewData.totalSum }} kr /{{ $t('perMonth') }}</span>
        </span>
      </div>
      <div class="text--white text-right mb-10 mt-10">
        <span :style="subscriptionDetailViewData.status === 'Active' || subscriptionDetailViewData.status === 'Canceled' ? 'margin-right: 56px;' : 'margin-right: 10px;'">{{ $t('Status') }}:</span> <span class="text--primary text-16">{{ mapStatus(subscriptionDetailViewData.status) }}</span>
      </div>
      <div
        v-if="subscriptionDetailViewData.status === 'Active'"
        class="mt-30 text--gray text-left text-14"
      >
        <span class="text--white">{{ $t('Subscribed') }}: <span class="text--primary">{{ new Date(subscriptionDetailViewData.createdAt).toLocaleDateString('en-GB') }}</span></span>
      </div>
      <div
        v-if="subscriptionDetailViewData.status === 'Canceled'"
        class="text--primary text--bold text-14 mt-20"
      >
        {{ $t('Subscription is canceled') }}
      </div>
      <div
        v-if="subscriptionDetailViewData.status === 'Requested cancellation'"
        class="text--primary text--bold text-14 mt-20"
      >
        {{ $t('Processing cancellation') }}
        <div class="mt-5 text--white">
          RMA: {{ subscriptionDetailViewData.rmaNumber }}
        </div>
      </div>
      <div
        v-if="minDateSubCancellation(subscriptionDetailViewData)"
        class="mt-20"
      >
        <ion-button
          :disabled="subscriptionDetailViewData.status === 'Requested cancellation' || subscriptionDetailViewData.status === 'Cancelled' || subscriptionDetailViewData.status === 'Returning'"
          @click="presentActionSheet(subscriptionDetailViewData)"
        >
          <ion-label>{{ $t('Manage Subscription') }}</ion-label>
        </ion-button>
      </div>
      <div v-else>
        <ion-button
          v-if="subscriptionDetailViewData.status !== 'Requested cancellation' && subscriptionDetailViewData.status !== 'Cancelled' && subscriptionDetailViewData.status !== 'Returning'"
          class="mt-30"
          @click="requestEarlyCancellation(subscriptionDetailViewData)"
        >
          <span v-if="!loading">{{ $t('Cancel Subscription') }}</span>
          <ion-spinner
            v-else
            color="light"
            name="crescent"
          />
        </ion-button>
      </div>

      <div
        class="text--white text-left text-14 mt-10 text--underline"
        @click="openLink"
      >
        {{ $t('Click here to learn more about our cancellation policy') }}
      </div>
    </div>
    <div v-else-if="selectedView === 4">
      <div class="text--white text-left">
        {{ $t('Order return and subscription cancellation') }}
      </div>
      <div class="text--gray text-left mt-20">
        <div>{{ $t('Subscription ID') }}: {{ subscriptionDetailViewData.id }}</div>
        <div>Order ID: {{ subscriptionDetailViewData.orderId }}</div>
      </div>
      <div>
        <div class="mt-20 text--white text-left text--bold">
          {{ $t('Select which items to return') }}
        </div>
        <div class="mt-20">
          <ion-item
            v-for="item in returnData.items"
            :key="item.id"
            detail="false"
            lines="none"
            color="transparent"
            class="item-order rounded-corners mt-8 text--white"
          >
            <ion-checkbox
              :checked="isItSelected(item)"
              @ionChange="selectToReturn(item)"
            />
            <ion-img
              class="item-picture"
              :src="item.itemPicturesAWSLinks[0]"
            />
            <div>
              <div class="text-14 ml-10">
                {{ item.itemName }}
              </div>
              <div
                class="text-left text-12 mt-5 ml-10"
                style="min-width: 100px;"
              >
                <span>
                  {{ item.subscriptionPriceMonth }} kr /<span class="text--primary">{{ $t('perMonth') }}</span>
                </span>
              </div>
            </div>
            <div
              slot="end"
            >
              <div
                class="text-14"
                style="min-width: 20px; display: inline-block; text-align: center; vertical-align: middle; margin-bottom: 0px;"
              >
                <span class="text--gray text-14">{{ $t('Qty') }}: </span> {{ findQuantity(item.productId) }}
              </div>
            </div>
          </ion-item>
        </div>
        <div
          v-if="returnItems.some(item => item.itemName === 'Homevision Essential' || item.itemName === 'Homevision Plus' || item.itemName === 'Homevision Haven')"
          class="mt-20 text--primary text-left text-14"
        >
          {{ $t('If you cancel your Homevision kit subscription, you will also lose your membership benefits') }}
        </div>
        <div
          v-if="returnItems.some(item => item.itemName === 'IKEA Integration')"
          class="mt-20 text--primary text-left text-14"
        >
          {{ $t('If you cancel ikea subscription, all your devices will be removed') }}
        </div>
        <div class="mt-20 text-left">
          <div class="mt-5 mb-10 text-left text--white text-14">
            {{ $t('Please tell us reason of your cancellation') }}
          </div>
          <ion-textarea
            v-model="reasonToCancel"
            class="return-notes rounded-corners"
            rows="3"
            cols="20"
          />
        </div>
      </div>
      <ion-button
        class="mt-30"
        @click="sendReturnForm"
      >
        <span v-if="!loading">{{ $t('Submit') }}</span>
        <ion-spinner
          v-else
          color="light"
          name="crescent"
        />
      </ion-button>
    </div>
    <div v-else-if="selectedView === 5">
      <div class="text--white">
        <div>
          {{ $t('Thank you, we have received your request') }}
        </div>
        <div class="text-14 mt-5 text--primary">
          {{ $t('Your reference number is') }}: {{ rma }}
        </div>
        <div class="mt-30">
          <ion-button @click="returnToFirstPage">
            OK
          </ion-button>
        </div>
      </div>
    </div>
    <div class="spacer" />
  </ion-content>
</template>

<script>
import { modalController, actionSheetController, alertController } from '@ionic/vue'
import { StripeCheckout } from '@vue-stripe/vue-stripe'

import { InAppBrowser } from '@ionic-native/in-app-browser/'

import myPreviousOrdersModal from './MyPreviousOrdersModal.vue'

export default ({
  components: {
    StripeCheckout
  },
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      selectedView: 1,
      orders: [],
      subscriptions: [],
      sessionCreated: false,
      complete: false,
      currentOrder: {},
      subscriptionDetailViewData: {},
      publishableKey: 'pk_live_51JkEZ6DZ8y8HBQ6JgND5wa84r8QNaTGBDtjlEjfAC6fCfU6OCEE8N8NZuKc9KTXQ17ZWU4CR3a5RqX2Y2wtrC3OW002Gd9E1nH',
      session: '',
      returnData: null,
      returnItems: [],
      reasonToCancel: '',
      rma: '',
      loading: false
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    isMember () {
      return this.$store.state.isMember
    },
    isIkeaMember () {
      return this.$store.state.isIkeaMember
    }
  },
  watch: {
    returnItems: function (val) {
      // console.log(val)
    }
  },
  mounted () {
    this.getMyOrders()
  },
  created () {
  },
  methods: {
    dismissModal () {
      this.close()
    },
    getMyOrders () {
      this.complete = false
      this.$store.dispatch('getMyOrdersAndSubs').then((resp) => {
        this.orders = resp.data.orders
        this.subscriptions = resp.data.subscriptions
        this.complete = true
      })
    },
    openPreviousOrdersModal () {
      return modalController
        .create({
          component: myPreviousOrdersModal,
          cssClass: 'devices-modal',
          canDismiss: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            orders: this.orders,
            close: () => modalController.dismiss()
          }
        })
        .then(m => m.present())
    },
    checkMyCurrentOrder (order) {
      this.currentOrder = order
      this.selectedView = 2
    },
    subscriptionDetail (sub) {
      this.subscriptionDetailViewData = sub
      this.selectedView = 3
    },
    findQuantity (productId) {
      const index = this.subscriptionDetailViewData.stripeObject.items.data.findIndex(item => item.price.product === productId)
      return this.subscriptionDetailViewData.stripeObject.items.data[index].quantity
    },
    async presentActionSheet (subData) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: this.$t('Cancel Subscription'),
              role: 'destructive',
              handler: () => {
                this.checkIfSubCanBeCancelled(subData)
              }
            },
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              handler: () => {
                // console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()
      // const { role } = await actionSheet.onDidDismiss()
      // console.log('onDidDismiss resolved with role', role)
    },
    minDateSubCancellation (subData) {
      const createdAt = new Date(subData.createdAt)
      const minimumPeriod = new Date(new Date(createdAt).setFullYear(new Date(createdAt).getFullYear() + 1))
      const today = new Date()

      let result = false
      if (minimumPeriod < today) {
        result = true
      } else {
        result = false
      }
      return result
    },
    checkIfSubCanBeCancelled (subData) {
      const createdAt = new Date(subData.createdAt)
      const minimumPeriod = new Date(new Date(createdAt).setFullYear(new Date(createdAt).getFullYear() + 1))
      const today = new Date()

      if (minimumPeriod < today) {
        this.confirmCancelleation(subData)
      } else {
        const date = new Date(minimumPeriod).toLocaleDateString()
        this.cannotBeCancelled(date)
      }
    },
    async cannotBeCancelled (date) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Failure'),
          message: this.$t('Subscription cannot be canceled before') + ' ' + date,
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            }
          ]
        })
      return alert.present()
    },
    async confirmCancelleation (subData) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Canceling Subscription'),
          message: this.$t('Are you sure you want to cancel this subcription?'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            },
            {
              text: this.$t('Cancel Subscription'),
              role: 'destructive',
              handler: () => {
                this.cancelSubscription(subData)
              }
            }
          ]
        })
      return alert.present()
    },
    cancelSubscription (subData) {
      this.$store.dispatch('cancelSubscription', subData)
        .then(async () => {
          this.getMyOrders()
          const alert = await alertController
            .create({
              cssClass: 'remove-device',
              header: this.$t('Success'),
              message: this.$t('Subscription canceled'),
              buttons: [
                {
                  text: this.$t('OK'),
                  handler: () => {
                    this.selectedView = 1
                  }
                }
              ]
            })
          return alert.present()
        })
        .catch(async error => {
          console.log(error)
          const alert = await alertController
            .create({
              cssClass: 'remove-device',
              header: this.$t('Failure'),
              message: this.$t('Subscription cancelation failed'),
              buttons: [
                {
                  text: this.$t('Cancel'),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  }
                }
              ]
            })
          return alert.present()
        })
    },
    async updatePaymentMethodAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Update payment method'),
          message: this.$t('You are about to update payment menthod for all your active subscriptions'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: this.$t('Continue'),
              handler: () => {
                this.updatePaymentMethod()
              }
            }
          ]
        })
      return alert.present()
    },
    updatePaymentMethod () {
      this.$store.dispatch('updatePaymentMethodIntent')
        .then(resp => {
          console.log(resp)
          this.session = resp.data.id
          this.sessionCreated = true
        })
        .finally(() => {
          this.$refs.checkoutRef1.redirectToCheckout()
          setTimeout(() => {
            this.dismissModal()
          }, 500)
        })
    },
    mapStatus (status) {
      let result
      if (status === 'Active') {
        result = 'Aktiv'
      } else if (status === 'Requested cancellation') {
        result = 'Pågående'
      } else if (status === 'Cancelled') {
        result = 'Avbruten'
      } else if (status === 'Paid') {
        result = 'Betald'
      } else if (status === 'Unpaid') {
        result = 'Obetald'
      } else if (status === 'Preparing') {
        result = 'Förbereder'
      } else if (status === 'Dispatched') {
        result = 'Skickad'
      } else if (status === 'Delivered') {
        result = 'Levererad'
      } else if (status === 'Returning') {
        result = 'Retur'
      } else if (status === 'Returned') {
        result = 'Returnerad'
      }
      return result
    },
    requestEarlyCancellation (subData) {
      this.returnData = subData
      this.selectedView = 4
    },
    selectToReturn (item) {
      const index = this.returnItems.findIndex(i => i.id === item.id)

      if (index === -1) {
        this.returnItems.push(item)
      } else {
        this.returnItems.splice(index, 1)
      }
      console.log(this.returnItems)
    },
    isItSelected (item) {
      const index = this.returnItems.findIndex(i => i.id === item.id)
      let result = false
      if (index === -1) {
        result = false
      } else {
        result = true
      }
      return result
    },
    sendReturnForm () {
      this.loading = true
      this.$store.dispatch('requestEarlyOrderReturn', { orderObject: this.returnData, returnItems: this.returnItems, reasonToCancel: this.reasonToCancel, userData: this.$store.state.userData })
        .then((resp) => {
          this.loading = false
          this.getMyOrders()
          this.rma = resp.data.rmaNumber
          this.selectedView = 5
        })
    },
    returnToFirstPage () {
      this.rma = ''
      this.returnData = null
      this.returnItems = []
      this.selectedView = 1
    },
    openLink () {
      let linkToOpen = ''
      if (this.platform.includes('android')) {
        linkToOpen = 'https://docs.google.com/viewer?url=https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/PurchaseAndReturnPolicy.pdf'
      } else {
        linkToOpen = 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/PurchaseAndReturnPolicy.pdf'
      }
      InAppBrowser.create(linkToOpen).show()
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.box {
  padding: 10px;
  // @include bg-box;
}
.spacer {
  min-height: 90px;
}
.item-order {
    // max-width: 20px;
    height: 50px;
    padding: 0;
    margin-inline: 0 0;
    .item-inner {
        margin-inline: 0;
        padding-inline-end: 0;
        padding-inline-start: 0;
    }
}
.item-picture {
    margin-inline: 0;
    max-width: 40px;
}
.item-qty {
    border: 1px solid white;
    color: white;
    font-size: 13px;
    max-width: 35px;
    max-height: 25px;
    padding-left: 7px;
    ion-input {
      max-height: 25px;
      padding: 0;
      margin: 0;
    }
    // padding: 3px;
    // margin-inline: 0;
  //     --inner-padding-bottom: 0;
  // --inner-padding-end: 0;
  // --inner-padding-start: 0;
  // --inner-padding-top: 0;
  // --inner-margin: 0;
  // --ion-safe-area-right: 0;
  // --ion-safe-area-left: 0;
  // --ion-safe-area: 0;
  // --ion-safe-area-bottom: 0;
  //   .item-inner {
  //       margin-inline: 0;
  //       padding-inline-end: 0;
  //       padding-inline-start: 0;
  //   }
}
.return-notes {
  @include bg-box-room-device;
      padding-left: 10px;
    padding-right: 10px;
}
</style>
